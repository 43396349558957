import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { selectUser } from '../../selectors/user.selector';
import { UserActions } from '../../actions/user.actionsts';
import { Store } from '@ngrx/store';
import { IAppState } from '../../state/app.state';

@Injectable({
    providedIn: 'root',
})
export class InfoPagesJobsService  {
    constructor(private router: Router, private store: Store<IAppState>) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        if (!localStorage.getItem('token')) {
            return of(true);
        } else {
            return this.getUser().pipe(
                switchMap(() => {
                    return of(true);
                }),
                catchError(() => of(false)),
            );
        }
    }

    getUser(): Observable<any> {
        return this.store.select(selectUser).pipe(
            tap((user: any) => {
                if (!user) {
                    this.store.dispatch(UserActions.getUser());
                }
            }),
            filter((user: any) => user),
            take(1),
        );
    }
}
