<div class="dialog-wrapper">
    <div mat-dialog-title>
        <h1 class="title">
            Lost pets center
            <i class="icon close-icon" [inlineSVG]="'#close'" (click)="closeDialog()"></i>
        </h1>

    </div>
    <div mat-dialog-content>
        <p class="discription"><b>Sorry to hear that your pet has gone 😢</b><br />
            A.W.O.L. get started by notifying your trusted contacts within your Community and we will send them a
            message so they can open the app and help with the search and rescue mission.</p>

        <h5>Select which pet is lost</h5>

        <mat-radio-group [(ngModel)]="selectedDevice" [labelPosition]="'before'">
            <div *ngFor="let device of loadedDevices$ | async; track device">
                <mat-radio-button class="device-radio" [value]="device" color="primary">
                    <div class="device">
                        <div class="device-img">
                            <i class="icon" [inlineSVG]="device.icon" *ngIf="!device?.icon_url; else deviceImg"></i>
                            <ng-template #deviceImg>
                                <img class="img" [src]="device?.icon_url" alt="{{ device?.device_id }}" />
                            </ng-template>
                            <app-device-battery class="battery" [batteryLevel]="device.battery">
                            </app-device-battery>
                        </div>

                        <h5 class="device-title" [ngClass]="{ sos: device.allSOSEvents?.length }"> {{ device.device_name
                            ?
                            device.device_name : device.device_id }} </h5>
                    </div>
                </mat-radio-button>
            </div>
        </mat-radio-group>

        <div class="add-new-device" (click)="addAnotherLostPet()">
            <i class="icon" [inlineSVG]="'#plus_green'"></i>
            Add another Lost Pet
        </div>

    </div>
    <div mat-dialog-actions>
        <div class="dialog-actions">
            <button class="btn basic" (click)="closeDialog()" mat-flat-button>
                {{ 'CANCEL' | translate }}
            </button>
            <button class="btn primary" [disabled]="!selectedDevice" (click)="next()" mat-flat-button>
                {{ 'NEXT' | translate }}
            </button>
        </div>
    </div>
</div>