import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthActions } from 'src/app/store/actions/auth.actions';
import { StatusLabelActions } from 'src/app/store/actions/status-label.actions';
import {
    StatusLabelType,
    VERIFICATION_CODE_FORGOT_PASSWORD_REX_EXP,
} from 'src/app/store/constants/common.constants';
import { IStatusLabel } from 'src/app/store/interfaces';
import { ApiService } from 'src/app/store/services';
import { IAppState } from 'src/app/store/state/app.state';
import { selectIsStatusLabel } from '../../store/selectors/status.selectors';
import { TranslateService } from '@ngx-translate/core';
import { skin } from 'src/white-labels';

@Component({
    selector: 'app-forgot-password-verification',
    templateUrl: './forgot-password-verification.component.html',
    styleUrls: ['./forgot-password-verification.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgotPasswordVerificationComponent implements OnInit, OnDestroy {
    userEmail: string;

    form: UntypedFormGroup = this.formBuilder.group({
        code: ['', [Validators.required, Validators.pattern(VERIFICATION_CODE_FORGOT_PASSWORD_REX_EXP)]],
    });

    verifyAccountError$: Observable<IStatusLabel>;
    private destroyed$ = new Subject<void>();

    constructor(
        private formBuilder: UntypedFormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private store: Store<IAppState>,
        private apiService: ApiService,
        private translate: TranslateService,
    ) {}

    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            if (params.email) {
                this.userEmail = params.email;
            } else {
                this.router.navigate(['/']);
            }
        });

        this.verifyAccountError$ = this.store.select(selectIsStatusLabel);
    }

    ngOnDestroy(): void {
        this.store.dispatch(StatusLabelActions.hideStatusLabel());
    }

    onSubmit(isValid: boolean) {
        this.form.markAllAsTouched();
        if (isValid) {
            const payload = {
                email: this.userEmail,
                code: this.form.value.code,
                whitelabel: skin.whiteLabel,
            };
            this.apiService
                .resetPasswordMatch(payload)
                .pipe(takeUntil(this.destroyed$))
                .subscribe(
                    () =>
                        this.router.navigate(['/reset-password'], {
                            queryParams: { email: this.userEmail, code: this.form.value.code },
                        }),
                    ({ error }) => {
                        if (error['message_key'] === 'exception_account_locked') {
                            this.store.dispatch(AuthActions.loginError({ error }));
                        } else {
                            this.store.dispatch(
                                StatusLabelActions.showStatusLabel({
                                    statusLabel: {
                                        status: error.message_key
                                            ? this.translate.instant(error.message_key)
                                            : error.message,
                                        labelType: StatusLabelType.WARNING,
                                    },
                                }),
                            );
                        }
                    },
                );
        }
    }
}
