import { createSelector } from '@ngrx/store';
import { IAppState } from '../state/app.state';
import { IStatusState } from '../state/status.stete';

export const selectFeature = (appState: IAppState) => appState.status;

export const selectIsPaymentProcessing = createSelector(
    selectFeature,
    (appState: IStatusState) => appState.paymentProcessing,
);

export const selectIsLoginProcessing = createSelector(
    selectFeature,
    (appState: IStatusState) => appState.loginProcessing,
);
export const selectIsDevicesLoaded = createSelector(
    selectFeature,
    (appState: IStatusState) => appState.devicesLoaded,
);
export const selectIsDeviceLoaded = createSelector(
    selectFeature,
    (appState: IStatusState) => appState.deviceLoaded,
);
export const selectIsZonesLoaded = createSelector(
    selectFeature,
    (appState: IStatusState) => appState.zonesLoaded,
);
export const selectIsMapLoaded = createSelector(
    selectFeature,
    (appState: IStatusState) => appState.mapLoaded,
);
export const selectIsStatusLabel = createSelector(
    selectFeature,
    (appState: IStatusState) => appState.statusLabel,
);
export const selectIsSubAccountsLoaded = createSelector(
    selectFeature,
    (appState: IStatusState) => appState.subAccountsLoaded,
);
export const selectDevicesLocationsStatus = createSelector(
    selectFeature,
    (appState: IStatusState) => appState.devicesLocationsStatus,
);
export const selectPingDeviceLocationStatus = createSelector(
    selectFeature,
    (appState: IStatusState) => appState.pingDeviceLocation,
);
export const selectIsShowLocationModal = createSelector(
    selectFeature,
    (appState: IStatusState) => appState.noLocationModal,
);
export const selectIsSubscriptionLoaded = createSelector(
    selectFeature,
    (appState: IStatusState) => appState.subscriptionLoading,
);

export const selectFpiSettingsLoaded = createSelector(
    selectFeature,
    (appState: IStatusState) => appState.fpiSettingsLoaded,
);

export const selectLoginStatus = createSelector(
    selectFeature,
    (appState: IStatusState) => appState.loginSuccess,
);

export const selectRenewWithStripeSuccess = createSelector(
    selectFeature,
    (appState: IStatusState) => appState.renewWithStripeStatus,
);
