export const TRACKI = {
    title: 'TRACKI',
    brandName: 'Tracki',
    primaryColor: '#1181ef',
    androidAppLink: '',
    iosAppLink: '',
    localizeLink: 'https://trackimo-web-plus-translations.s3.us-west-2.amazonaws.com/locale/',
    clientId: '7f8976b0-3f0e-4cf2-9d14-6b898787292c',
    clientSecret: '99404ead886bcb4fec3b87fa9c7ba3f9',
};
