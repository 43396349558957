import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { ILogin, IResetPassword, ISocialUserData, IUser } from '../interfaces';

const prefix = '[Auth]';

const login = createAction(`${prefix} Login`, props<{ userData: ILogin }>());
const loginSuccess = createAction(`${prefix} Login Success`, props<{ response: IUser }>());
const loginError = createAction(`${prefix} Login Error`, props<{ error: HttpErrorResponse }>());

const loginByVerificationCode = createAction(
    `${prefix} Login By Verification Code`,
    props<{ redirectPageURL: string }>(),
);
const loginByVerificationCodeSuccess = createAction(
    `${prefix} Login By Verification Code Success`,
    props<{ response: IUser }>(),
);

const forgotPassword = createAction(
    `${prefix} Forgot Password`,
    props<{ email: string; whitelabel: string }>(),
);
const forgotPasswordSuccess = createAction(`${prefix} Forgot Password Success`, props<any>());
const forgotPasswordError = createAction(
    `${prefix} Forgot Password Error`,
    props<{ error: HttpErrorResponse }>(),
);

const resetPassword = createAction(`${prefix} Reset Password`, props<{ passwordData: IResetPassword }>());
const resetPasswordSuccess = createAction(`${prefix} Reset Password Success`, props<any>());
const resetPasswordError = createAction(
    `${prefix} Reset Password Error`,
    props<{ error: HttpErrorResponse }>(),
);

const logout = createAction(`${prefix} Logout`, props<{ accountID: number }>());
const logoutSuccess = createAction(`${prefix} Logout Success`);
const logoutError = createAction(`${prefix} Logout Error`, props<{ error: HttpErrorResponse }>());

const loginWithSocial = createAction(
    `${prefix} Login With Social`,
    props<{ userData: ISocialUserData; provider: string }>(),
);
const loginWithSocialSuccess = createAction(`${prefix} Login With Social Success`);
const loginWithSocialError = createAction(
    `${prefix} Login With Social Error`,
    props<{ error: HttpErrorResponse }>(),
);
const startSocialLogin = createAction(`${prefix} Start Login via Social Network`);
const endSocialLogin = createAction(`${prefix} End Login via Social Network`);

export const AuthActions = {
    login,
    loginSuccess,
    loginError,
    startSocialLogin,
    endSocialLogin,

    loginWithSocial,
    loginWithSocialSuccess,
    loginWithSocialError,

    loginByVerificationCode,
    loginByVerificationCodeSuccess,

    forgotPassword,
    forgotPasswordSuccess,
    forgotPasswordError,

    resetPassword,
    resetPasswordSuccess,
    resetPasswordError,

    logout,
    logoutSuccess,
    logoutError,
};
