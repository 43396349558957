import { ChangeDetectionStrategy, Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { skin } from 'src/white-labels';
import { HotkeysService } from '../store/services';
@Component({
    selector: 'app-public',
    templateUrl: './public.component.html',
    styleUrls: ['./public.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublicComponent {
    skin = skin;
    photo = `white-labels/${skin.whiteLabel}/test.svg`;

    constructor(private hotkeysService: HotkeysService) {
        if (!environment.production) {
            this.hotkeysService.init();
        }
    }
}
