export const TRACKIMO = {
    title: 'TRACKIMO',
    brandName: 'Trackimo+',
    primaryColor: '#1181ef',
    androidAppLink: 'https://play.google.com/store/apps/details?id=com.trackimo.app2',
    iosAppLink: 'https://apps.apple.com/us/app/trackimo/id1515561581',
    localizeLink: 'https://trackimo-web-plus-translations.s3.us-west-2.amazonaws.com/locale/',
    clientId: '9092cd94-a728-47b7-86da-e15c9a3d4cdb',
    clientSecret: '9f540cd42ec8d3bc452ce39cdd3d6de4',
    storeLink: 'https://store.trackimo.com/',
    contactUs: 'https://trackimo.com/starting-guide/submitrequest',
    termsOfService: 'https://trackimoplus.com/terms-of-service',
    privacyPolicy: 'https://trackimoplus.com/privacy-policy',
};
