import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, takeUntil } from 'rxjs';
import { ILostPetInfo } from 'src/app/store/interfaces';
import { ApiService, GoogleMapService, WindowService } from 'src/app/store/services';
import { Meta, Title } from '@angular/platform-browser';

@Component({
    selector: 'app-lost-pet',
    templateUrl: './lost-pet.component.html',
    styleUrls: ['./lost-pet.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LostPetComponent implements OnInit, OnDestroy {
    code: string;
    petInfo: ILostPetInfo;
    isShowCheckMmark: boolean;
    isShowJoinCommunity: boolean;
    address$: Observable<string>;
    ownerPhone: string;
    private destroyed$ = new Subject<void>();

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private apiService: ApiService,
        private cdRef: ChangeDetectorRef,
        private googleMapService: GoogleMapService,
        private window: WindowService,
        private titleService: Title,
        private metaService: Meta,
    ) {
        this.titleService.setTitle('Pet is LOST!');
        this.metaService.addTags(
            [
                { name: 'description', content: 'Help to find!' },
                // Google / Search Engine Tags
                { itemprop: 'name', content: 'Pet is LOST!' },
                { itemprop: 'description', content: 'Help to find!' },
                { itemprop: 'image', content: 'assets/images/lost-pet-image.svg' },
                // Facebook Meta Tags
                { property: 'og:url', content: this.router.url },
                { property: 'og:type', content: 'website' },
                { property: 'og:title', content: 'Pet is LOST!' },
                { property: 'og:description', content: 'Help to find!' },
                { property: 'og:image', content: 'assets/images/lost-pet-image.svg' },
                // Twitter Meta Tags
                { name: 'twitter:card"', content: 'summary_large_image' },
                { name: 'twitter:title"', content: 'Pet is LOST!' },
                { name: 'twitter:description', content: 'Help to find!' },
                { name: 'twitter:image', content: 'assets/images/lost-pet-image.svg' },
            ],
            true,
        );
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            if (params.code) {
                this.code = params.code;
                this.apiService
                    .getLostpetInfo(params.code)
                    .pipe(takeUntil(this.destroyed$))
                    .subscribe(
                        (response) => {
                            this.petInfo = response;
                            this.address$ = this.googleMapService.getAddress(
                                this.petInfo.location.latitude,
                                this.petInfo.location.longitude,
                            );

                            this.cdRef.markForCheck();
                        },
                        () => {
                            this.router.navigate(['/pet-found']);
                        },
                    );
            } else {
                this.router.navigate(['/']);
            }
        });
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    helpWithSearch(): void {
        this.isShowCheckMmark = true;
        this.apiService
            .helpToSearch(this.code)
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                setTimeout(() => {
                    this.isShowJoinCommunity = true;
                    this.cdRef.markForCheck();
                }, 2000);
            });
    }

    getOwnerPhone(): void {
        if (!this.ownerPhone) {
            this.apiService
                .getOwnerPhone(this.code)
                .pipe(takeUntil(this.destroyed$))
                .subscribe(({ ownerPhone }) => {
                    this.ownerPhone = ownerPhone;
                    this.cdRef.markForCheck();
                });
        }
    }

    setImageAsMain(image: string): void {
        var index = this.petInfo.images.indexOf(image);
        this.petInfo.images[index] = this.petInfo.petIcon;
        this.petInfo.petIcon = image;
    }

    callToOwner(): void {
        this.window.getWindow.open(`tel:${this.ownerPhone}`);
    }

    goToMap(): void {
        this.router.navigate(['/public-map'], {
            queryParams: {
                code: this.code,
            },
        });
    }
}
