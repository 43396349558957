import { Injectable } from '@angular/core';
import { IDeviceListItem } from 'src/app/store/interfaces';

@Injectable({
    providedIn: 'root',
})
export class AddDeviceService {
    private _deviceID: number;
    private _SBAccountID: number;
    private _deviceType: IDeviceListItem;
    private _usageType: string;
    private _deviceName: string;
    private _isOverwatch: boolean;

    constructor() {}

    public set deviceID(v: number) {
        this._deviceID = v;
    }

    public get deviceID(): number {
        return this._deviceID;
    }

    public set deviceType(device: IDeviceListItem) {
        this._deviceType = device;
    }

    public get deviceType(): IDeviceListItem {
        return this._deviceType;
    }

    public set SBAccountID(v: number) {
        this._SBAccountID = v;
    }
    public get SBAccountID(): number {
        return this._SBAccountID;
    }

    public set usageType(v: string) {
        this._usageType = v;
    }
    public get usageType(): string {
        return this._usageType;
    }

    public set deviceName(v: string) {
        this._deviceName = v;
    }
    public get deviceName(): string {
        return this._deviceName;
    }

    public set isOverwatch(v: boolean) {
        this._isOverwatch = v;
    }
    public get isOverwatch(): boolean {
        return this._isOverwatch;
    }
}
