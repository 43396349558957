import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { SnackbarService } from 'src/app/store/services/snackbar.service';

@Component({
    selector: 'app-clubloc8-membership-benefits-dialog',
    templateUrl: './clubloc8-membership-benefits-dialog.component.html',
    styleUrls: ['./clubloc8-membership-benefits-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Clubloc8MembershipBenefitsDialogComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<Clubloc8MembershipBenefitsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
        private snackBar: SnackbarService,
        private translate: TranslateService,
        private clipboard: Clipboard,
    ) {}

    ngOnInit(): void {}

    closeDialog(): void {
        this.dialogRef.close();
    }

    applyCode() {
        this.clipboard.copy('LOC8SPECIAL50');
        this.snackBar.success(this.translate.instant('COPIED'));
    }
}
