import { createReducer, on } from '@ngrx/store';
import { DescendantsActions } from '../actions/descendants.actions';
import { DevicesActions } from '../actions/devices.actions';
import { EventsActions } from '../actions/events.actions';
import { DeviceStatus, DEVICES_TYPES } from '../constants';
import { IDeviceLocationEvent, IDeviceShort } from '../interfaces';
import { initialDescendantsState } from '../state/descendants.stete';

export const descendantsReducer = createReducer(
    initialDescendantsState,
    on(DescendantsActions.getDescendantsSuccess, (state, { response }) => ({
        ...state,
        descendants: response,
    })),
    on(DescendantsActions.getDescendantsDevicesSuccess, (state, { response }) => {
        let descendantsDevices = [];
        let lDevices = response.map((device: IDeviceShort) => ({
            ...device,
            icon:
                DEVICES_TYPES.find((d) => +d.icon_id === device.icon_id)?.icon ||
                (device.is_overwatch_supported ? '#curve' : '#universal'),
        }));
        if (state.descendantsDevices) {
            descendantsDevices = [...state.descendantsDevices, ...lDevices].sort(
                (a, b) => +a.device_name - +b.device_name,
            );
        } else {
            descendantsDevices = [...lDevices];
        }

        return {
            ...state,
            descendantsDevices,
        };
    }),
    on(DescendantsActions.clearDescendants, (state, {}) => ({
        ...state,
        descendants: undefined,
        descendantsDevices: undefined,
    })),
    on(DescendantsActions.moveDescendantDeviceSuccess, (state, {}) => ({
        ...state,
        descendants: undefined,
        descendantsDevices: undefined,
        subaccountsDevicesTotalCount: undefined,
    })),
    on(DescendantsActions.removeDescendantSuccess, (state, {}) => ({
        ...state,
        descendants: undefined,
        descendantsDevices: undefined,
        subaccountsDevicesTotalCount: undefined,
    })),
    on(DescendantsActions.getTotalSubaccountDevicesCountSuccess, (state, { response }) => ({
        ...state,
        subaccountsDevicesTotalCount: response.count,
    })),
    on(DescendantsActions.pingSubAccountsDevicesLocationsSuccess, (state, { response }) => ({
        ...state,
        descendantsDevices: state.descendantsDevices.map((device) => ({
            ...device,
            location_ping: response.find(
                (location: IDeviceLocationEvent) => location.device_id === device.device_id,
            ),
        })),
    })),
    on(EventsActions.getSOSDevicesEventsSuccess, (state, { response }) => ({
        ...state,
        descendantsDevices: state.descendantsDevices?.map((device) => ({
            ...device,
            allSOSEvents: response.alarmTypesDevices.filter(
                (event) =>
                    (event.alarm_type === 'SOS' || event.eventType === 'SOS') &&
                    +event.deviceId === device.device_id,
            ),
        })),
    })),
    on(DevicesActions.updateDeviceEventSuccess, (state, res) => {
        if (state.descendantsDevices) {
            return {
                ...state,
                descendantsDevices: state.descendantsDevices.map((device) => ({
                    ...device,
                    allSOSEvents:
                        res.events.length === 1
                            ? device.allSOSEvents.filter(
                                  (inEvent) =>
                                      inEvent.eventId !== res.events[0].eventId ||
                                      inEvent.id !== res.events[0].id,
                              )
                            : res.events.find((event) => +event.deviceId === device.device_id)
                            ? []
                            : device.allSOSEvents,
                })),
            };
        }

        return state;
    }),
    on(
        DevicesActions.renewPlanSuccess,
        DevicesActions.renewPrepaidPlanSuccess,
        DevicesActions.renewPlanByCouponSuccess,
        DevicesActions.renewPlanWithStripeSuccess,
        (state, response) => ({
            ...state,
            descendantsDevices: state.descendantsDevices?.map((device: IDeviceShort) => ({
                ...device,
                status: device.device_id === response.deviceID ? DeviceStatus.ACTIVE : device.status,
            })),
        }),
    ),
);
