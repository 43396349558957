<div class="dialog-wrapper add-contact-dialog" rtl>
    <div mat-dialog-title>
        <h1 class="title">{{ 'LIMIT_REACHED' | translate }}</h1>
    </div>
    <div mat-dialog-content>
        <p>{{ 'LIVE_TRACKING_LIMIT_MSG' | translate }}</p>
    </div>
    <div mat-dialog-actions>
        <div class="dialog-actions">
            <button class="btn primary" (click)="closeDialog()" mat-flat-button>
                {{ 'OK' | translate }}
            </button>
        </div>
    </div>
</div>