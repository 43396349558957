<ng-container *ngIf="skin.whiteLabel !== 'MYTRACKI'">
    <div class="device-imgs-container" *ngIf="!isHiddenDevicesBar">
        <div class="device-item" [ngClass]="{ active: device.isSelected }" *ngFor="let device of devicesTypes"
            (click)="selectDeviceImg(device)">
            <div class="device-img">
                <i class="icon"
                    [ngClass]="{stroke: +device.icon_id === 14 || +device.icon_id === 11 || +device.icon_id === 10}"
                    [inlineSVG]="device.icon"></i>
            </div>
            <p>{{ device.device_name }}</p>
        </div>
    </div>
</ng-container>

<app-status-label *ngIf="isMaxSizeError" [statusLabel]="statusLabel"> </app-status-label>

<div class="drop-container" (drop)="onDrop($event)" (dragover)="onDragOver($event)">
    <i class="icon stroke" [inlineSVG]="'#upload'" *ngIf="!imgURL"></i>

    <img [src]="imgURL" *ngIf="imgURL" />
    <h4 *ngIf="imgURL" (click)="onRemovePhoto()">{{ 'REMOVE_IMAGE' | translate }}</h4>

    <p>{{ 'DRAG_AND_DROP_IMAGE_HERE' | translate }}</p>

    <input type="file" accept="image/*" hidden="true" (change)="onFileSelect($event)" #file />
    <button class="btn primary-light" (click)="file.click()" mat-flat-button>
        {{ 'BROWSE_FILES' | translate }}
    </button>
</div>