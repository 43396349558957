import { createSelector } from '@ngrx/store';
import { IAppState } from '../state/app.state';
import { ISharedState } from '../state/shared.state';

export const selectFeature = (appState: IAppState) => appState.shared;

export const selectHistoryItem = createSelector(
    selectFeature,
    (appState: ISharedState) => appState.historyItem,
);

export const selectBrainTreeToken = createSelector(
    selectFeature,
    (appState: ISharedState) => appState.brainTreeToken,
);

export const selectDeviceGettedByToken = createSelector(
    selectFeature,
    (appState: ISharedState) => appState.devicesByToken,
);
