import { createSelector } from '@ngrx/store';
import { IAppState } from '../state/app.state';
import { IDescendantsState } from '../state/descendants.stete';
import { IUserState } from '../state/user.stete';

export const selectFeature = (appState: IAppState) => appState.descendant;
export const selectFeatureUser = (appState: IAppState) => appState.user;

export const selectDescendantsFirstLevel = createSelector(
    selectFeature,
    (appState: IDescendantsState) => appState.descendants,
);

export const selectSubaccountsDevices = createSelector(
    selectFeature,
    (appState: IDescendantsState) => appState.descendantsDevices,
);

export const selectMainAccountDevices = createSelector(
    selectFeature,
    selectFeatureUser,
    (appState: IDescendantsState, userState: IUserState) =>
        appState.descendantsDevices?.filter((device) => device.account_id === userState.user.account_id),
);

export const selectSubaccountsDevicesTotalCount = createSelector(
    selectFeature,
    (appState: IDescendantsState) => appState.subaccountsDevicesTotalCount,
);
