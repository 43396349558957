export const MAMORI = {
    title: 'MAMORI',
    brandName: 'Mamori',
    primaryColor: '#1181ef',
    androidAppLink: 'https://play.google.com/store/apps/details?id=com.trackimo.app2',
    iosAppLink: 'https://apps.apple.com/ua/app/trackimo/id1515561581',
    localizeLink: 'https://trackimo-web-plus-translations.s3.us-west-2.amazonaws.com/locale/',
    clientId: 'd4988039-b321-455e-88b4-0820d9b11ecf',
    clientSecret: '32bfbdd6d0761738d5f14066c60c0084',
};
