export enum PetType {
    CAT = 'CAT',
    DOG = 'DOG',
    OTHER = 'OTHER',
}

export const CATS = [
    'Abyssinian Cat',
    'American Bobtail Cat',
    'American Curl Cat',
    'American Shorthair Cat',
    'American Wirehair Cat',
    'Balinese-Javanese Cat',
    'Bengal Cat',
    'Birman Cat',
    'Bombay Cat',
    'British Shorthair Cat',
    'Burmese Cat',
    'Chartreux Cat',
    'Cornish Rex Cat',
    'Devon Rex Cat',
    'Domestic Short-haired Cat (simple)',
    'Egyptian Mau Cat',
    'European Burmese Cat',
    'Exotic Shorthair Cat',
    'Havana Brown Cat',
    'Himalayan Cat',
    'Japanese Bobtail Cat',
    'Korat Cat',
    'LaPerm Cat',
    'Maine Coon Cat',
    'Manx Cat',
    'Munchkin Ca',
    'Norwegian Forest Cat',
    'Ocicat Cat',
    'Oriental Cat',
    'Persian Cat',
    'Peterbald Cat',
    'Pixiebob Cat',
    'Ragamuffin Cat',
    'Ragdoll Cat',
    'Russian Blue Cat',
    'Savannah Cat',
    'Scottish Fold Cat',
    'Selkirk Rex Cat',
    'Siamese Cat',
    'Siberian Cat',
    'Singapura Cat',
    'Somali Cat',
    'Sphynx Cat',
    'Tonkinese Cat',
    'Toyger Cat',
    'Turkish Angora Cat',
    'Turkish Van Cat',
];

export const DOGS = [
    'Affenpinscher',
    'Afghan Hound',
    'Airedale Terrier',
    'Akita',
    'Alaskan Malamute',
    'American English Coonhound',
    'American Eskimo Dog',
    'American Foxhound',
    'American Staffordshire Terrier',
    'American Water Spaniel',
    'Anatolian Shepherd Dog',
    'Australian Cattle Dog',
    'Australian Shepherd',
    'Australian Terrier',
    'Basenji',
    'Basset Hound',
    'Beagle',
    'Bearded Collie',
    'Beauceron',
    'Bedlington Terrier',
    'Belgian Malinois',
    'Belgian Sheepdog',
    'Belgian Tervuren',
    'Bergamasco',
    'Berger Picard',
    'Bernese Mountain Dog',
    'Bichon Frise',
    'Black and Tan Coonhound',
    'Black Russian Terrier',
    'Bloodhound',
    'Bluetick Coonhound',
    'Boerboel',
    'Border Collie',
    'Border Terrier',
    'Borzoi',
    'Boston Terrier',
    'Bouvier des Flandres',
    'Boxer',
    'Boykin Spaniel',
    'Briard',
    'Brittany',
    'Brussels Griffon',
    'Bull Terrier',
    'Bulldog',
    'Bullmastiff',
    'Cairn Terrier',
    'Canaan Dog',
    'Cane Corso',
    'Cardigan Welsh Corgi',
    'Cavalier King Charles Spaniel',
    'Cesky Terrier',
    'Chesapeake Bay Retriever',
    'Chihuahua',
    'Chinese Crested',
    'Chinese Shar-Pei',
    'Chinook',
    'Chow Chow',
    "Cirneco dell'Etna",
    'Clumber Spaniel',
    'Cocker Spaniel',
    'Collie',
    'Corgi',
    'Coton de Tulear',
    'Curly-Coated Retriever',
    'Dachshund',
    'Dalmatian',
    'Dandie Dinmont Terrier',
    'Doberman Pinscher',
    'Dogue de Bordeaux',
    'English Cocker Spaniel',
    'English Foxhound',
    'English Setter',
    'English Springer Spaniel',
    'English Toy Spaniel',
    'Entlebucher Mountain Dog',
    'Field Spaniel',
    'Finnish Lapphund',
    'Finnish Spitz',
    'Flat-Coated Retriever',
    'French Bulldog',
    'German Pinscher',
    'German Shepherd Dog',
    'German Shorthaired Pointer',
    'German Wirehaired Pointer',
    'Giant Schnauzer',
    'Glen of Imaal Terrier',
    'Golden Retriever',
    'Gordon Setter',
    'Great Dane',
    'Great Pyrenees',
    'Greater Swiss Mountain Dog',
    'Greyhound',
    'Harrier',
    'Havanese',
    'Ibizan Hound',
    'Icelandic Sheepdog',
    'Irish Red and White Setter',
    'Irish Setter',
    'Irish Terrier',
    'Irish Water Spaniel',
    'Irish Wolfhound',
    'Italian Greyhound',
    'Japanese Chin',
    'Keeshond',
    'Kerry Blue Terrier',
    'Komondor',
    'Kuvasz',
    'Labrador Retriever',
    'Lagotto Romagnolo',
    'Lakeland Terrier',
    'Leonberger',
    'Lhasa Apso',
    'Löwchen',
    'Maltese',
    'Manchester Terrie',
    'Mastiff',
    'Miniature American Shepherd',
    'Miniature Bull Terrier',
    'Miniature Pinscher',
    'Miniature Schnauzer',
    'Mixed-breed Dog (simple)',
    'Neapolitan Mastiff',
    'Newfoundland',
    'Norfolk Terrier',
    'Norwegian Buhund',
    'Norwegian Elkhound',
    'Norwegian Lundehund',
    'Norwich Terrier',
    'Nova Scotia Duck Tolling Retriever',
    'Old English Sheepdog',
    'Otterhound',
    'Papillon',
    'Parson Russell Terrier',
    'Pekingese',
    'Petit Basset Griffon Vendéen',
    'Pharaoh Hound',
    'Pharaoh Hound',
    'Pointer',
    'Polish Lowland Sheepdog',
    'Pomeranian',
    'Poodle',
    'Portuguese Podengo Pequeno',
    'Portuguese Water Dog',
    'Pug',
    'Puli',
    'Pyrenean Shepherd',
    'Rat Terrier',
    'Redbone Coonhound',
    'Rhodesian Ridgeback',
    'Rottweiler',
    'Jack Russell Terrier',
    'Saint Bernard',
    'Saluki',
    'Samoyed',
    'Schipperke',
    'Scottish Deerhound',
    'Scottish Terrier',
    'Sealyham Terrier',
    'Shetland Sheepdog',
    'Shiba Inu',
    'Shih Tzu',
    'Siberian Husky',
    'Silky Terrier',
    'Skye Terrier',
    'Soft Coated Wheaten Terrier',
    'Spanish Water Dog',
    'Spinone Italiano',
    'Staffordshire Bull Terrier',
    'Standard Schnauzer',
    'Sussex Spaniel',
    'Swedish Vallhund',
    'Tibetan Mastiff',
    'Tibetan Spaniel',
    'Tibetan Terrier',
    'Toy Fox Terrier',
    'Treeing Walker Coonhound',
    'Vizsla',
    'Weimaraner',
    'Welsh Springer Spaniel',
    'Welsh Terrier',
    'West Highland White Terrier',
    'Whippet',
    'Wire Fox Terrier',
    'Wirehaired Pointing Griffon',
    'Wirehaired Vizsla',
    'Xoloitzcuintli',
    'Yorkshire Terrier',
];
