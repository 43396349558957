import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { IStepOption, TourService } from 'ngx-ui-tour-md-menu';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TranslateService } from '@ngx-translate/core';
import { WindowService } from './window.service';

@Injectable({ providedIn: 'root' })
export class TutorialsService {
    constructor(
        public translate: TranslateService,
        private localStorage: LocalStorageService,
        private deviceService: DeviceDetectorService,
        private window: WindowService,
        private readonly tourService: TourService,
    ) {}

    private readonly MAIN_SECTION_CSS_SELECTOR = '.tutorials';
    private readonly NO_SCROLL_CSS_CLASS = 'no-scroll';

    initTutorials(tourSteps: IStepOption[], tourType: string) {
        if (!this.deviceService.isMobile()) {
            if (!this.localStorage.getItem(`tutorials-${tourType}`)) {
                this.localStorage.setItem(`tutorials-${tourType}`, 'On');
            }

            if (this.localStorage.getItem(`tutorials-${tourType}`) === 'On') {
                this.tourService.initialize(tourSteps, {
                    enableBackdrop: false,
                    delayAfterNavigation: 3000,
                    closeOnOutsideClick: true,
                });
                this.setIsScrollable(true);
                this.tourService.start();
                setTimeout(() => {
                    if (this.window.getDocument.getElementsByClassName('tutorial-step-content')[0]) {
                        this.window.getDocument
                            .getElementsByClassName('tutorial-step-content')[0]
                            .setAttribute('dir', this.translate.currentLang === 'he' ? 'rtl' : 'ltr');
                        this.window.getDocument
                            .getElementsByClassName('tutorial-step-title')[0]
                            .setAttribute('dir', this.translate.currentLang === 'he' ? 'rtl' : 'ltr');
                    }
                });
            }
        }
    }

    turnOffTutorials(step) {
        this.tourService.end();
        this.localStorage.setItem(`tutorials-${step.tourType}`, 'Off');
    }

    endTutorials(step) {
        this.tourService.end();
        this.localStorage.setItem(`tutorials-${step.tourType}`, 'Off');
    }

    nextStep() {
        this.tourService.next();
    }

    isHasNextStep(step) {
        return this.tourService.hasNext(step);
    }

    private setIsScrollable(isScrollable: boolean) {
        const body = document.body,
            mainSection = document.querySelector(this.MAIN_SECTION_CSS_SELECTOR),
            addOrRemove = isScrollable ? 'remove' : 'add';

        mainSection.classList[addOrRemove](this.NO_SCROLL_CSS_CLASS);
        body[addOrRemove + 'EventListener']('touchmove', this.preventTouchMove, { passive: false });
    }

    private preventTouchMove(e) {
        e.preventDefault();
    }
}
