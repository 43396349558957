<ng-container *ngIf="sosMsgs?.length">
    <button class="btn sos-btn" (click)="showList()" mat-flat-button>
        <i class="icon" [inlineSVG]="'#map-sos'"></i>
        <div class="badge">{{sosMsgs.length}}</div>
    </button>

    <div class="list-container" *ngIf="isShowList">
        <div class="dismiss" (click)="dismissAll(sosMsgs)">{{ 'DISMISS_ALL' | translate }}</div>
        <div class="list-item" *ngFor="let sos of sosMsgs">
            <i class="icon close-icon" [inlineSVG]="'#close'" (click)="readEvent(sos)"></i>
            <div>
                <p class="time">{{ (localStorage.getItem('GQL_LOCATION') ? sos.created * 1000 : sos.created) |
                    dateFormat: 'HH:mm' }}</p>
                <p class="title">{{sos.accountEmail}}, {{sos.deviceName ? sos.deviceName : sos.deviceId}}</p>
                <p class="address" *ngIf="sos.address">{{ sos.address }}</p>
            </div>
            <i class="icon" [inlineSVG]="'#arrow_right'" (click)="goToDevice(sos)"></i>
        </div>
    </div>
</ng-container>