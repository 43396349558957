<div class="status-label-wrapper {{ statusLabel?.labelType }}"
    [ngClass]="{ hover: isHoverType, somitomoMibile: isSomitomoMobile }">
    <div class="icon-wrap">
        <i class="icon" [inlineSVG]="'#warning'"></i>
    </div>

    <div class="text-wrap" rtl>

        <h3 *ngIf="statusLabel?.status">{{ statusLabel?.status }} <a class="link"
                *ngIf="statusLabel?.statusCode === 'exception_trackimo_marked_deleted'"
                href="{{skin.whiteLabel === 'TRACKIPET' ? 'https://trackipet.com/pages/contact' : 'https://trackimo.com/starting-guide/submitrequest'}}"
                target="_blank">{{ 'CONTACT_US' | translate }}</a></h3>
        <p *ngIf="statusLabel?.description || address">{{ statusLabel?.description || address }}</p>
        <div class="somitomo-btns-wrap" *ngIf="isSomitomo">
            <div class="button" (click)="closeStatusLabel()">
                {{ statusLabel?.okCloseBtn }}
            </div>
            <div class="button button-orange" (click)="checkAgainSomitomoStatus()">
                {{ statusLabel?.tryAgainBtn }}
            </div>
        </div>
    </div>

    <div class="close-button-wrap" *ngIf="!isHoverType && !statusLabel?.isHideClose">
        <i class="warning-icon" [inlineSVG]="'#close'" (click)="closeStatusLabel()"></i>
    </div>
</div>