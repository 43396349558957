<section class="container">
    <app-header></app-header>

    <div class="pet-found">
        <h1>{{ 'PET_FOUND' | translate }}</h1>

        <div class="img-box">
            <img src="assets/images/pet-pic.svg" alt="pet-pic" />

            <div class="thank-you">
                <p>{{ 'THANK_YOU_FOR_HELP' | translate }}</p>
                <p>{{ 'SUPPORT_IS_A_LOT' | translate }}</p>
            </div>
        </div>

        <button class="btn primary" (click)="joinTrackipet()" mat-flat-button>
            {{ 'JOIN_TP_COMMUNITY' | translate }}
        </button>

        <p class="together">{{ 'WE_CAN_HELP' | translate }}</p>
    </div>

    <app-footer class="footer"></app-footer>
</section>