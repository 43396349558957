import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '../providers/window-provider';

@Injectable({ providedIn: 'root' })
export class WindowService {
    constructor(@Inject(WINDOW) private window: Window) {}

    public get getWindow(): Window {
        return this.window;
    }

    public get getNavigatorPlatform() {
        return this.window.navigator.platform;
    }

    public get getUserAgent() {
        return this.window.navigator.userAgent;
    }

    getHostname(): string {
        return this.window.location.hostname;
    }

    public get getDocument(): Document {
        return this.window.document;
    }

    public get getLocation(): Location {
        return this.window.location;
    }

    open(url): void {
        this.window.open(url, '_blank');
    }
}
