import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'app-overwatch-dialog',
    templateUrl: './overwatch-dialog.component.html',
    styleUrls: ['./overwatch-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverwatchDialogComponent implements OnInit {
    url = 'https://www.theerap.com/';
    urlMap: SafeResourceUrl | undefined;
    constructor(
        public dialogRef: MatDialogRef<OverwatchDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
        public sanitizer: DomSanitizer,
    ) {}

    ngOnInit() {
        this.urlMap = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    }

    close(): void {
        this.dialogRef.close();
    }
}
