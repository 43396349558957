import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
    providedIn: 'root',
})
export class EncryptionService {
    encrypt(strToEncrypt: string, salt: string): string {
        const key1 = CryptoJS.SHA256(salt).toString(CryptoJS.enc.Hex).substr(0, 32);
        const encrypted = CryptoJS.AES.encrypt(strToEncrypt, CryptoJS.enc.Hex.parse(key1), {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7,
        });
        return encrypted.toString();
    }
}
