import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LocalStorageService, TutorialsService } from 'src/app/store/services';
import { skin } from 'src/white-labels';
import { AuthActions } from '../../store/actions/auth.actions';
import { ILanguage, IUser } from '../../store/interfaces';
import { IAppState } from '../../store/state/app.state';
import {
    LANGUAGES,
    LANGUAGES_MAMORI,
    LANGUAGES_TNT,
    MAIN_TOUR_STEPS,
} from '../../store/constants/common.constants';
import { Observable } from 'rxjs';
import { selectUnreadEventsList } from '../../store/selectors/unread-events.selector';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
    @Input() user: IUser;
    @Input() isShowMobSupportMenu: boolean;
    unreadEvents$: Observable<number> = this.store.select(selectUnreadEventsList);
    skin = skin;
    languagesList: ILanguage[];
    currentLanguage: string;

    constructor(
        private store: Store<IAppState>,
        public localStorage: LocalStorageService,
        private router: Router,
        private tutorials: TutorialsService,
        private translate: TranslateService,
    ) {
        switch (this.skin.whiteLabel) {
            case 'TAGANDTRACK':
                this.languagesList = LANGUAGES_TNT;
                break;
            case 'MAMORI':
                this.languagesList = LANGUAGES_MAMORI;
                break;
            default:
                this.languagesList = LANGUAGES;
                break;
        }

        this.currentLanguage = this.localStorage.getItem('language');
    }

    goHome(): void {
        if (this.localStorage.getItem('token')) {
            this.router.navigate(['/map']);
        } else {
            this.router.navigate(['/']);
        }
    }

    logout(): void {
        this.store.dispatch(AuthActions.logout({ accountID: this.user.account_id }));
    }

    moveToTutorials(): void {
        this.localStorage.setItem('tutorials-main', 'On');
        this.localStorage.setItem('tutorials-devicePanel', 'On');
        if (this.router.routerState.snapshot.url === '/map/devices') {
            this.tutorials.initTutorials(MAIN_TOUR_STEPS, 'main');
        } else {
            this.router.navigate(['/map']);
        }
    }

    onLanguageChange(language: string): void {
        this.localStorage.setItem('language', language);
        this.translate.use(language);
        this.currentLanguage = language;
    }
}
