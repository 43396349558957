import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { ActionsSubject, Store } from '@ngrx/store';
import { from, Observable, Subject } from 'rxjs';
import { DevicesActions } from 'src/app/store/actions/devices.actions';
import {
    ICongratsMessage,
    IPlan,
    IPlanDTO,
    IStatusLabel,
    IStripeServerResponse,
    IUser,
} from 'src/app/store/interfaces';
import { selectDeviceRenewalPlans } from 'src/app/store/selectors/devices.selectors';
import { IAppState } from 'src/app/store/state/app.state';
import { selectBrainTreeToken } from 'src/app/store/selectors/shared.selector';
import { filter, takeUntil } from 'rxjs/operators';
import {
    selectIsPaymentProcessing,
    selectIsStatusLabel,
    selectRenewWithStripeSuccess,
} from 'src/app/store/selectors/status.selectors';
import { StatusLabelActions } from 'src/app/store/actions/status-label.actions';
import dropin from 'braintree-web-drop-in';
import { ofType } from '@ngrx/effects';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SnackbarService } from '../../store/services/snackbar.service';
import { Router } from '@angular/router';
import { ApiService } from '../../store/services';
import { PAYMENT_TYPE } from '../../store/constants';
import { AngularStripeService } from '@fireflysemantics/angular-stripe-service';
import { selectUser } from '../../store/selectors/user.selector';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-update-plan-dialog',
    templateUrl: './update-plan-dialog.component.html',
    styleUrls: ['./update-plan-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdatePlanDialogComponent implements OnInit, OnDestroy {
    plan$: Observable<IPlan>;
    paymentStatus$: Observable<IStatusLabel>;
    paymentProcessing$: Observable<boolean>;
    isShowRenewWithStripeSuccess$: Observable<boolean>;
    stripePlans: IPlan;
    showStripePlans = false;
    accountID: number;
    selectedPlan: IPlanDTO;
    isBrainTreeToken: boolean;
    isProceededToCouponForm: boolean;
    congratsMessage: ICongratsMessage;
    couponForm: UntypedFormGroup = this.formBuilder.group({
        couponValue: ['', [Validators.required]],
    });
    dropinInstance: any;
    isChoiceStepProceeded: boolean;
    isProceededToBraintree: boolean;
    isProceededToPrepaid: boolean;
    isShowPayButton: boolean;
    isProceededToStripe: boolean;
    listener: any;
    paymentGateway: string;
    private destroyed$ = new Subject<void>();
    //for STRIPE
    @ViewChild('cardInfo', { static: false }) cardInfo: ElementRef;
    stripe;
    _totalAmount: number;
    _currency: string;
    loading = false;
    card: any;
    cardHandler = this.onChange.bind(this);
    error: string;
    customerId: string;
    ephemeralKeySecret: string;
    publishableKey: string;
    setUpIntentClientSecret: string;

    constructor(
        public dialogRef: MatDialogRef<UpdatePlanDialogComponent>,
        private formBuilder: UntypedFormBuilder,
        private store: Store<IAppState>,
        private cdRef: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public data,
        private actionsListener$: ActionsSubject,
        private snackBar: SnackbarService,
        private renderer: Renderer2,
        private router: Router,
        private apiService: ApiService,
        private stripeService: AngularStripeService,
    ) {}

    ngOnInit(): void {
        this.store
            .select(selectUser)
            .pipe(takeUntil(this.destroyed$))
            .subscribe((user: IUser) => (this.accountID = user.account_id));
        this.apiService
            .getDevicePaymentGateway(this.data.currentDeviceID)
            .pipe(
                filter((token) => Boolean(token)),
                takeUntil(this.destroyed$),
                filter((res) => !!res),
            )
            .subscribe((res) => {
                this.paymentGateway = res[0]['features']['payment_gateway'];
                if (this.paymentGateway !== PAYMENT_TYPE.STRIPE) {
                    this.store.dispatch(
                        DevicesActions.getRenewalPlans({ deviceID: this.data.currentDeviceID }),
                    );
                    this.plan$ = this.store.select(selectDeviceRenewalPlans);
                    this.store
                        .select(selectBrainTreeToken)
                        .pipe(
                            filter((token) => Boolean(token)),
                            takeUntil(this.destroyed$),
                        )
                        .subscribe((token) => {
                            this.isBrainTreeToken = true;
                            this.cdRef.markForCheck();
                            this.createBraintreeUI(token);
                        });
                    this.cdRef.markForCheck();
                    this.listener = this.renderer.listen('document', 'change', (evt) => {
                        if (document.getElementsByClassName('braintree-sheet--active').length > 0) {
                            this.isShowPayButton = true;
                        } else {
                            this.isShowPayButton = false;
                        }
                        this.cdRef.markForCheck();
                    });
                } else {
                    this.apiService
                        .getStripeDeviceRenewalPlans(this.data.currentDeviceID)
                        .pipe(takeUntil(this.destroyed$))
                        .subscribe((res) => {
                            this.showStripePlans = true;
                            this.stripePlans = res;
                            this.cdRef.markForCheck();
                        });

                    this.isShowRenewWithStripeSuccess$ = this.store.select(selectRenewWithStripeSuccess);
                }
            });

        this.paymentStatus$ = this.store.select(selectIsStatusLabel);
        this.paymentProcessing$ = this.store.select(selectIsPaymentProcessing);
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
        if (this.listener) {
            this.renderer.listen('body', 'click', (event) => {
                this.listener();
            });
        }
        this.store.dispatch(DevicesActions.clearRenewWithStripeStatus());
        this.store.dispatch(DevicesActions.resetPaymentProcessingStatus());
        this.store.dispatch(DevicesActions.removeBrainTreeDropInToken());
        this.store.dispatch(StatusLabelActions.hideStatusLabel());
    }

    choosePlanOption() {
        if (this.selectedPlan) {
            this.isChoiceStepProceeded = true;
            if (this.selectedPlan.paymentPlatform === PAYMENT_TYPE.STRIPE) {
                this.isProceededToStripe = true;
                this._totalAmount = +this.selectedPlan.amount;
                this._currency = this.selectedPlan.currency;
                setTimeout(() => {
                    this.apiService
                        .getStripeCredentials(this.data.currentDeviceID)
                        .pipe(takeUntil(this.destroyed$))
                        .subscribe((res: IStripeServerResponse) => {
                            this.customerId = res.customerId;
                            this.ephemeralKeySecret = res.ephemeralKeySecret;
                            this.publishableKey = res.publishableKey;
                            this.setUpIntentClientSecret = res.setUpIntentClientSecret;
                            // drawing stripe UI
                            this.stripeService.setPublishableKey(this.publishableKey).then((stripe) => {
                                this.stripe = stripe;
                                const elements = stripe.elements();
                                this.card = elements.create('card');
                                this.card.mount(this.cardInfo.nativeElement);
                                this.card.addEventListener('change', this.cardHandler);
                            });
                        });
                    this.cdRef.markForCheck();
                }, 0);
            } else if (this.selectedPlan.paymentPlatform === PAYMENT_TYPE.PREPAID) {
                this.isProceededToPrepaid = true;
                this.renewPrepaidPlan();
            } else if (
                this.selectedPlan.paymentPlatform === PAYMENT_TYPE.NONCE ||
                this.selectedPlan.paymentPlatform === PAYMENT_TYPE.NONCE_ONE_TIME ||
                this.selectedPlan.paymentPlatform === PAYMENT_TYPE.PAYPAL
            ) {
                this.isProceededToBraintree = true;
                this.goToPayment();
            } else if (this.selectedPlan.paymentPlatform === PAYMENT_TYPE.COUPON) {
                this.isProceededToCouponForm = true;
            } else {
                this.snackBar.error('AN_ERROR_OCCURRED_TRY_AGAIN_LATER_OR_CONTACT_TO_CUSTOMER_SUPPORT');
            }
        } else {
            this.snackBar.warning('CHOOSE_PAYMENT_PLAN');
        }
    }

    close(): void {
        this.dialogRef.close();

        if (this.dialogRef.disableClose) {
            this.router.navigate(['map/devices']);
        }
    }

    closeWithConfirm(): void {
        this.dialogRef.close(true);
    }

    goToPayment(): void {
        this.isShowPayButton = true;
        const payload = {
            planID: this.selectedPlan.id,
            deviceID: this.data.currentDeviceID,
        };
        this.store.dispatch(DevicesActions.getBrainTreeDropInToken({ payload }));
    }

    createBraintreeUI(token: string): void {
        from(
            dropin.create({
                authorization: token,
                container: document.getElementById('#dropin-container'),
                paypal: {
                    flow: 'vault',
                    amount: this.selectedPlan.amount,
                    currency: this.selectedPlan.currency,
                },
            }),
        )
            .pipe(takeUntil(this.destroyed$))
            .subscribe((instance) => (this.dropinInstance = instance));
    }

    renewPlan(): void {
        from(this.dropinInstance.requestPaymentMethod())
            .pipe(takeUntil(this.destroyed$))
            .subscribe((reqPayload) => {
                this.congratsMessage = {
                    planID: this.selectedPlan.id,
                    planDesc: this.selectedPlan.name,
                    planPrice: `${this.selectedPlan.amount} ${this.selectedPlan.currency}`,
                };
                const payload = {
                    plan: { nonce: reqPayload['nonce'], plan_id: this.selectedPlan.id },
                    deviceID: this.data.currentDeviceID,
                    masterAccountID: this.data.currentAccountID,
                };
                this.store.dispatch(DevicesActions.renewPlan({ payload }));
                this.cdRef.markForCheck();
            });
    }

    renewPrepaidPlan(): void {
        const payload = {
            planID: this.selectedPlan.id,
            deviceID: this.data.currentDeviceID,
            masterAccountID: this.data.currentAccountID,
        };
        this.actionsListener$
            .pipe(ofType(DevicesActions.renewPrepaidPlan))
            .pipe(takeUntil(this.destroyed$))
            .subscribe((data: any) => {
                this.congratsMessage = {
                    planID: this.selectedPlan.id,
                    planDesc: this.selectedPlan.name,
                };
            });
        return this.store.dispatch(DevicesActions.renewPrepaidPlan({ payload }));
    }

    renewPlanByCouponCode(isValid: boolean): void {
        if (isValid) {
            const payload = {
                deviceID: this.data.currentDeviceID,
                coupon: this.couponForm.value['couponValue'],
                masterAccountID: this.data.currentAccountID,
            };
            this.actionsListener$
                .pipe(ofType(DevicesActions.renewPlanByCoupon))
                .pipe(takeUntil(this.destroyed$))
                .subscribe((data: any) => {
                    this.congratsMessage = {
                        planID: this.selectedPlan.id,
                        planDesc: this.selectedPlan.name,
                    };
                });
            this.store.dispatch(DevicesActions.renewPlanByCoupon({ payload }));
        }
    }

    // STRIPE
    onChange({ error }) {
        if (error) {
            this.error = error.message;
        } else {
            this.error = null;
        }
        this.cdRef.detectChanges();
    }

    renewWithStripe() {
        from(
            this.stripe.confirmCardSetup(this.setUpIntentClientSecret, {
                payment_method: {
                    card: this.card,
                },
            }),
        )
            .pipe(takeUntil(this.destroyed$))
            .subscribe((res) => {
                if (res) {
                    this.congratsMessage = {
                        planID: this.selectedPlan.id,
                        planDesc: this.selectedPlan.name,
                    };
                    this.store.dispatch(
                        DevicesActions.renewPlanWithStripe({
                            account_id: this.accountID,
                            device_id: this.data.currentDeviceID,
                            plan_id: this.selectedPlan.id,
                        }),
                    );
                }
            });
    }
}
