<div class="device-wrapp" [tooltip]="$any(statusLabel)" contentType="template" placement="right" theme="light"
    [shadow]="false" [offset]="20" [hideDelay]="0"
    [display]="!!device.allSOSEvents?.length && skin.whiteLabel !== 'TAGANDTRACK'">
    <div class="device" (click)="onDeviceClick(device)"
        [ngClass]="{ inactiveDevice: device.location_ping?.shutdown_reason === 'POWER_BUTTON_SHUTDOWN' || device.location_ping?.shutdown_reason === 'UNKNOWN' || device.location_ping?.shutdown_reason === 'UNKNOWN_REASON' && isHideChevron }"
        *ngIf="device; else loader">
        <div class="device-img">
            <i class="icon"
                [ngClass]="{ sos: device.allSOSEvents?.length, inactive: device.location_ping?.shutdown_reason === 'POWER_BUTTON_SHUTDOWN' || device.location_ping?.shutdown_reason === 'UNKNOWN' || device.location_ping?.shutdown_reason === 'UNKNOWN_REASON' }"
                [inlineSVG]="device.icon" *ngIf="!device?.icon_url; else deviceImg"></i>
            <ng-template #deviceImg>
                <img class="img" [src]="device?.icon_url" alt="{{ device?.device_id }}" />
            </ng-template>
            <app-device-battery class="battery" [batteryLevel]="device?.battery || device?.location?.battery">
            </app-device-battery>
            <span *ngIf="device.pending_fields_count" class="counter">{{ device.pending_fields_count }}</span>
        </div>
        <div class="device-names-wrap">
            <h5 class="device-title" [ngClass]="{ sos: device.allSOSEvents?.length }"
                title="{{ device.device_name ? device.device_name : device.device_id }}{{ (device?.battery && device?.battery < 10) || !device?.battery ? (' - '+('CHARGE_BATTERY' | translate)) : ''}}">
                {{ device.device_name ? device.device_name : device.device_id }}
                <span *ngIf="(device?.battery && device?.battery < 10) || !device?.battery">{{ 'CHARGE_BATTERY' |
                    translate }}</span>
            </h5>
            <h5 class="device-address" *ngIf="device.lat && device.lng">
                <ng-container *ngIf="isShowAddressImmediately; else showAddressOnDemand">
                    {{ device.address }}
                </ng-container>
                <ng-template #showAddressOnDemand>
                    <ng-container *ngIf="!device.address; else addressContainer">
                        <span class="show-address" (click)="showDeviceAddress($event)">{{
                            'SHOW_ADDRESS' | translate
                            }}</span>
                    </ng-container>
                    <ng-template #addressContainer>
                        <span>{{ device.address }}</span>
                    </ng-template>
                </ng-template>
            </h5>
            <h5 class="device-address warning" *ngIf="isHideChevron && !(device.lat && device.lng)">
                {{ 'CONNECTION_LOST' | translate }}...
            </h5>
        </div>
    </div>

    <div class="icons-wrapp" *ngIf="!isWifiZone">
        <i class="icon" *ngIf="device.status === deviceStatus.EXPIRED" [inlineSVG]="'#renew_subscription'"
            (click)="renewSubscription(device?.device_id, $event)"></i>

        <i class="icon" *ngIf="isHideChevron && !(device.lat && device.lng)" [inlineSVG]="'#warning_2'"></i>

        <i class="icon right" *ngIf="!isHideChevron" [inlineSVG]="'#chevron_right'"></i>

        <mat-slide-toggle *ngIf="isAddZone" [(ngModel)]="device.isChecked" (change)="onToggleChange(device, $event)"
            color="primary" [ngClass]="{ disabled: device.device_type === eTollDeviceType }">
        </mat-slide-toggle>

        <i class="icon no-fill sos-alarm" *ngIf="device.allSOSEvents?.length && skin.whiteLabel !== 'TAGANDTRACK'"
            [inlineSVG]="'#alarm_sos'"></i>

        <img *ngIf="device.is_overwatch_supported" src="assets/images/erap.svg" alt="ERAP" class="overwatch-img" />

        <i class="icon more" *ngIf="user?.subAccountEnabled" [inlineSVG]="'#more'" matRipple
            [matMenuTriggerFor]="menuDevice" [matMenuTriggerData]="{device: device}"></i>

        <mat-checkbox class="checkbox" *ngIf="isShowHistorySelect" [disabled]="device.status !== 'active'
            || selectedDevicesList.length >= MAX_DEVICES_HISTORY_SELECT
            && !(selectedDevicesList.indexOf(device.device_id) > -1)" [checked]="device.isChecked"
            [checked]="selectedDevicesList.indexOf(device.device_id) > -1" (change)="checkboxChange(device)"
            color="primary">
        </mat-checkbox>
    </div>

    <div class="icons-wrapp" *ngIf="selectedDevice && !isLastWifiStep">
        <i class="icon" [inlineSVG]="'#refresh'" (click)="refreshWifiList(device)"></i>
    </div>

    <div class="icons-wrapp" *ngIf="isLastWifiStep">
        <mat-checkbox class="checkbox" color="primary" [checked]="device.isChecked"
            (change)="onToggleChange(device, $event)">
        </mat-checkbox>
    </div>

    <ng-template #statusLabel>
        <app-status-label [statusLabel]="sosStatusLabel" [address]="device.address" [isHoverType]="true">
        </app-status-label>
    </ng-template>
</div>

<ng-template #loader>
    {{ 'LOADING' | translate }}
</ng-template>

<mat-menu class="menu menu-device" #menuDevice="matMenu" xPosition="after">
    <ng-template matMenuContent let-device="device">
        <button class="sub-menu-item" (click)="moveDeviceToDescendant(device)" mat-menu-item rtl>{{
            'MOVE_DEVICE_TO_DESCENDANT' | translate }}</button>
    </ng-template>
</mat-menu>