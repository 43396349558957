import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { interval, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { EventsActions } from '../actions/events.actions';
import { API_PING_INTERVAL } from '../constants/common.constants';
import { selectDevicesList, selectSectedDevice } from '../selectors/devices.selectors';
import { selectUser } from '../selectors/user.selector';
import { ApiService, GoogleMapService, LocalStorageService } from '../services';
import { IAppState } from '../state/app.state';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '../services/snackbar.service';
import { SoundService } from '../services/sound.service';
import { UserActions } from '../actions/user.actionsts';
import { AuthActions } from '../actions/auth.actions';
import { unreadEventsActions } from '../actions/unread-events.actions';
import { IEventsResponse } from '../interfaces';

@Injectable()
export class EventsEffects {
    updateNotificationStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EventsActions.updateEvent),
            withLatestFrom(this.store.select(selectUser)),
            switchMap(([event, user]) =>
                this.apiService.updateNotificationStatus(user.account_id, event.eventID).pipe(
                    map(() => {
                        this.soundService.stopAudio();
                        return EventsActions.updateEventSuccess({
                            eventsCount: Array.isArray(event.eventID) ? event.eventID.length : 1,
                            msg: this.translate.instant(event.msg),
                        });
                    }),
                    catchError((error) => of(EventsActions.updateEventError({ error }))),
                ),
            ),
        ),
    );

    deleteAllEvents$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EventsActions.deleteAllEvents),
            withLatestFrom(this.store.select(selectUser)),
            switchMap(([, user]) =>
                this.apiService.deleteAllNotifications(user.account_id).pipe(
                    map(() =>
                        EventsActions.deleteAllEventsSuccess({ msg: this.translate.instant('deleted') }),
                    ),
                    catchError((error) => of(EventsActions.deleteAllEventsError({ error }))),
                ),
            ),
        ),
    );

    triggerEventsonGetUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.loginSuccess, UserActions.getUserSuccess),
            withLatestFrom(this.store.select(selectUser)),
            switchMap(([, user]) =>
                this.apiService
                    .getNotReadEvents(user.account_id)
                    .pipe(
                        map((events: IEventsResponse) =>
                            unreadEventsActions.setUnreadEvents({ unreadEvents: events.eventCount }),
                        ),
                    ),
            ),
        ),
    );

    triggerAllEvents$ = createEffect(() =>
        this.actions$.pipe(
            filter(() => !Boolean(this.localStorage.getItem('GQL_LOCATION'))),
            ofType(AuthActions.loginSuccess, UserActions.getUserSuccess),
            withLatestFrom(this.store.select(selectDevicesList)),
            switchMap(() =>
                interval(API_PING_INTERVAL).pipe(
                    withLatestFrom(this.store.select(selectSectedDevice)),
                    filter(([, device]) => !(device && device.history && !!device.history.length)),
                    takeUntil(this.actions$.pipe(ofType(AuthActions.logout))),
                    withLatestFrom(this.store.select(selectUser)),
                    mergeMap(([, user]) =>
                        this.apiService.getNotReadEvents(user.account_id).pipe(
                            tap((response) =>
                                this.store.dispatch(
                                    unreadEventsActions.setUnreadEvents({
                                        unreadEvents: response.eventCount,
                                    }),
                                ),
                            ),
                            map((response) => EventsActions.getSOSDevicesEventsSuccess({ response })),
                        ),
                    ),
                ),
            ),
        ),
    );

    pingDeviceLocationSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                filter(() => !Boolean(this.localStorage.getItem('GQL_LOCATION'))),
                ofType(EventsActions.getSOSDevicesEventsSuccess),
                withLatestFrom(this.store.select(selectSectedDevice)),
                filter(([, device]) => Boolean(device)),
                tap(([, device]) => {
                    if (!device.history?.length && device.location_ping) {
                        this.googleMapService.updateSelectedDeviceMarkerIcon(device);
                    }
                }),
            ),
        { dispatch: false },
    );

    eventSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(EventsActions.updateEventSuccess, EventsActions.deleteAllEventsSuccess),
                tap((resonse) => this.snackBar.success(resonse.msg)),
            ),
        { dispatch: false },
    );

    constructor(
        private actions$: Actions,
        private apiService: ApiService,
        private store: Store<IAppState>,
        private snackBar: SnackbarService,
        private soundService: SoundService,
        private translate: TranslateService,
        private googleMapService: GoogleMapService,
        private localStorage: LocalStorageService,
    ) {}
}
