import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxScannerQrcodeComponent } from 'ngx-scanner-qrcode';
import { filter, Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-qr-modal',
    templateUrl: './qr-modal.component.html',
    styleUrls: ['./qr-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QrModalComponent implements OnDestroy {
    private destroyed$ = new Subject<void>();
    action: NgxScannerQrcodeComponent;

    constructor(public dialogRef: MatDialogRef<QrModalComponent>, @Inject(MAT_DIALOG_DATA) public data) {}

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
        this.action.stop();
    }

    onEvent(action: NgxScannerQrcodeComponent) {
        this.action = action;
        // action.data
        //     .pipe(
        //         takeUntil(this.destroyed$),
        //         filter((res) => Boolean(res)),
        //     )
        //     .subscribe((res) => {
        //         const urlParams = new URL(res).searchParams;
        //         const id = urlParams.get('id');
        //         const im = urlParams.get('im');
        //         this.dialogRef.close({
        //             id,
        //             im,
        //             action,
        //         });
        //     });
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    public onError(e: any): void {
        console.log(e);
    }
}
