<section class="activation-account">
    <h1>{{'CREATE_ACCOUNT' | translate}}</h1>

    <p class="info">{{'ACCOUNT_ACTIVATION_VIA_EMAIL' | translate}}
        {{'SENT_TO_EMAIL' | translate}}
        <strong>{{ userEmail }}</strong>
    </p>
    <p class="resend">
        {{'CODE_NOT_RECIEVED' | translate}}
        <span class="resend-btn" (click)="resendEmailLink()">{{'RESEND_EMAIL' | translate}}</span>
    </p>
    <a class="btn primary" routerLink="/" mat-flat-button>{{'GO_TO_HOMEPAGE' | translate}}</a>
</section>