import { Injectable } from '@angular/core';
import { WindowService } from './window.service';
import { LocalStorageService } from './local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { COUNTRIES, DEFAULT_LANGUAGE, LANGUAGES_TNT } from '../constants';

@Injectable({ providedIn: 'root' })
export class LocaleService {
    public countriesInfo = COUNTRIES;
    public languagesTNT = LANGUAGES_TNT;
    private DEFAULT_LOCALE = 'en-US';

    constructor(
        private window: WindowService,
        private localStorage: LocalStorageService,
        private translate: TranslateService,
    ) {}

    getUsersLocale(defaultValue: string = this.DEFAULT_LOCALE): string {
        const navigator = this.window.getWindow.navigator;
        if (!navigator) {
            return defaultValue;
        }
        let lang = navigator.languages ? navigator.languages[0] : defaultValue;
        lang = lang || navigator.language || navigator['browserLanguage'] || navigator['userLanguage'];
        if (lang === 'en') {
            return defaultValue;
        }
        return lang;
    }

    getUserCountryCode(): string {
        return this.getUsersLocale().replace(/.*-/, '').toLowerCase();
    }

    getCurrentCountryInfo(): any[] {
        const currentCountryCode = this.getUserCountryCode();
        return (
            this.countriesInfo.find((country) => country[1] === currentCountryCode) || [
                'United States',
                'us',
                '1',
                0,
            ]
        );
    }

    getCurrentCountryInfoByCountryCode(phoneCountryCode: string): (string | number | string[])[] {
        return this.countriesInfo.find((country) => country[1] === phoneCountryCode.toLowerCase());
    }

    getCurrentCountryInfoByCode(countryCode: string): (string | number | string[])[] {
        return this.countriesInfo.find((country) => country[2] === countryCode);
    }

    // TnT Feature
    changeLanguageFromUrl(langKey = DEFAULT_LANGUAGE) {
        const langKeys = this.languagesTNT.filter((lang) => lang.value === langKey);
        if (langKeys.length) {
            this.localStorage.setItem('language', langKey);
            this.translate.use(langKey);
        }
    }
}
