import { createReducer, on } from '@ngrx/store';
import { DevicesActions } from '../actions/devices.actions';
import { DEVICES_TYPES, METRIC_SYSTEM } from '../constants/common.constants';
import { IDeviceLocationEvent, IDeviceShort, ISubscription, IZone } from '../interfaces';
import { initialDeviceState } from '../state/devices.stete';
import { converter } from '../services/helper';
import { EventsActions } from '../actions/events.actions';
import { skin } from 'src/white-labels';

export const devicesReducer = createReducer(
    initialDeviceState,
    on(DevicesActions.getDevicesError, () => initialDeviceState),
    on(DevicesActions.getTotalDevicesCountSuccess, (state, { response }) => ({
        ...state,
        devicesTotalCount: response.count,
    })),
    on(DevicesActions.getDevicesSuccess, (state, { response }) => {
        let devices = [];
        let lDevices = response.map((device: IDeviceShort) => ({
            ...device,
            icon:
                skin.whiteLabel === 'TRACKIPET'
                    ? '#pawPrint'
                    : DEVICES_TYPES.find((d) => +d.icon_id === device.icon_id)?.icon ||
                      (device.is_overwatch_supported ? '#curve' : '#universal'),
        }));
        if (state.devices) {
            devices = [...state.devices, ...lDevices].sort((a, b) => a.device_name - b.device_name);
        } else {
            devices = [...lDevices];
        }

        return {
            ...state,
            devices,
        };
    }),
    on(DevicesActions.clearDevices, (state, {}) => ({
        ...state,
        devices: undefined,
    })),
    on(DevicesActions.getAddressForDeviceSuccess, (state, { deviceID, address }) => {
        if (state.selectedDevice) {
            return {
                ...state,
                selectedDevice: {
                    ...state.selectedDevice,
                    location: {
                        ...state.selectedDevice.location,
                        address: address,
                    },
                },
            };
        } else {
            return {
                ...state,
                devices: state.devices?.map((device: IDeviceShort) => {
                    if (device.device_id === deviceID) {
                        return {
                            ...device,
                            address,
                        };
                    }
                    return device;
                }),
            };
        }
    }),
    on(DevicesActions.getAddressForDeviceError, (state, { error, deviceID, errorText }) => ({
        ...state,
        devices: state.devices?.map((device: IDeviceShort) => {
            if (device.device_id === deviceID) {
                return {
                    ...device,
                    address: errorText,
                };
            }
            return device;
        }),
    })),
    on(DevicesActions.deleteDeviceHistorySuccess, (state) => ({
        ...state,
        selectedDevice: {
            ...state.selectedDevice,
            history: [],
        },
    })),
    on(DevicesActions.hideDeviceHistoryInfo, (state) => ({
        ...state,
        selectedDevice: {
            ...state.selectedDevice,
            history: [],
        },
    })),
    on(DevicesActions.getDeviceByIDSuccess, (state, { device, user }) => {
        const altitude = device.location?.altitude;

        let selectedDevice = {
            ...device,
            info: {
                ...device.info,
                icon: getDeviceIcon(device) || (device.features.overwatch ? '#curve' : '#universal'),
            },
            location: {
                ...device.location,
                altitude: altitude ? `${altitude} M` : 'N/A',
            },
        };

        if (user.preferences.speed_unit === METRIC_SYSTEM.MPH) {
            selectedDevice.location.altitude = altitude
                ? `${converter.metricToImperial.ft(altitude)} FT`
                : 'N/A';
        }

        return {
            ...state,
            selectedDevice,
        };
    }),
    on(DevicesActions.setDeviceSettingsSuccess, (state, { deviceName, preferences, usageName }) => ({
        ...state,
        selectedDevice: {
            ...state.selectedDevice,
            info: {
                ...state.selectedDevice.info,
                nick_name: deviceName,
                usage: usageName,
            },
            settings: {
                ...state.selectedDevice.settings,
                preferences: {
                    ...state.selectedDevice.settings.preferences,
                    ...preferences,
                },
            },
        },
    })),
    on(DevicesActions.getDeviceHistorySuccess, (state, { response }) => {
        const clearHistoryPoints = response.filter((h) => h.lat && h.lng);
        return {
            ...state,
            selectedDevice: {
                ...state.selectedDevice,
                history: clearHistoryPoints.map((history, i) => ({
                    ...history,
                    toTime: !history.event_id
                        ? clearHistoryPoints[i + 1]?.time
                            ? clearHistoryPoints[i + 1]?.time
                            : clearHistoryPoints[i]?.time
                        : null,
                })),
            },
        };
    }),
    on(DevicesActions.updateDeviceImageSuccess, (state, response) => ({
        ...state,
        selectedDevice: {
            ...state.selectedDevice,
            info: {
                ...state.selectedDevice.info,
                ...mapIcon(response),
            },
        },
    })),
    on(DevicesActions.assignDeviceSuccess, (state, { zone }) => ({
        ...state,
        selectedDevice: {
            ...state.selectedDevice,
            assigned_fences: [...state.selectedDevice.assigned_fences, zone],
        },
    })),
    on(DevicesActions.assignDeviceError, (state) => ({
        ...state,
        selectedDevice: {
            ...state.selectedDevice,
            assigned_fences: [...state.selectedDevice.assigned_fences],
        },
    })),
    on(DevicesActions.unassignDeviceSuccess, (state, { zone }) => ({
        ...state,
        selectedDevice: {
            ...state.selectedDevice,
            assigned_fences: state.selectedDevice.assigned_fences.filter(
                (gzone: IZone) => gzone.id !== zone.id,
            ),
        },
    })),
    on(DevicesActions.getRenewalPlansSuccess, (state, { plan }) => ({
        ...state,
        selectedDevice: {
            ...state.selectedDevice,
            renewalsPlans: plan,
        },
    })),
    on(DevicesActions.getDevicesFPIPendingFieldsCountSuccess, (state, { response }) => ({
        ...state,
        devices: state.devices?.map((device) => ({
            ...device,
            pending_fields_count: response.find((inDevice) => inDevice.device_id === device.device_id)
                ?.pending_fields_count,
        })),
    })),
    on(EventsActions.getSOSDevicesEventsSuccess, (state, { response }) => ({
        ...state,
        devices: state.devices?.map((device) => ({
            ...device,
            allSOSEvents: response.alarmTypesDevices.filter(
                (event) =>
                    (event.alarmType === 'SOS' || event.eventType === 'SOS') &&
                    +event.deviceId === device.device_id,
            ),
        })),
    })),
    on(EventsActions.getSOSDevicesEventsSuccess, (state, { response }) => {
        if (state.selectedDevice) {
            return {
                ...state,
                selectedDevice: {
                    ...state.selectedDevice,
                    allSOSEvents: response.alarmTypesDevices.filter(
                        (event) =>
                            (event.alarm_type === 'SOS' || event.eventType === 'SOS') &&
                            +event.deviceId === state.selectedDevice.info.device_id,
                    ),
                },
            };
        }

        return state;
    }),
    on(DevicesActions.updateDeviceEventSuccess, (state, res) => {
        if (state.selectedDevice) {
            return {
                ...state,
                selectedDevice: {
                    ...state.selectedDevice,
                    allSOSEvents:
                        res.events.length === 1
                            ? state.selectedDevice.allSOSEvents.filter(
                                  (inEvent) =>
                                      inEvent.eventId !== res.events[0].eventId ||
                                      inEvent.id !== res.events[0].id,
                              )
                            : res.events.find(
                                  (event) => +event.deviceId === state.selectedDevice.info.device_id,
                              )
                            ? []
                            : state.selectedDevice.allSOSEvents,
                },
            };
        }

        if (state.devices) {
            return {
                ...state,
                devices: state.devices.map((device) => ({
                    ...device,
                    allSOSEvents:
                        res.events.length === 1
                            ? device.allSOSEvents.filter(
                                  (inEvent) =>
                                      inEvent.eventId !== res.events[0].eventId ||
                                      inEvent.id !== res.events[0].id,
                              )
                            : res.events.find((event) => +event.deviceId === device.device_id)
                            ? []
                            : device.allSOSEvents,
                })),
            };
        }

        return state;
    }),

    on(DevicesActions.removeSelectedDevice, (state, {}) => ({
        ...state,
        selectedDevice: null,
    })),
    on(DevicesActions.getDevicesSubscriptionSuccess, (state, { response }) => ({
        ...state,
        devicesSubscriptions: response.map((subscription: ISubscription) => ({
            ...subscription,
            icon_type_id:
                skin.whiteLabel === 'TRACKIPET'
                    ? '#pawPrint'
                    : subscription.extra_param.icon_id !== 1
                    ? DEVICES_TYPES.find((d) => +d.icon_id === subscription.extra_param.icon_id)?.icon
                    : '#universal',
        })),
    })),
    on(DevicesActions.pingDeviceLocationSuccess, (state, { response }) => ({
        ...state,
        selectedDevice: {
            ...state.selectedDevice,
            location_ping: response,
        },
    })),
    on(DevicesActions.pingDevicesLocationsSuccess, (state, { response }) => ({
        ...state,
        devices: state.devices?.map((device) => ({
            ...device,
            location_ping: response.find(
                (location: IDeviceLocationEvent) => location.device_id === device.device_id,
            ),
        })),
    })),
    // @TODO AFTER NEW API WILL BE READY
    on(DevicesActions.getDevicePetDetailsSuccess, (state, { response }) => ({
        ...state,
        selectedDevice: {
            ...state.selectedDevice,
            info: {
                ...state.selectedDevice.info,
                petDetailsSettings: response,
            },
        },
    })),
    on(DevicesActions.getPetActivityInfoSuccess, (state, { response }) => ({
        ...state,
        selectedDevice: {
            ...state.selectedDevice,
            petInfo: { ...response, transformedAvrTemp: '0', transformedDistance: '0' },
        },
    })),
    on(DevicesActions.getDeviceFPIDetailsSuccess, (state, { response }) => ({
        ...state,
        selectedDevice: {
            ...state.selectedDevice,
            info: {
                ...state.selectedDevice.info,
                deviceFPISettings: response,
            },
        },
    })),
    on(DevicesActions.setFPIDetailsSettingsSuccess, (state, { response }) => ({
        ...state,
        selectedDevice: {
            ...state.selectedDevice,
            info: {
                ...state.selectedDevice.info,
                deviceFPISettings: response,
            },
        },
    })),

    ///**** Renewals ****///
    on(DevicesActions.increaseSMSLimitSuccess, (state, { smsLimit, haveSelectedDevice }) => {
        if (haveSelectedDevice) {
            return {
                ...state,
                selectedDevice: {
                    ...state.selectedDevice,
                    info: {
                        ...state.selectedDevice.info,
                        sms_limit: state.selectedDevice.info.sms_limit + smsLimit,
                    },
                },
            };
        } else {
            return { ...state };
        }
    }),
    on(
        DevicesActions.renewPlanSuccess,
        DevicesActions.renewPrepaidPlanSuccess,
        DevicesActions.renewPlanByCouponSuccess,
        DevicesActions.renewPlanWithStripeSuccess,
        (state, payload) => {
            if (state.devices) {
                return {
                    ...state,
                    devices: state.devices?.map((device: IDeviceShort) => ({
                        ...device,
                        status: device.device_id === payload.deviceID ? 'active' : device.status,
                    })),
                };
            }
            return state;
        },
    ),
    on(DevicesActions.getDeviceSensorsDetailsSuccess, (state, { response }) => ({
        ...state,
        selectedDevice: {
            ...state.selectedDevice,
            sensors_details: response,
        },
    })),

    on(DevicesActions.getDevicesForFOTASuccess, (state, { response }) => ({
        ...state,
        devicesForFOTA: response.map((inDevice) => {
            return {
                ...inDevice,
                icon: getDeviceIcon(inDevice),
            };
        }),
    })),

    on(DevicesActions.getFOTALogsSuccess, (state, { response }) => ({
        ...state,

        devicesForFOTA: state.devicesForFOTA.map((inDevice) => {
            const activeDeviceStatus = response.find(
                (fotaStatus) => fotaStatus.device_id === inDevice.device_id,
            );

            if (activeDeviceStatus) {
                return {
                    ...inDevice,
                    deviceFotaStatus: activeDeviceStatus.fota_status,
                };
            } else {
                return inDevice;
            }
        }),

        // devicesForFOTA: {
        //     ...state.devicesForFOTA,
        //     devices: state.devicesForFOTA.devices.map((inDevice) => {
        //         const activeDeviceStatus = response.find(
        //             (fotaStatus) => fotaStatus.device_id === inDevice.device_id,
        //         );

        //         if (activeDeviceStatus) {
        //             return {
        //                 ...inDevice,
        //                 deviceFotaStatus: activeDeviceStatus.fota_status,
        //             };
        //         } else {
        //             return inDevice;
        //         }
        //     }),
        // },
    })),

    // response.map((fotaStatus) => {
    //     console.log(state);

    //     return state.devicesForFOTA?.devices
    //         .filter((inDevice) => inDevice.fota_required)
    //         .map((inDevice) => {
    //             console.log(inDevice);

    //             if (fotaStatus.device_id === inDevice.device_id) {
    //                 return {
    //                     ...inDevice,
    //                     deviceFotaStatus: fotaStatus.fota_status,
    //                 };
    //             } else {
    //                 return inDevice;
    //             }
    //         });
    // }),
);

const mapIcon = (res) =>
    res.icon_url
        ? { icon_url: res.icon_url }
        : {
              icon_id: res.icon,
              icon: getDeviceIcon(res),
              icon_url: undefined,
          };

const getDeviceIcon = (inDevice) =>
    skin.whiteLabel === 'TRACKIPET'
        ? '#pawPrint'
        : DEVICES_TYPES.find((divice) => +divice.icon_id === (inDevice.icon || inDevice.info.icon_id))?.icon;
