import { trigger, transition, style, animate } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-carousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss'],
    animations: [
        trigger('carouselAnimation', [
            transition('void => *', [style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))]),
            transition('* => void', [animate('300ms', style({ opacity: 0 }))]),
        ]),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselComponent implements OnInit {
    @Input() slides;
    currentSlide = 0;
    constructor(private router: Router) {}

    ngOnInit(): void {}

    onPreviousClick() {
        const previous = this.currentSlide - 1;
        this.currentSlide = previous < 0 ? this.slides.length - 1 : previous;
    }

    onNextClick() {
        const next = this.currentSlide + 1;
        this.currentSlide = next === this.slides.length ? 0 : next;
    }

    goToSlide(index: number) {
        this.currentSlide = index;
    }

    skipIntro() {
        if (this.currentSlide === this.slides.length - 1) {
            this.router.navigate(['/account-settings']);
        } else {
            this.router.navigate(['/firmware-list']);
        }
    }

    nextSlide() {
        if (this.currentSlide === this.slides.length - 1) {
            this.router.navigate(['/firmware-list']);
        } else {
            this.onNextClick();
        }
    }
}
