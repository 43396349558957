import { IEvent } from '../interfaces';

export interface IUnreadEventsState {
    unreadEvents: number;
    sosMsgs: IEvent[];
}

export const initialUnreadEventsState = {
    unreadEvents: undefined,
    sosMsgs: undefined,
};
