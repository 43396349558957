<section *ngIf="lastLoginUserInfo">
    <app-status-label *ngIf="loginError$ | async" [statusLabel]="loginError$ | async"></app-status-label>

    <h1>{{ 'WELCOME_BACK' | translate }}</h1>

    <div class="user-info">
        <img src="{{ lastLoginUserInfo.photoUrl || lastLoginUserInfo.accountIconUrl ? lastLoginUserInfo.photoUrl || lastLoginUserInfo.accountIconUrl : '/assets/images/user-icon.svg' }}"
            alt="user">
        <div>
            <h3 *ngIf="lastLoginUserInfo.firstName">{{lastLoginUserInfo.firstName}} {{lastLoginUserInfo.lastName}}</h3>
            <p>{{lastLoginUserInfo.email}}</p>
        </div>
    </div>

    <p class="desc">{{ 'LAST_LOGIN_TEXT' | translate: { provider: lastLoginUserInfo.provider } }}</p>

    <app-social-btns [buttonToShow]="lastLoginUserInfo.provider"></app-social-btns>

    <div class="devider">
        <span></span>
        <p>{{ 'OR' | translate }}</p>
        <span></span>
    </div>

    <form [formGroup]="form" (ngSubmit)="onSubmit(form.valid)">
        <mat-form-field class="form-field" appearance="outline">
            <mat-label>{{ 'PASSWORD' | translate }}</mat-label>
            <input formControlName="password" matInput [type]="showPassword ? 'text' : 'password'"
                placeholder="{{ 'PASSWORD' | translate }}" />

            <i class="icon" (click)="changeVisiblePassword()" matSuffix
                [inlineSVG]="showPassword ? '#eye' : '#eye-close'"></i>
        </mat-form-field>

        <div class="link-container">
            <mat-checkbox class="form-field checkbox" color="primary" formControlName="isKeepLogged">
                {{ 'KEEP_ME_LOGGED' | translate }}
            </mat-checkbox>

            <span class="link" routerLink="/forgot-password"> {{ 'FORGOT_PASSWORD' | translate }} </span>
        </div>

        <button class="btn primary form-btn" mat-flat-button>
            {{ 'CONTINUE' | translate }}
        </button>
    </form>

</section>