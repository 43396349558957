<div class="cookie-bar" [ngClass]="skin.whiteLabel">
    <div class="cookie-bar-left-side">
        <span>
            {{'COOKIE_MSG1' | translate}}
        </span>
        <span>
            {{'COOKIE_MSG2' | translate}}
        </span>
        <a target="_blank" href="{{skin.privacyPolicy}}">
            {{'PRIVACY_POLICY' | translate}}.
        </a>
        <span>
            {{'WE_ALSO_URGE_YOU_TO_READ' | translate}}
        </span>
        <a target="_blank" href="{{skin.termsOfService}}">
            {{'TERMS_AND_CONDITIONS' | translate}}
        </a>
        <span>
            {{'COOKIE_MSG3' | translate}}
        </span>
    </div>
    <div class="cookie-bar-right-side">
        <button (click)="acceptCookie()" class="btn outline" mat-flat-button>{{'ACCEPT_CLOSE' | translate}}</button>
    </div>
</div>