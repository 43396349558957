import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-back-btn',
    templateUrl: './back-btn.component.html',
    styleUrls: ['./back-btn.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackBtnComponent {
    @Input() title: string;
    @Input() url?: string;
    @Input() output?: boolean;
    @Output() goBack = new EventEmitter();

    constructor(private location: Location, private router: Router) {}

    backClicked() {
        if (this.output) {
            this.goBack.emit();
            return;
        }

        if (this.url) {
            this.router.navigate([this.url]);
        } else {
            this.location.back();
        }
    }
}
