import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IZone } from '../interfaces';

const prefix = '[Zones API]';

const getZones = createAction(`${prefix} Get Zones`);
const getZonesSuccess = createAction(`${prefix} Get Zones Success`, props<{ response: IZone[] }>());
const getZonesError = createAction(`${prefix} Get Zones Error`, props<{ error: HttpErrorResponse }>());

const getZoneByID = createAction(`${prefix} Get Zone By ID`, props<{ zoneID: number }>());
const getZoneByIDSuccess = createAction(`${prefix} Get Zone By ID Success`, props<{ response: any }>());
const getZoneByIDError = createAction(
    `${prefix} Get Zone By ID Error`,
    props<{ error: HttpErrorResponse }>(),
);
const removeSelectedZone = createAction(`${prefix} Remove Selected Zone`);

const createNewZone = createAction(`${prefix} Create New Zone`, props<{ zone: IZone }>());
const createNewZoneSuccess = createAction(
    `${prefix} Create New Zone Success`,
    props<{ zone: IZone; msg: string }>(),
);
const createNewZoneError = createAction(
    `${prefix} Create New Zone Error`,
    props<{ error: HttpErrorResponse }>(),
);

const updateZone = createAction(`${prefix} Update Zone`, props<{ zone: IZone }>());
const updateZoneSuccess = createAction(
    `${prefix} Update Zone Success`,
    props<{ zone: IZone; msg: string }>(),
);
const updateZoneError = createAction(`${prefix} Update Zone Error`, props<{ error: HttpErrorResponse }>());

const removeZone = createAction(`${prefix} Remove Zones`);
const removeZoneSuccess = createAction(`${prefix} Remove Zones Success`, props<{ msg: string }>());
const removeZoneError = createAction(`${prefix} Remove Zone Error`, props<{ error: HttpErrorResponse }>());

const changeZoneType = createAction(`${prefix} Change Zone Type`, props<{ zoneType: string }>());

const resetZonesLoaded = createAction(`${prefix} Reset Zones Loaded`);

export const ZonesActions = {
    getZones,
    getZonesSuccess,
    getZonesError,

    getZoneByID,
    getZoneByIDSuccess,
    getZoneByIDError,
    removeSelectedZone,

    createNewZone,
    createNewZoneSuccess,
    createNewZoneError,

    updateZone,
    updateZoneSuccess,
    updateZoneError,

    removeZone,
    removeZoneSuccess,
    removeZoneError,

    changeZoneType,

    resetZonesLoaded,
};
