<div class="dialog-wrapper">
    <div class="title" mat-dialog-title>
        <h1>{{ modalTitle | translate }}</h1>
        <i class="icon" (click)="closeDialog()" [inlineSVG]="'#close'"></i>
    </div>
    <div class="content" mat-dialog-content>

        <div class="intro" *ngIf="!isWifiListShown">
            <div class="info">
                <h3 class="old">Your Firmware {{data.current_fw}} is outdated.</h3>
                <p>{{ 'PLS_UPDATE_FW' | translate }}</p>

                <p class="date">06.13.2024</p>
                <h3 class="new">Trackipet Device Firmware update {{data.new_fw}}</h3>

                <ul>
                    <li>{{data.description}}</li>
                    <!-- <li>Upgraded location accuracy</li>
                    <li>Fixed geo-fence accuracy</li> -->
                </ul>
            </div>
            <img src="assets/images/firmware-update.svg" alt="firmware-update">
        </div>

        <ng-container *ngIf="isWifiListShown">
            <ng-container *ngIf="!wifiSelected">
                <ng-container *ngIf="wifiList?.length; else spinner">
                    <div class="wifi-item" *ngFor="let wifi of wifiList" (click)="onWifiClick(wifi)">
                        {{wifi.wifiName}}
                        <i class="icon" *ngIf="wifi.wifiSignal >= 80" [inlineSVG]="'#wifi-full'"></i>
                        <i class="icon" *ngIf="wifi.wifiSignal > 30 && wifi.wifiSignal < 80"
                            [inlineSVG]="'#wifi-middle'"></i>
                        <i class="icon" *ngIf="wifi.wifiSignal < 30" [inlineSVG]="'#wifi-low'"></i>
                    </div>
                </ng-container>

                <ng-template #spinner>
                    <div class="loader-wrapper">
                        <mat-spinner class="spinner" diameter="60"></mat-spinner>
                    </div>
                </ng-template>
            </ng-container>

            <ng-container *ngIf="wifiSelected">

                <ng-container *ngIf="!petWifiLoaderShown">
                    <div class="wifi-error" *ngIf="isWifiConnectionError">
                        <i class="icon" [inlineSVG]="'#error'"></i>
                        {{ 'INCORRECT_WIFI_PASS' | translate: {wifi_name: wifiSelected.wifiName} }}
                    </div>
                    <mat-form-field class="form-field" appearance="outline">
                        <mat-label>{{ 'Enter password' | translate }}</mat-label>
                        <input [formControl]="wifiPassword" matInput [type]="showPassword ? 'text' : 'password'"
                            placeholder="{{ 'Enter password' | translate }}" />
                        <i class="icon" (click)="changeVisiblePassword()" matSuffix
                            [inlineSVG]="showPassword ? '#eye' : '#eye-close'"></i>
                    </mat-form-field>
                </ng-container>


                <ng-container *ngIf="petWifiLoaderShown">

                    <ng-container *ngIf="!confirmShown">
                        <div class="paw-loader-gif">
                            <img src="assets/images/paw.gif" alt="">
                        </div>
                    </ng-container>

                    <ng-container *ngIf="confirmShown">
                        <h3 class="confirm">{{ 'YOU_WILL_LOSE_CONNECTION_ON_YES' | translate }}
                        </h3>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>

    <div class="nav-container" mat-dialog-actions>
        <ng-container *ngIf="!wifiSelected">
            <button class="btn basic" (click)="closeDialog()">{{ (isWifiListShown ? 'CANCEL' : 'Update later') |
                translate
                }}</button>
            <button class="btn primary" (click)="onUpdateNow()" *ngIf="!isWifiListShown" mat-flat-button>
                {{ 'Update now' | translate }}
            </button>
        </ng-container>

        <ng-container *ngIf="wifiSelected">
            <ng-container *ngIf="!confirmShown">
                <button class="btn basic" (click)="backToWifiList()">{{ 'CANCEL' | translate }}</button>
                <button class="btn primary" (click)="onWifiPasswordEnter()" *ngIf="!petWifiLoaderShown"
                    mat-flat-button>{{ 'OK' | translate }}</button>
            </ng-container>

            <ng-container *ngIf="confirmShown">
                <button class="btn basic" (click)="backToLoading()">{{ 'CANCEL' | translate }}</button>
                <button class="btn primary" (click)="closeDialog()" mat-flat-button>{{ 'YES' | translate }}</button>
            </ng-container>
        </ng-container>
    </div>
</div>