import { NgModule } from '@angular/core';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { InMemoryCache, split } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { OperationDefinitionNode } from 'graphql';
import { environment } from '../environments/environment';

@NgModule({
    exports: [ApolloModule],
    providers: [
        {
            provide: APOLLO_OPTIONS,
            useFactory(httpLink: HttpLink) {
                const http = httpLink.create({
                    uri: 'graphql/graphql',
                });

                const domain = new URL(environment.baseRedirectUrl);
                let activeSocket, timedOut;
                const ws = new GraphQLWsLink(
                    createClient({
                        url: `wss://${domain.hostname}/graphql/subscriptions`,
                        keepAlive: 10000,
                        on: {
                            connected: (socket) => (activeSocket = socket),
                            ping: (received) => {
                                if (!received)
                                    timedOut = setTimeout(() => {
                                        if (activeSocket.readyState === WebSocket.OPEN)
                                            activeSocket.close(4408, 'Request Timeout');
                                    }, 5000);
                            },
                            pong: (received) => {
                                if (received) clearTimeout(timedOut);
                            },
                        },

                        connectionParams: {
                            Authorization: localStorage.getItem('token'),
                        },
                        shouldRetry: () => true,
                    }),
                );

                // using the ability to split links, you can send data to each link
                // depending on what kind of operation is being sent
                const link = split(
                    ({ query }) => {
                        const { kind, operation } = getMainDefinition(query) as OperationDefinitionNode;
                        return kind === 'OperationDefinition' && operation === 'subscription';
                    },
                    ws,
                    http,
                );

                return {
                    link,
                    cache: new InMemoryCache({ resultCaching: false }),
                };
            },
            deps: [HttpLink],
        },
    ],
})
export class GraphQLModule {}
