import { createReducer, on } from '@ngrx/store';
import { ZonesActions } from '../actions/zones.actions';
import { initialZoneState } from '../state/zones.stete';
import { ZoneType } from '../constants';

export const zonesReducer = createReducer(
    initialZoneState,
    on(ZonesActions.getZonesError, () => initialZoneState),
    on(ZonesActions.getZonesSuccess, (state, { response }) => {
        return {
            ...state,
            zones: response,
        };
    }),
    on(ZonesActions.getZoneByIDSuccess, (state, { response }) => ({
        ...state,
        selectedZone: response,
    })),
    on(ZonesActions.removeSelectedZone, (state) => ({
        ...state,
        selectedZone: undefined,
    })),
);
