<div class="dialog-wrapper add-contact-dialog" rtl>
    <div mat-dialog-title>
        <h1 class="title">{{ 'STOP_LIVE_TRACKING' | translate }}</h1>
    </div>
    <div mat-dialog-content>
        <p>{{ 'STOP_LIVE_TRACKING_MSG' | translate }}</p>
    </div>
    <div mat-dialog-actions>
        <div class="dialog-actions">
            <button class="btn basic" (click)="closeDialog(false)" mat-flat-button>
                {{ 'CANCEL' | translate }}
            </button>
            <button class="btn primary" (click)="closeDialog(true)" mat-flat-button>
                {{ 'STOP' | translate }}
            </button>
        </div>
    </div>
</div>