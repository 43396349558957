export const NOTIFICATION_TYPES = [
    {
        alarmTypeAsInt: 0,
        alarm_type: 'GEOZONE',
        alarm_icon: '#alarm_zone',
        color: 'red',
    },
    {
        alarmTypeAsInt: 1,
        alarm_type: 'SPEED',
        alarm_icon: '#alarm_speed',
        isNoFill: true,
        color: 'red',
    },
    {
        alarmTypeAsInt: 2, // for both START/STOP MOVEMENT EVENT
        alarm_type: 'MOVEMENT',
        alarm_icon: '#alarm_movement',
        isNoFill: true,
        color: 'red',
    },
    {
        alarmTypeAsInt: 3,
        alarm_type: 'BATTERY',
        alarm_icon: '#alarm_battery',
        color: 'red',
    },
    {
        alarmTypeAsInt: 4,
        alarm_type: 'SHAKING',
        alarm_icon: '#alarm_shaking',
        color: 'blue',
    },
    {
        alarmTypeAsInt: 5,
        alarm_type: 'BLIND',
        alarm_icon: '#alarm_blind',
        color: 'blue',
    },
    {
        alarmTypeAsInt: 6,
        alarm_type: 'RIGHT',
        alarm_icon: '#alarm_right_button',
        color: 'blue',
    },
    {
        alarmTypeAsInt: 7,
        alarm_type: 'LEFT',
        alarm_icon: '#alarm_left_button',
        color: 'blue',
    },
    {
        alarmTypeAsInt: 8,
        alarm_type: 'SOS',
        alarm_icon: '#alarm_sos',
        color: 'red',
    },
    {
        alarmTypeAsInt: 9,
        alarm_type: 'LIGHT',
        alarm_icon: '#alarm_light',
        color: 'blue',
    },
    {
        alarmTypeAsInt: 10,
        alarm_type: 'POI',
        alarm_icon: '#alarm_poi',
        color: 'blue',
    },
    {
        alarmTypeAsInt: 11,
        alarm_type: 'FLIGHT',
        alarm_icon: '#alarm_flight',
        color: 'blue',
    },
    {
        alarmTypeAsInt: 12,
        alarm_type: 'alarm_bluetooth', // Bluetooth Proximity
        alarm_icon: '#alarm_sos',
        color: 'blue',
    },
    {
        alarmTypeAsInt: 13,
        alarm_type: 'WIFI_SAFEZONE',
        alarm_icon: '#alarm_wi-fi',
        color: 'blue',
    },
    {
        alarmTypeAsInt: 14,
        alarm_type: 'LOCK_UNLOCK_EVENT',
        alarm_icon: '#alarm_locker',
        color: 'blue',
    },
    {
        alarmTypeAsInt: 15,
        alarm_type: 'GEOZONE_ENTRY', // This EventType is used only from server side.
        alarm_icon: '#alarm_geozone',
        color: 'green',
    },
    {
        alarmTypeAsInt: 16,
        alarm_type: 'GEOZONE_EXIT', // This EventType is used only from server side.
        alarm_icon: '#alarm_geozone',
        color: 'green',
    },
    {
        alarmTypeAsInt: 17,
        alarm_type: 'CHARGE_START',
        alarm_icon: '#alarm_charging_start',
        color: 'blue',
    },
    {
        alarmTypeAsInt: 18,
        alarm_type: 'CHARGE_DONE',
        alarm_icon: '#alarm_charging_done',
        color: '#33CC5A',
    },
    {
        alarmTypeAsInt: 19,
        alarm_type: 'CHARGE_STOP',
        alarm_icon: '#alarm_charging_stop',
        color: 'red',
    },
    {
        alarmTypeAsInt: 20,
        alarm_type: 'SHUTDOWN',
        alarm_icon: '#alarm_power_off',
        color: 'red',
    },
    {
        alarmTypeAsInt: 21,
        alarm_type: 'WAKE_UP',
        alarm_icon: '#alarm_power_on',
        color: 'blue',
    },
    {
        alarmTypeAsInt: 22,
        alarm_type: 'NON_TRACKING',
        alarm_icon: '#alarm_warning',
        color: 'red',
    },
    {
        alarmTypeAsInt: 23,
        alarm_type: 'TEMPERATURE',
        alarm_icon: '#alarm_temperature',
        color: 'blue',
    },
    {
        alarmTypeAsInt: 24,
        alarm_type: 'TAMPERING',
        alarm_icon: '#alarm_warning',
        color: 'red',
    },
    {
        alarmTypeAsInt: 28,
        alarm_type: 'FOTA_STATUS_INITIATED_EVENT',
        alarm_icon: '#alarm_wi-fi',
        color: 'green',
    },
    {
        alarmTypeAsInt: 29,
        alarm_type: 'FOTA_STATUS_SUCCESS_EVENT',
        alarm_icon: '#alarm_wi-fi',
        color: 'green',
    },
    {
        alarmTypeAsInt: 30,
        alarm_type: 'FOTA_STATUS_FAILED_EVENT',
        alarm_icon: '#alarm_wi-fi',
        color: 'red',
    },
    {
        alarmTypeAsInt: 31,
        alarm_type: 'FOTA_STATUS_MANUAL_REBOOT_EVENT',
        alarm_icon: '#alarm_wi-fi',
        color: 'green',
    },
    {
        alarmTypeAsInt: 32,
        alarm_type: 'WIFIZONE_ENTRY', // This EventType is used only from server side.
        alarm_icon: '#alarm_geozone',
        color: 'green',
    },
    {
        alarmTypeAsInt: 33,
        alarm_type: 'WIFIZONE_EXIT', // This EventType is used only from server side.
        alarm_icon: '#alarm_geozone',
        color: 'green',
    },
];

export const SOS_ALARM_TYPE_ID = 8;
