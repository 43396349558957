<router-outlet></router-outlet>
<tour-step-template>
    <ng-template let-step="step">
        <div class="tutorial-step" (click)="$event.stopPropagation()"
            [class.arrow-move-center]="step?.stepId === 'arrowDownCenter'"
            [class.arrow-move-left]="step?.stepId === 'arrowDownLeft'">
            <div class="tutorial-step-circle  {{ skin.whiteLabel }}"
                [class.circle-center]="step?.stepId === 'arrowDownCenter'"
                [class.circle-left]="step?.stepId === 'arrowDownLeft'"
                [class.circle-precision]="step?.stepId === 'precisionCircle'"></div>
            <div class="tutorial-step-title">{{ step?.title | translate }}</div>
            <div class="tutorial-step-content" [innerHTML]="step?.content | translate"></div>
            <div class="tutorial-step-buttons">
                <button mat-flat-button class="btn btn-skip" (click)="tutorials.turnOffTutorials(step)">
                    {{ 'skip' | translate }}
                </button>
                <button mat-flat-button class="btn" *ngIf="tutorials.isHasNextStep(step)"
                    (click)="tutorials.nextStep()">
                    {{ 'NEXT' | translate }}
                </button>
                <button mat-flat-button class="btn" *ngIf="!tutorials.isHasNextStep(step)"
                    (click)="tutorials.endTutorials(step)">
                    {{ 'NEXT' | translate }}
                </button>
            </div>
        </div>
    </ng-template>
</tour-step-template>