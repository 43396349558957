export const COUNTRIES = [
    ['Afghanistan', 'af', '93'],
    ['Albania', 'al', '355'],
    ['Algeria', 'dz', '213'],
    ['American Samoa', 'as', '1684'],
    ['Andorra', 'ad', '376'],
    ['Angola', 'ao', '244'],
    ['Anguilla', 'ai', '1264'],
    ['Antigua and Barbuda', 'ag', '1268'],
    ['Argentina', 'ar', '54'],
    ['Armenia', 'am', '374'],
    ['Aruba', 'aw', '297'],
    ['Australia', 'au', '61', 0],
    ['Austria', 'at', '43'],
    ['Azerbaijan', 'az', '994'],
    ['Bahamas', 'bs', '1242'],
    ['Bahrain', 'bh', '973'],
    ['Bangladesh', 'bd', '880'],
    ['Barbados', 'bb', '1246'],
    ['Belarus', 'by', '375'],
    ['Belgium', 'be', '32'],
    ['Belize', 'bz', '501'],
    ['Benin', 'bj', '229'],
    ['Bermuda', 'bm', '1441'],
    ['Bhutan', 'bt', '975'],
    ['Bolivia', 'bo', '591'],
    ['Bosnia and Herzegovina', 'ba', '387'],
    ['Botswana', 'bw', '267'],
    ['Brazil', 'br', '55'],
    ['British Indian Ocean Territory', 'io', '246'],
    ['British Virgin Islands', 'vg', '1284'],
    ['Brunei', 'bn', '673'],
    ['Bulgaria', 'bg', '359'],
    ['Burkina Faso', 'bf', '226'],
    ['Burundi', 'bi', '257'],
    ['Cambodia', 'kh', '855'],
    ['Cameroon', 'cm', '237'],
    [
        'Canada',
        'ca',
        '1',
        1,
        [
            '204',
            '226',
            '236',
            '249',
            '250',
            '289',
            '306',
            '343',
            '365',
            '387',
            '403',
            '416',
            '418',
            '431',
            '437',
            '438',
            '450',
            '506',
            '514',
            '519',
            '548',
            '579',
            '581',
            '587',
            '604',
            '613',
            '639',
            '647',
            '672',
            '705',
            '709',
            '742',
            '778',
            '780',
            '782',
            '807',
            '819',
            '825',
            '867',
            '873',
            '902',
            '905',
        ],
    ],
    ['Cape Verde', 'cv', '238'],
    ['Caribbean Netherlands', 'bq', '599', 1],
    ['Cayman Islands', 'ky', '1345'],
    ['Central African Republic', 'cf', '236'],
    ['Chad', 'td', '235'],
    ['Chile', 'cl', '56'],
    ['China', 'cn', '86'],
    ['Christmas Island', 'cx', '61', 2],
    ['Cocos Islands', 'cc', '61', 1],
    ['Colombia', 'co', '57'],
    ['Comoros', 'km', '269'],
    ['Congo (DRC)', 'cd', '243'],
    ['Congo (Republic)', 'cg', '242'],
    ['Cook Islands', 'ck', '682'],
    ['Costa Rica', 'cr', '506'],
    ['Côte d’Ivoire', 'ci', '225'],
    ['Croatia', 'hr', '385'],
    ['Cuba', 'cu', '53'],
    ['Curaçao', 'cw', '599', 0],
    ['Cyprus', 'cy', '357'],
    ['Czech Republic', 'cz', '420'],
    ['Denmark', 'dk', '45'],
    ['Djibouti', 'dj', '253'],
    ['Dominica', 'dm', '1767'],
    ['Dominican Republic', 'do', '1', 2, ['809', '829', '849']],
    ['Ecuador', 'ec', '593'],
    ['Egypt', 'eg', '20'],
    ['El Salvador', 'sv', '503'],
    ['Equatorial Guinea', 'gq', '240'],
    ['Eritrea', 'er', '291'],
    ['Estonia', 'ee', '372'],
    ['Ethiopia', 'et', '251'],
    ['Falkland Islands', 'fk', '500'],
    ['Faroe Islands', 'fo', '298'],
    ['Fiji', 'fj', '679'],
    ['Finland', 'fi', '358', 0],
    ['France', 'fr', '33'],
    ['French Guiana', 'gf', '594'],
    ['French Polynesia', 'pf', '689'],
    ['Gabon', 'ga', '241'],
    ['Gambia', 'gm', '220'],
    ['Georgia', 'ge', '995'],
    ['Germany', 'de', '49'],
    ['Ghana', 'gh', '233'],
    ['Gibraltar', 'gi', '350'],
    ['Greece', 'gr', '30'],
    ['Greenland', 'gl', '299'],
    ['Grenada', 'gd', '1473'],
    ['Guadeloupe', 'gp', '590', 0],
    ['Guam', 'gu', '1671'],
    ['Guatemala', 'gt', '502'],
    ['Guinea', 'gn', '224'],
    ['Guinea-Bissau', 'gw', '245'],
    ['Guyana', 'gy', '592'],
    ['Haiti', 'ht', '509'],
    ['Honduras', 'hn', '504'],
    ['Hong Kong', 'hk', '852'],
    ['Hungary', 'hu', '36'],
    ['Iceland', 'is', '354'],
    ['India', 'in', '91'],
    ['Indonesia', 'id', '62'],
    ['Iran', 'ir', '98'],
    ['Iraq', 'iq', '964'],
    ['Ireland', 'ie', '353'],
    ['Israel', 'il', '972'],
    ['Italy', 'it', '39', 0],
    ['Jamaica', 'jm', '1876'],
    ['Japan', 'jp', '81'],
    ['Jordan', 'jo', '962'],
    ['Kazakhstan', 'kz', '7', 1],
    ['Kenya', 'ke', '254'],
    ['Kiribati', 'ki', '686'],
    ['Kosovo', 'xk', '383'],
    ['Kuwait', 'kw', '965'],
    ['Kyrgyzstan', 'kg', '996'],
    ['Laos', 'la', '856'],
    ['Latvia', 'lv', '371'],
    ['Lebanon', 'lb', '961'],
    ['Lesotho', 'ls', '266'],
    ['Liberia', 'lr', '231'],
    ['Libya', 'ly', '218'],
    ['Liechtenstein', 'li', '423'],
    ['Lithuania', 'lt', '370'],
    ['Luxembourg', 'lu', '352'],
    ['Macau', 'mo', '853'],
    ['Macedonia', 'mk', '389'],
    ['Madagascar', 'mg', '261'],
    ['Malawi', 'mw', '265'],
    ['Malaysia', 'my', '60'],
    ['Maldives', 'mv', '960'],
    ['Mali', 'ml', '223'],
    ['Malta', 'mt', '356'],
    ['Marshall Islands', 'mh', '692'],
    ['Martinique', 'mq', '596'],
    ['Mauritania', 'mr', '222'],
    ['Mauritius', 'mu', '230'],
    ['Mayotte', 'yt', '262', 1],
    ['Mexico', 'mx', '52'],
    ['Micronesia', 'fm', '691'],
    ['Moldova', 'md', '373'],
    ['Monaco', 'mc', '377'],
    ['Mongolia', 'mn', '976'],
    ['Montenegro', 'me', '382'],
    ['Montserrat', 'ms', '1664'],
    ['Morocco', 'ma', '212', 0],
    ['Mozambique', 'mz', '258'],
    ['Myanmar', 'mm', '95'],
    ['Namibia', 'na', '264'],
    ['Nauru', 'nr', '674'],
    ['Nepal', 'np', '977'],
    ['Netherlands', 'nl', '31'],
    ['New Caledonia', 'nc', '687'],
    ['New Zealand', 'nz', '64'],
    ['Nicaragua', 'ni', '505'],
    ['Niger', 'ne', '227'],
    ['Nigeria', 'ng', '234'],
    ['Niue', 'nu', '683'],
    ['Norfolk Island', 'nf', '672'],
    ['North Korea', 'kp', '850'],
    ['Northern Mariana Islands', 'mp', '1670'],
    ['Norway', 'no', '47', 0],
    ['Oman', 'om', '968'],
    ['Pakistan', 'pk', '92'],
    ['Palau', 'pw', '680'],
    ['Palestine', 'ps', '970'],
    ['Panama', 'pa', '507'],
    ['Papua New Guinea', 'pg', '675'],
    ['Paraguay', 'py', '595'],
    ['Peru', 'pe', '51'],
    ['Philippines', 'ph', '63'],
    ['Poland', 'pl', '48'],
    ['Portugal', 'pt', '351'],
    ['Puerto Rico', 'pr', '1', 3, ['787', '939']],
    ['Qatar', 'qa', '974'],
    ['Réunion', 're', '262', 0],
    ['Romania', 'ro', '40'],
    ['Russia', 'ru', '7', 0],
    ['Rwanda', 'rw', '250'],
    ['Saint Barthélemy', 'bl', '590', 1],
    ['Saint Helena', 'sh', '290'],
    ['Saint Kitts and Nevis', 'kn', '1869'],
    ['Saint Lucia', 'lc', '1758'],
    ['Saint Martin', 'mf', '590', 2],
    ['Saint Pierre and Miquelon', 'pm', '508'],
    ['Saint Vincent and the Grenadines', 'vc', '1784'],
    ['Samoa', 'ws', '685'],
    ['San Marino', 'sm', '378'],
    ['São Tomé and Príncipe', 'st', '239'],
    ['Saudi Arabia', 'sa', '966'],
    ['Senegal', 'sn', '221'],
    ['Serbia', 'rs', '381'],
    ['Seychelles', 'sc', '248'],
    ['Sierra Leone', 'sl', '232'],
    ['Singapore', 'sg', '65'],
    ['Sint Maarten', 'sx', '1721'],
    ['Slovakia', 'sk', '421'],
    ['Slovenia', 'si', '386'],
    ['Solomon Islands', 'sb', '677'],
    ['Somalia', 'so', '252'],
    ['South Africa', 'za', '27'],
    ['South Korea', 'kr', '82'],
    ['South Sudan', 'ss', '211'],
    ['Spain', 'es', '34'],
    ['Sri Lanka', 'lk', '94'],
    ['Sudan', 'sd', '249'],
    ['Suriname', 'sr', '597'],
    ['Svalbard and Jan Mayen', 'sj', '47', 1],
    ['Swaziland', 'sz', '268'],
    ['Sweden', 'se', '46'],
    ['Switzerland', 'ch', '41'],
    ['Syria', 'sy', '963'],
    ['Taiwan', 'tw', '886'],
    ['Tajikistan', 'tj', '992'],
    ['Tanzania', 'tz', '255'],
    ['Thailand', 'th', '66'],
    ['Timor-Leste', 'tl', '670'],
    ['Togo', 'tg', '228'],
    ['Tokelau', 'tk', '690'],
    ['Tonga', 'to', '676'],
    ['Trinidad and Tobago', 'tt', '1868'],
    ['Tunisia', 'tn', '216'],
    ['Turkey', 'tr', '90'],
    ['Turkmenistan', 'tm', '993'],
    ['Turks and Caicos Islands', 'tc', '1649'],
    ['Tuvalu', 'tv', '688'],
    ['U.S. Virgin Islands', 'vi', '1340'],
    ['Uganda', 'ug', '256'],
    ['Ukraine', 'ua', '380'],
    ['United Arab Emirates', 'ae', '971'],
    ['United Kingdom', 'gb', '44', 0],
    ['United States', 'us', '1', 0],
    ['Uruguay', 'uy', '598'],
    ['Uzbekistan', 'uz', '998'],
    ['Vanuatu', 'vu', '678'],
    ['Vatican City', 'va', '39', 1],
    ['Venezuela', 've', '58'],
    ['Vietnam', 'vn', '84'],
    ['Wallis and Futuna', 'wf', '681'],
    ['Western Sahara', 'eh', '212', 1],
    ['Yemen', 'ye', '967'],
    ['Zambia', 'zm', '260'],
    ['Zimbabwe', 'zw', '263'],
    ['Åland Islands', 'ax', '358', 1],
];

export const COUNTRIES_NAMES = [
    { code: 'AF', name: 'Afghanistan' },
    { code: 'AX', name: 'Aland Islands' },
    { code: 'AL', name: 'Albania' },
    { code: 'DZ', name: 'Algeria' },
    { code: 'AS', name: 'American Samoa' },
    { code: 'AD', name: 'Andorra' },
    { code: 'AO', name: 'Angola' },
    { code: 'AI', name: 'Anguilla' },
    { code: 'AQ', name: 'Antarctica' },
    { code: 'AG', name: 'Antigua And Barbuda' },
    { code: 'AR', name: 'Argentina' },
    { code: 'AM', name: 'Armenia' },
    { code: 'AW', name: 'Aruba' },
    { code: 'AU', name: 'Australia' },
    { code: 'AT', name: 'Austria' },
    { code: 'AZ', name: 'Azerbaijan' },
    { code: 'BS', name: 'Bahamas' },
    { code: 'BH', name: 'Bahrain' },
    { code: 'BD', name: 'Bangladesh' },
    { code: 'BB', name: 'Barbados' },
    { code: 'BY', name: 'Belarus' },
    { code: 'BE', name: 'Belgium' },
    { code: 'BZ', name: 'Belize' },
    { code: 'BJ', name: 'Benin' },
    { code: 'BM', name: 'Bermuda' },
    { code: 'BT', name: 'Bhutan' },
    { code: 'BO', name: 'Bolivia' },
    { code: 'BA', name: 'Bosnia And Herzegovina' },
    { code: 'BW', name: 'Botswana' },
    { code: 'BV', name: 'Bouvet Island' },
    { code: 'BR', name: 'Brazil' },
    { code: 'IO', name: 'British Indian Ocean Territory' },
    { code: 'BN', name: 'Brunei Darussalam' },
    { code: 'BG', name: 'Bulgaria' },
    { code: 'BF', name: 'Burkina Faso' },
    { code: 'BI', name: 'Burundi' },
    { code: 'KH', name: 'Cambodia' },
    { code: 'CM', name: 'Cameroon' },
    { code: 'CA', name: 'Canada' },
    { code: 'CV', name: 'Cape Verde' },
    { code: 'KY', name: 'Cayman Islands' },
    { code: 'CF', name: 'Central African Republic' },
    { code: 'TD', name: 'Chad' },
    { code: 'CL', name: 'Chile' },
    { code: 'CN', name: 'China' },
    { code: 'CX', name: 'Christmas Island' },
    { code: 'CC', name: 'Cocos (Keeling) Islands' },
    { code: 'CO', name: 'Colombia' },
    { code: 'KM', name: 'Comoros' },
    { code: 'CG', name: 'Congo' },
    { code: 'CD', name: 'Congo}, Democratic Republic' },
    { code: 'CK', name: 'Cook Islands' },
    { code: 'CR', name: 'Costa Rica' },
    { code: 'CI', name: 'Ivory Coast' },
    { code: 'HR', name: 'Croatia' },
    { code: 'CU', name: 'Cuba' },
    { code: 'CY', name: 'Cyprus' },
    { code: 'CZ', name: 'Czech Republic' },
    { code: 'DK', name: 'Denmark' },
    { code: 'DJ', name: 'Djibouti' },
    { code: 'DM', name: 'Dominica' },
    { code: 'DO', name: 'Dominican Republic' },
    { code: 'EC', name: 'Ecuador' },
    { code: 'EG', name: 'Egypt' },
    { code: 'SV', name: 'El Salvador' },
    { code: 'GQ', name: 'Equatorial Guinea' },
    { code: 'ER', name: 'Eritrea' },
    { code: 'EE', name: 'Estonia' },
    { code: 'ET', name: 'Ethiopia' },
    { code: 'FK', name: 'Falkland Islands (Malvinas)' },
    { code: 'FO', name: 'Faroe Islands' },
    { code: 'FJ', name: 'Fiji' },
    { code: 'FI', name: 'Finland' },
    { code: 'FR', name: 'France' },
    { code: 'GF', name: 'French Guiana' },
    { code: 'PF', name: 'French Polynesia' },
    { code: 'TF', name: 'French Southern Territories' },
    { code: 'GA', name: 'Gabon' },
    { code: 'GM', name: 'Gambia' },
    { code: 'GE', name: 'Georgia' },
    { code: 'DE', name: 'Germany' },
    { code: 'GH', name: 'Ghana' },
    { code: 'GI', name: 'Gibraltar' },
    { code: 'GR', name: 'Greece' },
    { code: 'GL', name: 'Greenland' },
    { code: 'GD', name: 'Grenada' },
    { code: 'GP', name: 'Guadeloupe' },
    { code: 'GU', name: 'Guam' },
    { code: 'GT', name: 'Guatemala' },
    { code: 'GG', name: 'Guernsey' },
    { code: 'GN', name: 'Guinea' },
    { code: 'GW', name: 'Guinea-Bissau' },
    { code: 'GY', name: 'Guyana' },
    { code: 'HT', name: 'Haiti' },
    { code: 'HM', name: 'Heard Island & Mcdonald Islands' },
    { code: 'VA', name: 'Holy See (Vatican City State)' },
    { code: 'HN', name: 'Honduras' },
    { code: 'HK', name: 'Hong Kong' },
    { code: 'HU', name: 'Hungary' },
    { code: 'IS', name: 'Iceland' },
    { code: 'IN', name: 'India' },
    { code: 'ID', name: 'Indonesia' },
    { code: 'IR', name: 'Iran}, Islamic Republic Of' },
    { code: 'IQ', name: 'Iraq' },
    { code: 'IE', name: 'Ireland' },
    { code: 'IM', name: 'Isle Of Man' },
    { code: 'IL', name: 'Israel' },
    { code: 'IT', name: 'Italy' },
    { code: 'JM', name: 'Jamaica' },
    { code: 'JP', name: 'Japan' },
    { code: 'JE', name: 'Jersey' },
    { code: 'JO', name: 'Jordan' },
    { code: 'KZ', name: 'Kazakhstan' },
    { code: 'KE', name: 'Kenya' },
    { code: 'KI', name: 'Kiribati' },
    { code: 'KR', name: 'Korea' },
    { code: 'KW', name: 'Kuwait' },
    { code: 'KG', name: 'Kyrgyzstan' },
    { code: 'LA', name: 'Laos' },
    { code: 'LV', name: 'Latvia' },
    { code: 'LB', name: 'Lebanon' },
    { code: 'LS', name: 'Lesotho' },
    { code: 'LR', name: 'Liberia' },
    { code: 'LY', name: 'Libyan Arab Jamahiriya' },
    { code: 'LI', name: 'Liechtenstein' },
    { code: 'LT', name: 'Lithuania' },
    { code: 'LU', name: 'Luxembourg' },
    { code: 'MO', name: 'Macao' },
    { code: 'MK', name: 'Macedonia' },
    { code: 'MG', name: 'Madagascar' },
    { code: 'MW', name: 'Malawi' },
    { code: 'MY', name: 'Malaysia' },
    { code: 'MV', name: 'Maldives' },
    { code: 'ML', name: 'Mali' },
    { code: 'MT', name: 'Malta' },
    { code: 'MH', name: 'Marshall Islands' },
    { code: 'MQ', name: 'Martinique' },
    { code: 'MR', name: 'Mauritania' },
    { code: 'MU', name: 'Mauritius' },
    { code: 'YT', name: 'Mayotte' },
    { code: 'MX', name: 'Mexico' },
    { code: 'FM', name: 'Micronesia}, Federated States Of' },
    { code: 'MD', name: 'Moldova' },
    { code: 'MC', name: 'Monaco' },
    { code: 'MN', name: 'Mongolia' },
    { code: 'ME', name: 'Montenegro' },
    { code: 'MS', name: 'Montserrat' },
    { code: 'MA', name: 'Morocco' },
    { code: 'MZ', name: 'Mozambique' },
    { code: 'MM', name: 'Myanmar' },
    { code: 'NA', name: 'Namibia' },
    { code: 'NR', name: 'Nauru' },
    { code: 'NP', name: 'Nepal' },
    { code: 'NL', name: 'Netherlands' },
    { code: 'AN', name: 'Netherlands Antilles' },
    { code: 'NC', name: 'New Caledonia' },
    { code: 'NZ', name: 'New Zealand' },
    { code: 'NI', name: 'Nicaragua' },
    { code: 'NE', name: 'Niger' },
    { code: 'NG', name: 'Nigeria' },
    { code: 'NU', name: 'Niue' },
    { code: 'NF', name: 'Norfolk Island' },
    { code: 'MP', name: 'Northern Mariana Islands' },
    { code: 'NO', name: 'Norway' },
    { code: 'OM', name: 'Oman' },
    { code: 'PK', name: 'Pakistan' },
    { code: 'PW', name: 'Palau' },
    { code: 'PS', name: 'Palestinian Territory}, Occupied' },
    { code: 'PA', name: 'Panama' },
    { code: 'PG', name: 'Papua New Guinea' },
    { code: 'PY', name: 'Paraguay' },
    { code: 'PE', name: 'Peru' },
    { code: 'PH', name: 'Philippines' },
    { code: 'PN', name: 'Pitcairn' },
    { code: 'PL', name: 'Poland' },
    { code: 'PT', name: 'Portugal' },
    { code: 'PR', name: 'Puerto Rico' },
    { code: 'QA', name: 'Qatar' },
    { code: 'RE', name: 'Reunion' },
    { code: 'RO', name: 'Romania' },
    { code: 'RU', name: 'Russian Federation' },
    { code: 'RW', name: 'Rwanda' },
    { code: 'BL', name: 'Saint Barthelemy' },
    { code: 'SH', name: 'Saint Helena' },
    { code: 'KN', name: 'Saint Kitts And Nevis' },
    { code: 'LC', name: 'Saint Lucia' },
    { code: 'MF', name: 'Saint Martin' },
    { code: 'PM', name: 'Saint Pierre And Miquelon' },
    { code: 'VC', name: 'Saint Vincent And Grenadines' },
    { code: 'WS', name: 'Samoa' },
    { code: 'SM', name: 'San Marino' },
    { code: 'ST', name: 'Sao Tome And Principe' },
    { code: 'SA', name: 'Saudi Arabia' },
    { code: 'SN', name: 'Senegal' },
    { code: 'RS', name: 'Serbia' },
    { code: 'SC', name: 'Seychelles' },
    { code: 'SL', name: 'Sierra Leone' },
    { code: 'SG', name: 'Singapore' },
    { code: 'SK', name: 'Slovakia' },
    { code: 'SI', name: 'Slovenia' },
    { code: 'SB', name: 'Solomon Islands' },
    { code: 'SO', name: 'Somalia' },
    { code: 'ZA', name: 'South Africa' },
    { code: 'GS', name: 'South Georgia And Sandwich Isl.' },
    { code: 'ES', name: 'Spain' },
    { code: 'LK', name: 'Sri Lanka' },
    { code: 'SD', name: 'Sudan' },
    { code: 'SR', name: 'Suriname' },
    { code: 'SJ', name: 'Svalbard And Jan Mayen' },
    { code: 'SZ', name: 'Swaziland' },
    { code: 'SE', name: 'Sweden' },
    { code: 'CH', name: 'Switzerland' },
    { code: 'SY', name: 'Syrian Arab Republic' },
    { code: 'TW', name: 'Taiwan' },
    { code: 'TJ', name: 'Tajikistan' },
    { code: 'TZ', name: 'Tanzania' },
    { code: 'TH', name: 'Thailand' },
    { code: 'TL', name: 'Timor-Leste' },
    { code: 'TG', name: 'Togo' },
    { code: 'TK', name: 'Tokelau' },
    { code: 'TO', name: 'Tonga' },
    { code: 'TT', name: 'Trinidad And Tobago' },
    { code: 'TN', name: 'Tunisia' },
    { code: 'TR', name: 'Turkey' },
    { code: 'TM', name: 'Turkmenistan' },
    { code: 'TC', name: 'Turks And Caicos Islands' },
    { code: 'TV', name: 'Tuvalu' },
    { code: 'UG', name: 'Uganda' },
    { code: 'UA', name: 'Ukraine' },
    { code: 'AE', name: 'United Arab Emirates' },
    { code: 'GB', name: 'United Kingdom' },
    { code: 'US', name: 'United States' },
    { code: 'UM', name: 'United States Outlying Islands' },
    { code: 'UY', name: 'Uruguay' },
    { code: 'UZ', name: 'Uzbekistan' },
    { code: 'VU', name: 'Vanuatu' },
    { code: 'VE', name: 'Venezuela' },
    { code: 'VN', name: 'Vietnam' },
    { code: 'VG', name: 'Virgin Islands}, British' },
    { code: 'VI', name: 'Virgin Islands}, U.S.' },
    { code: 'WF', name: 'Wallis And Futuna' },
    { code: 'EH', name: 'Western Sahara' },
    { code: 'YE', name: 'Yemen' },
    { code: 'ZM', name: 'Zambia' },
    { code: 'ZW', name: 'Zimbabwe' },
];

export const CANADA_STATES = [
    {
        name: 'Alberta',
        abbreviation: 'AB',
    },
    {
        name: 'British Columbia',
        abbreviation: 'BC',
    },
    {
        name: 'Manitoba',
        abbreviation: 'MB',
    },
    {
        name: 'New Brunswick',
        abbreviation: 'NB',
    },
    {
        name: 'Newfoundland and Labrador',
        abbreviation: 'NL',
    },
    {
        name: 'Northwest Territories',
        abbreviation: 'NT',
    },
    {
        name: 'Nova Scotia',
        abbreviation: 'NS',
    },
    {
        name: 'Nunavut',
        abbreviation: 'NU',
    },
    {
        name: 'Ontario',
        abbreviation: 'ON',
    },
    {
        name: 'Prince Edward Island',
        abbreviation: 'PE',
    },
    {
        name: 'Quebec',
        abbreviation: 'QC',
    },
    {
        name: 'Saskatchewan',
        abbreviation: 'SK',
    },
    {
        name: 'Yukon Territory',
        abbreviation: 'YT',
    },
];

export const USA_STATES = [
    {
        name: 'Alabama',
        abbreviation: 'AL',
    },
    {
        name: 'Alaska',
        abbreviation: 'AK',
    },
    {
        name: 'American Samoa',
        abbreviation: 'AS',
    },
    {
        name: 'Arizona',
        abbreviation: 'AZ',
    },
    {
        name: 'Arkansas',
        abbreviation: 'AR',
    },
    {
        name: 'California',
        abbreviation: 'CA',
    },
    {
        name: 'Colorado',
        abbreviation: 'CO',
    },
    {
        name: 'Connecticut',
        abbreviation: 'CT',
    },
    {
        name: 'Delaware',
        abbreviation: 'DE',
    },
    {
        name: 'District Of Columbia',
        abbreviation: 'DC',
    },
    {
        name: 'Federated States Of Micronesia',
        abbreviation: 'FM',
    },
    {
        name: 'Florida',
        abbreviation: 'FL',
    },
    {
        name: 'Georgia',
        abbreviation: 'GA',
    },
    {
        name: 'Guam',
        abbreviation: 'GU',
    },
    {
        name: 'Hawaii',
        abbreviation: 'HI',
    },
    {
        name: 'Idaho',
        abbreviation: 'ID',
    },
    {
        name: 'Illinois',
        abbreviation: 'IL',
    },
    {
        name: 'Indiana',
        abbreviation: 'IN',
    },
    {
        name: 'Iowa',
        abbreviation: 'IA',
    },
    {
        name: 'Kansas',
        abbreviation: 'KS',
    },
    {
        name: 'Kentucky',
        abbreviation: 'KY',
    },
    {
        name: 'Louisiana',
        abbreviation: 'LA',
    },
    {
        name: 'Maine',
        abbreviation: 'ME',
    },
    {
        name: 'Marshall Islands',
        abbreviation: 'MH',
    },
    {
        name: 'Maryland',
        abbreviation: 'MD',
    },
    {
        name: 'Massachusetts',
        abbreviation: 'MA',
    },
    {
        name: 'Michigan',
        abbreviation: 'MI',
    },
    {
        name: 'Minnesota',
        abbreviation: 'MN',
    },
    {
        name: 'Mississippi',
        abbreviation: 'MS',
    },
    {
        name: 'Missouri',
        abbreviation: 'MO',
    },
    {
        name: 'Montana',
        abbreviation: 'MT',
    },
    {
        name: 'Nebraska',
        abbreviation: 'NE',
    },
    {
        name: 'Nevada',
        abbreviation: 'NV',
    },
    {
        name: 'New Hampshire',
        abbreviation: 'NH',
    },
    {
        name: 'New Jersey',
        abbreviation: 'NJ',
    },
    {
        name: 'New Mexico',
        abbreviation: 'NM',
    },
    {
        name: 'New York',
        abbreviation: 'NY',
    },
    {
        name: 'North Carolina',
        abbreviation: 'NC',
    },
    {
        name: 'North Dakota',
        abbreviation: 'ND',
    },
    {
        name: 'Northern Mariana Islands',
        abbreviation: 'MP',
    },
    {
        name: 'Ohio',
        abbreviation: 'OH',
    },
    {
        name: 'Oklahoma',
        abbreviation: 'OK',
    },
    {
        name: 'Oregon',
        abbreviation: 'OR',
    },
    {
        name: 'Palau',
        abbreviation: 'PW',
    },
    {
        name: 'Pennsylvania',
        abbreviation: 'PA',
    },
    {
        name: 'Puerto Rico',
        abbreviation: 'PR',
    },
    {
        name: 'Rhode Island',
        abbreviation: 'RI',
    },
    {
        name: 'South Carolina',
        abbreviation: 'SC',
    },
    {
        name: 'South Dakota',
        abbreviation: 'SD',
    },
    {
        name: 'Tennessee',
        abbreviation: 'TN',
    },
    {
        name: 'Texas',
        abbreviation: 'TX',
    },
    {
        name: 'Utah',
        abbreviation: 'UT',
    },
    {
        name: 'Vermont',
        abbreviation: 'VT',
    },
    {
        name: 'Virgin Islands',
        abbreviation: 'VI',
    },
    {
        name: 'Virginia',
        abbreviation: 'VA',
    },
    {
        name: 'Washington',
        abbreviation: 'WA',
    },
    {
        name: 'West Virginia',
        abbreviation: 'WV',
    },
    {
        name: 'Wisconsin',
        abbreviation: 'WI',
    },
    {
        name: 'Wyoming',
        abbreviation: 'WY',
    },
];
