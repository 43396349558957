export const TAGANDTRACK = {
    title: 'TAGANDTRACK',
    brandName: 'Tag and Track',
    primaryColor: '#007c92',
    androidAppLink: 'https://play.google.com/store/apps/details?id=com.trackimo.tagandtrack',
    iosAppLink: 'https://apps.apple.com/app/vodafone-business-tag-track/id1477489342',
    localizeLink: 'https://web-tnt-plus.s3-eu-west-1.amazonaws.com/locale/',
    clientId: '943f9b0f-73c8-4435-8801-0260db687f05',
    clientSecret: '96ca64b0ae5f7005fd18387a28019615',
};
