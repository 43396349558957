import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { environment } from '../environments/environment';

import { PublicModule } from './public/public.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { appReducers, metaReducers } from './store/reducers/app.reducers';
import { HttpClient, HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthorizationInterceptor } from './store/services/http/authorization.interceptor';
import { AuthService } from './store/services/auth/auth.service';
import {
    UserEffects,
    DevicesEffects,
    ZonesEffects,
    EventsEffects,
    MapEffects,
    ContactsEffects,
    DescendantsEffects,
    ClientsEffects,
    LocationsEffects,
} from './store/effects';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TntSupportPagesService } from './store/services/guards/tnt-support-pages.service';
import { TPlusSupportPagesService } from './store/services/guards/trackimo-plus-support-pages.service';
import { skin } from '../white-labels';
import { AccountPagesJobsService } from './store/services/guards/public-account-pages.service';
import { InfoPagesJobsService } from './store/services/guards/public-info-pages.service';
import { TutorialsService } from './store/services';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { SubAccountGuard } from './store/services/guards/sub-account-guard.service';
import { BidiModule } from '@angular/cdk/bidi';
import { FB_AUTH_CLIENT, G_AUTH_CLIENT } from './store/constants';
import { FacebookLoginProvider, GoogleLoginProvider, SocialLoginModule } from 'angularx-social-login';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { GraphQLModule } from './graphql.module';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, skin.localizeLink, '.json');
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        PublicModule,
        BidiModule,
        SocialLoginModule,
        // GoogleSigninButtonModule,

        StoreModule.forRoot(appReducers, { metaReducers }),
        EffectsModule.forRoot([
            UserEffects,
            DevicesEffects,
            ZonesEffects,
            EventsEffects,
            MapEffects,
            ContactsEffects,
            DescendantsEffects,
            ClientsEffects,
            LocationsEffects,
        ]),
        TourMatMenuModule,
        StoreDevtoolsModule.instrument({
            maxAge: 25, // Retains last 25 states
            logOnly: environment.production, // Restrict extension to log-only mode
            connectInZone: true,
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        AngularFireModule.initializeApp({
            apiKey: 'AIzaSyBtCQKN4sxzQBYF7Ita81u5Ttgc4UgFMdE',
            authDomain: 'trackimo-e40b3.firebaseapp.com',
            databaseURL: 'https://trackimo-e40b3.firebaseio.com',
            projectId: 'trackimo-e40b3',
            storageBucket: 'trackimo-e40b3.appspot.com',
            messagingSenderId: '960826123475',
            appId: '1:960826123475:web:d18fffa5e73dba37e1e026',
            measurementId: 'G-Z8VSKVH8Y5',
        }),
        AngularFireAnalyticsModule,
        HttpClientModule,
        GraphQLModule,
    ],
    exports: [],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthorizationInterceptor, multi: true },
        AuthService,
        TntSupportPagesService,
        TPlusSupportPagesService,
        SubAccountGuard,
        AccountPagesJobsService,
        InfoPagesJobsService,
        TutorialsService,
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider(G_AUTH_CLIENT),
                    },
                    {
                        id: FacebookLoginProvider.PROVIDER_ID,
                        provider: new FacebookLoginProvider(FB_AUTH_CLIENT),
                    },
                ],
            },
        },
        provideAnimationsAsync(),
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
