import { createReducer, on } from '@ngrx/store';
import { initialUnreadEventsState } from '../state/unread-events.state';
import { EventsActions } from '../actions/events.actions';
import { unreadEventsActions } from '../actions/unread-events.actions';
import { DevicesActions } from '../actions/devices.actions';

export const unreadEventsReducer = createReducer(
    initialUnreadEventsState,
    on(unreadEventsActions.setUnreadEvents, (state, res) => ({
        ...state,
        unreadEvents: res.unreadEvents,
    })),
    on(EventsActions.deleteAllEventsSuccess, (state) => ({
        ...state,
        unreadEvents: 0,
    })),
    on(EventsActions.updateEventSuccess, (state, res) => ({
        ...state,
        unreadEvents: state['unreadEvents'] - res.eventsCount,
    })),
    on(DevicesActions.updateDeviceEventSuccess, (state, res) => ({
        ...state,
        unreadEvents: state['unreadEvents'] - res.events.length,
    })),
);
