import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { WindowService } from 'src/app/store/services';
import { skin } from 'src/white-labels';

@Component({
    selector: 'app-pet-found',
    templateUrl: './pet-found.component.html',
    styleUrls: ['./pet-found.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PetFoundComponent implements OnInit {
    skin = skin;
    imgUrl: string;
    constructor(private window: WindowService) {}

    ngOnInit(): void {
        if (this.skin.whiteLabel === 'PETLOC8') {
            this.imgUrl = 'assets/images/pet-pic.svg';
        } else {
            this.imgUrl = 'assets/images/pet-pic-2.svg';
        }
    }

    joinTrackipet(): void {
        if (/android/i.test(navigator.userAgent)) {
            this.window.getWindow.location.href = skin.androidAppLink;
        } else if (/iPad|iPhone|iPod|iPad/.test(navigator.userAgent)) {
            this.window.getWindow.location.href = skin.iosAppLink;
        } else {
            this.window.getWindow.location.href = skin.storeLink;
        }
    }
}
