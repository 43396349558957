import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EMAIL_VALIDATOR_REG_EXP } from 'src/app/store/constants';

@Component({
    selector: 'app-descendant-device-move-dialog',
    templateUrl: './descendant-device-move-dialog.component.html',
    styleUrls: ['./descendant-device-move-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DescendantDeviceMoveDialogComponent {
    emailControl = new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(EMAIL_VALIDATOR_REG_EXP),
    ]);

    constructor(
        public dialogRef: MatDialogRef<DescendantDeviceMoveDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
    ) {}

    save() {
        this.dialogRef.close({
            selectedDescendantEmail: this.emailControl.value,
        });
    }

    closeDialog(): void {
        this.dialogRef.close();
    }
}
