import { createSelector } from '@ngrx/store';
import { COMMON_METRIC_SYSTEM, TrackingModeSettings } from '../constants/common.constants';
import { IAppState } from '../state/app.state';
import { IDeviceState } from '../state/devices.stete';
import { IPetInfo } from '../interfaces';
import { IUserState } from '../state/user.stete';
import { converter } from '../services/helper';

export const selectFeature = (appState: IAppState) => appState.devices || [];
export const selectFeatureUser = (appState: IAppState) => appState.user;

export const selectDevicesList = createSelector(selectFeature, (appState: IDeviceState) =>
    appState.devices?.map((device) => ({
        ...device,
        isChecked: false,
    })),
);

export const selectDevicesIDsList = createSelector(selectFeature, (appState: IDeviceState) =>
    appState.devices?.map((device) => device.device_id),
);

export const selectDevicesWithLocation = createSelector(selectFeature, (appState: IDeviceState) =>
    appState.devices?.filter((device: any) => device.lat && device.lng),
);

export const selectSectedDevice = createSelector(selectFeature, (appState: IDeviceState) => {
    if (appState.selectedDevice && appState.selectedDevice.info) {
        return appState.selectedDevice;
    }
    return null;
});

export const selectFpiUnfiledSettingsCount = createSelector(selectFeature, (appState: IDeviceState) => {
    let count = 0;
    if (appState.selectedDevice && appState.selectedDevice.features?.overwatch) {
        let settings = [];
        if (appState.selectedDevice.info?.usage === 'DRIVER_CAR') {
            settings = [
                'company',
                'primary_contact_name',
                'primary_contact_number',
                'primary_contact_country',
                'secondary_contact_name',
                'secondary_contact_number',
                'secondary_contact_country',
                'license_number',
                'state',
                'manufacturer',
                'model_name',
                'color',
            ];
        }

        if (
            appState.selectedDevice.info?.usage === 'TRAVELER' ||
            appState.selectedDevice.info?.usage === 'OTHER'
        ) {
            settings = [
                'primary_contact_name',
                'primary_contact_number',
                'primary_contact_country',
                'secondary_contact_name',
                'secondary_contact_number',
                'secondary_contact_country',
            ];
        }

        if (appState.selectedDevice.info.deviceFPISettings) {
            settings.forEach((inSetting) => {
                if (!appState.selectedDevice.info.deviceFPISettings[inSetting]) {
                    count++;
                }
            });
        } else {
            return settings.length;
        }

        return count;
    }
    return count;
});

export const selectDeviceHistory = createSelector(selectFeature, (appState: IDeviceState) => {
    if (appState.selectedDevice?.history) {
        const selectedDeviceHistory = appState.selectedDevice?.history.filter(
            (history) => history.lat && history.lng,
        );
        return selectedDeviceHistory;
    } else {
        return [];
    }
});

export const selectDeviceFences = createSelector(
    selectFeature,
    (appState: IDeviceState) => appState.selectedDevice?.assigned_fences,
);

export const selectDevicesTotalCount = createSelector(
    selectFeature,
    (appState: IDeviceState) => appState.devicesTotalCount,
);

export const selectDeviceRenewalPlans = createSelector(
    selectFeature,
    (appState: IDeviceState) => appState.selectedDevice?.renewalsPlans,
);

export const selectDeviceTrackingMode = createSelector(selectFeature, (appState: IDeviceState) => {
    const _trackingMode = appState.selectedDevice?.settings.preferences.tracking_mode;
    const value = Math.floor(_trackingMode.sample_rate / 60);
    let trackingMode = {
        type: '',
        value: value % 60 ? value : _trackingMode.sample_rate,
        measurment: value % 60 ? 'HOURS' : _trackingMode.tracking_measurment,
    };

    switch (_trackingMode.sample_rate) {
        case TrackingModeSettings.POWER_SAVING:
            trackingMode.type = 'POWER_SAVING';
            break;
        case TrackingModeSettings.EVERYDAY:
            trackingMode.type = 'EVERYDAY';
            break;
        case TrackingModeSettings.OFF:
            trackingMode.type = 'OFF';
            break;
        // case TrackingModeSettings.SECONDS:
        //     trackingMode.type = 'REALTIME';
        //     break;
        default:
            trackingMode.type = 'CUSTOM';
            break;
    }

    return trackingMode;
});

export const selectDevicesSubscription = createSelector(
    selectFeature,
    (appState: IDeviceState) => appState.devicesSubscriptions,
);

export const selectDeviceLocatioPing = createSelector(
    selectFeature,
    (appState: IDeviceState) => appState.selectedDevice?.location_ping,
);

export const selectDeviceInfo = createSelector(
    selectFeature,
    (appState: IDeviceState) => appState.selectedDevice?.info,
);

export const selectDeviceActivePlans = createSelector(
    selectFeature,
    (appState: IDeviceState) => appState.selectedDevice?.active_plans,
);

export const selectIsDeviceSOS = createSelector(selectFeature, (appState: IDeviceState) =>
    Boolean(appState.selectedDevice?.allSOSEvents?.length),
);

export const selectDeviceScheduledSleep = createSelector(
    selectFeature,
    (appState: IDeviceState) => appState.selectedDevice?.settings.preferences.scheduled_sleep,
);

export const selectFPIDeviceSettings = createSelector(
    selectFeature,
    (appState: IDeviceState) => appState.selectedDevice?.info?.deviceFPISettings,
);

export const selectDevicePetSettings = createSelector(
    selectFeature,
    (appState: IDeviceState) => appState.selectedDevice?.info?.petDetailsSettings,
);

export const selectPetActivityInfo = createSelector(
    selectFeature,
    selectFeatureUser,
    (appState: IDeviceState, userState: IUserState) => {
        return setPetActivity(appState.selectedDevice?.petInfo, userState);
    },
);

function setPetActivity(data: IPetInfo, userState) {
    if (data) {
        let userMetricSystem =
            userState.user.settings.preferences.speed_unit === 'kph'
                ? COMMON_METRIC_SYSTEM.METRIC
                : COMMON_METRIC_SYSTEM.IMPERIAL;
        if (data.distance) {
            if (userMetricSystem === COMMON_METRIC_SYSTEM.METRIC) {
                if (data.distance < 999) {
                    data = { ...data, transformedDistance: data.distance.toFixed(0) + ' m' };
                } else {
                    data = { ...data, transformedDistance: (data.distance / 1000).toFixed(1) + ' km' };
                }
            } else {
                let transformedDistance = converter.metricToImperial.metersToFeet(data.distance);
                if (transformedDistance >= 5280) {
                    let milesValue = converter.metricToImperial.feetToMiles(transformedDistance);
                    data = { ...data, transformedDistance: milesValue.toFixed(1) + ' mi' };
                } else {
                    data = { ...data, transformedDistance: transformedDistance.toFixed(0) + ' ft' };
                }
            }
        }

        if (data.temperatureMax) {
            if (userMetricSystem === COMMON_METRIC_SYSTEM.METRIC) {
                data = {
                    ...data,
                    transformedTemperatureMax: data.temperatureMax.toFixed(0) + ' °C',
                };
            } else {
                let transformedTemp = converter.metricToImperial.celciusToFahrenheit(data.temperatureMax);
                data = { ...data, transformedTemperatureMax: transformedTemp.toFixed(0) + ' °F' };
            }
        } else {
            data = { ...data, transformedTemperatureMax: 'N/A' };
        }

        if (data.temperatureMin) {
            if (userMetricSystem === COMMON_METRIC_SYSTEM.METRIC) {
                data = {
                    ...data,
                    transformedTemperatureMin: data.temperatureMin.toFixed(0) + ' °C',
                };
            } else {
                let transformedTemp = converter.metricToImperial.celciusToFahrenheit(data.temperatureMin);
                data = { ...data, transformedTemperatureMin: transformedTemp.toFixed(0) + ' °F' };
            }
        } else {
            data = { ...data, transformedTemperatureMin: 'N/A' };
        }

        if (data.temperature) {
            if (userMetricSystem === COMMON_METRIC_SYSTEM.METRIC) {
                data = {
                    ...data,
                    transformedAvrTemp: data.temperature.toFixed(0) + ' °C',
                };
            } else {
                let transformedTemp = converter.metricToImperial.celciusToFahrenheit(data.temperature);
                data = { ...data, transformedAvrTemp: transformedTemp.toFixed(0) + ' °F' };
            }
        } else {
            data = { ...data, transformedAvrTemp: 'N/A' };
        }

        return { ...data };
    } else {
        return undefined;
    }
}

export const selectSensorInfo = createSelector(selectFeature, (appState: IDeviceState) => {
    const sensorsDetails = appState.selectedDevice?.sensors_details;
    let externalBatteryVoltage, externalBatteryTemperature, trackerPowerSource, batteryCapacity;

    if (sensorsDetails && sensorsDetails.length) {
        const batterySensorValue = sensorsDetails[0].sensor_value;
        externalBatteryVoltage = `${(+batterySensorValue.split(' ')[0] / 1000).toFixed(2)} Volt`;
        const batteryInfo = batterySensorValue.split(' ')[2].replace(/\D/g, ' ').split(' ');
        externalBatteryTemperature = `${batteryInfo[1]}.${batteryInfo[2]} Celsius`;
        trackerPowerSource = +batterySensorValue.split(' ')[2][0] ? 'Internal' : 'External';
        batteryCapacity = `${
            sensorsDetails.find((details) => details.sensor_type === 'battery_capacity')?.sensor_value
        }%`;
    } else {
        externalBatteryVoltage = externalBatteryTemperature = trackerPowerSource = batteryCapacity = 'N/A';
    }

    return {
        externalBatteryVoltage,
        externalBatteryTemperature,
        trackerPowerSource,
        batteryCapacity,
    };
});

export const selectDevicesForFOTAcount = createSelector(
    selectFeature,
    (appState: IDeviceState) => appState.devicesForFOTA?.filter((inDevice) => inDevice.fota_required).length,
);

export const selectDevicesForFOTA = createSelector(selectFeature, (appState: IDeviceState) => {
    return appState.devicesForFOTA?.filter((inDevice) => inDevice.fota_required);
});
