import { skin } from 'src/white-labels';

let whiteLabel = 'TRACKIMO';
if (skin.whiteLabel === 'TAGANDTRACK') {
    whiteLabel = 'TAGANDTRACK';
} else if (skin.whiteLabel === 'TRACKIPET') {
    whiteLabel = 'TRACKIPET';
}

export const DeviceMarker = {
    Mini: `assets/images/devices/${whiteLabel}/6.svg`,
    Guardian: `assets/images/devices$/{whiteLabel}/5.svg`,
    Travel: `assets/images/devices/${whiteLabel}/7.svg`,
    TrackiPro: `assets/images/devices/${whiteLabel}/3.svg`,
    Watch: `assets/images/devices/${whiteLabel}/2.svg`,
    Universal: `assets/images/devices/${whiteLabel}/4.svg`,
    Male: `assets/images/devices/${whiteLabel}/10.svg`,
    TrackimoPlus: `assets/images/devices/${whiteLabel}/9.svg`,
    Female: `assets/images/devices/${whiteLabel}/11.svg`,
    Etoll: `assets/images/devices/${whiteLabel}/12.svg`,
    Pet: `assets/images/devices/${whiteLabel}/14.svg`,
};
