import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreateAccountComponent } from './public/create-account/create-account.component';
import { ForgotPasswordComponent } from './public/forgot-password/forgot-password.component';
import { LoginComponent } from './public/login/login.component';
import { PublicComponent } from './public/public.component';
import { ResetPasswordComponent } from './public/reset-password/reset-password.component';
import { FaqComponent } from './public/faq/faq.component';
import { PrivacyPolicyComponent } from './public/privacy-policy/privacy-policy.component';
import { AuthService } from './store/services/auth/auth.service';
import { TermsAndConditionsComponent } from './public/terms-and-conditions/terms-and-conditions.component';
import { ContactUsComponent } from './public/contact-us/contact-us.component';
import { AboutUsComponent } from './public/about-us/about-us.component';
import { AccountActivationComponent } from './public/account-activation/account-activation.component';
import { PublicMapComponent } from './public/public-map/public-map.component';
import { AccountVerificationComponent } from './public/account-verification/account-verification.component';
import { SignupConfirmComponent } from './public/signup-confirm/signup-confirm.component';
import { LoginAuthCodeComponent } from './public/login-auth-code/login-auth-code.component';
import { ForgotPasswordVerificationComponent } from './public/forgot-password-verification/forgot-password-verification.component';
import { ForgotPasswordSuccessComponent } from './public/forgot-password-success/forgot-password-success.component';
import { CommonSupportComponent } from './public/common-support/common-support.component';
import { UserGuideComponent } from './public/user-guide/user-guide.component';
import { TntSupportPagesService } from './store/services/guards/tnt-support-pages.service';
import { TPlusSupportPagesService } from './store/services/guards/trackimo-plus-support-pages.service';
import { AccountLockedComponent } from './public/account-locked/account-locked.component';
import { AccountPagesJobsService } from './store/services/guards/public-account-pages.service';
import { InfoPagesJobsService } from './store/services/guards/public-info-pages.service';
import { DownloadAppComponent } from './public/download-app/download-app.component';
import { WelcomeBackComponent } from './public/welcome-back/welcome-back.component';
import { CreateAccoutFromLinkComponent } from './public/create-accout-from-link/create-accout-from-link.component';
import { LostPetComponent } from './public/lost-pet/lost-pet.component';
import { PetFoundComponent } from './public/pet-found/pet-found.component';

const routes: Routes = [
    {
        path: '',
        component: PublicComponent,
        children: [
            {
                path: '',
                component: LoginComponent,
                canActivate: [AccountPagesJobsService],
            },
            {
                path: 'login-verification',
                component: LoginAuthCodeComponent,
                canActivate: [AccountPagesJobsService],
            },
            {
                path: 'forgot-password',
                component: ForgotPasswordComponent,
                canActivate: [AccountPagesJobsService],
            },
            {
                path: 'forgot-password-verification',
                component: ForgotPasswordVerificationComponent,
                canActivate: [AccountPagesJobsService],
            },
            {
                path: 'forgot-password-success',
                component: ForgotPasswordSuccessComponent,
                canActivate: [AccountPagesJobsService],
            },
            {
                path: 'reset-password',
                component: ResetPasswordComponent,
                canActivate: [AccountPagesJobsService],
            },
            {
                path: 'account-locked',
                component: AccountLockedComponent,
                canActivate: [AccountPagesJobsService],
            },
            {
                path: 'tnt_download',
                component: DownloadAppComponent,
            },
            {
                path: 'download',
                component: DownloadAppComponent,
            },
            {
                path: 'welcome-back',
                component: WelcomeBackComponent,
            },
            {
                path: 'register-account',
                component: CreateAccoutFromLinkComponent,
            },
            {
                path: 'create-account',
                canActivate: [AccountPagesJobsService],
                component: CreateAccountComponent,
            },
            {
                path: 'activation-account',
                canActivate: [AccountPagesJobsService],
                component: AccountActivationComponent,
            },
        ],
    },
    {
        path: 'activation-account',
        canActivate: [AccountPagesJobsService],
        component: AccountActivationComponent,
    },
    {
        path: 'verify-account',
        canActivate: [AccountPagesJobsService],
        component: AccountVerificationComponent,
    },
    {
        path: 'signup-confirm',
        canActivate: [AccountPagesJobsService],
        component: SignupConfirmComponent,
    },
    {
        path: 'faq',
        component: FaqComponent,
        canActivate: [TPlusSupportPagesService, InfoPagesJobsService],
        data: { isShowHeader: true },
    },
    {
        path: 'contact-us',
        canActivate: [TPlusSupportPagesService, InfoPagesJobsService],
        component: ContactUsComponent,
        data: { isShowHeader: true },
    },
    {
        path: 'public-map',
        component: PublicMapComponent,
    },
    {
        path: '',
        component: AboutUsComponent,
        canActivate: [TPlusSupportPagesService, InfoPagesJobsService],
        children: [
            {
                path: 'privacy-policy',
                component: PrivacyPolicyComponent,
            },
            {
                path: 'terms-and-conditions',
                component: TermsAndConditionsComponent,
            },
        ],
    },
    {
        path: 'about',
        component: CommonSupportComponent,
        data: { isShowHeader: true },
        canActivate: [TntSupportPagesService, InfoPagesJobsService],
        children: [
            { path: '', redirectTo: 'faqs', pathMatch: 'full' },
            {
                path: 'faqs/:lang',
                component: FaqComponent,
                data: { isShowHeader: false },
            },
            {
                path: 'faqs',
                component: FaqComponent,
                data: { isShowHeader: false },
            },
            {
                path: 'contact-support',
                component: ContactUsComponent,
                data: { isShowHeader: false },
            },
            {
                path: 'contact-support/:lang',
                component: ContactUsComponent,
                data: { isShowHeader: false },
            },
            {
                path: 'user-guide',
                component: UserGuideComponent,
            },
            {
                path: 'user-guide/:lang',
                component: UserGuideComponent,
            },
            {
                path: 'terms-and-conditions',
                component: TermsAndConditionsComponent,
                data: { isShowFooter: true },
            },
            {
                path: 'terms-and-conditions/:lang',
                component: TermsAndConditionsComponent,
                data: { isShowFooter: true },
            },
            {
                path: 'privacy-policy',
                component: PrivacyPolicyComponent,
                data: { isShowFooter: true },
            },
            {
                path: 'privacy-policy/:lang',
                component: PrivacyPolicyComponent,
                data: { isShowFooter: true },
            },
        ],
    },
    {
        path: 'share-lost-pet',
        component: LostPetComponent,
    },
    {
        path: 'pet-found',
        component: PetFoundComponent,
    },
    {
        path: '',
        canActivate: [AuthService],
        loadChildren: () => import('./dashboard/dashboard.module').then((mod) => mod.DashboardModule),
    },

    { path: '**', redirectTo: '' }, // Wildcard route for a 404 page
];
@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            paramsInheritanceStrategy: 'always',
            scrollPositionRestoration: 'enabled',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
