import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IDescendantDeviceMove, IDeviceLocationEvent, IDevicesCount, IDeviceShort } from '../interfaces';

const prefix = '[Descendants API]';

const getDescendants = createAction(`${prefix} Get Descendants`);
const getDescendantsSuccess = createAction(`${prefix} Get Descendants Success`, props<{ response: any }>());
const getDescendantsError = createAction(
    `${prefix} Get Descendants Error`,
    props<{ error: HttpErrorResponse }>(),
);

const getDescendantsDevices = createAction(`${prefix} Get Descendants Devices`);
const getDescendantsDevicesSuccess = createAction(
    `${prefix} Get Descendants Devices Success`,
    props<{ response: IDeviceShort[] }>(),
);
const getDescendantsDevicesError = createAction(
    `${prefix} Get Descendants Devices Error`,
    props<{ error: HttpErrorResponse }>(),
);

const removeDescendant = createAction(`${prefix} Remove Descendant`, props<{ email: string }>());
const removeDescendantSuccess = createAction(`${prefix} Remove Descendant Success`);
const removeDescendantError = createAction(
    `${prefix} Remove Descendant Error`,
    props<{ error: HttpErrorResponse }>(),
);

const moveDescendantDevice = createAction(
    `${prefix} Move Descendant Device`,
    props<{ req: IDescendantDeviceMove }>(),
);
const moveDescendantDeviceSuccess = createAction(
    `${prefix} Move Descendant Device Success`,
    props<{ msg: string }>(),
);
const moveDescendantDeviceError = createAction(
    `${prefix} Move Descendant Device Error`,
    props<{ error: HttpErrorResponse }>(),
);

const clearDescendants = createAction(`${prefix} Clear Descendants`);

const getTotalSubaccountDevicesCount = createAction(`${prefix} Get Subaccounts Related Devices Count`);
const getTotalSubaccountDevicesCountSuccess = createAction(
    `${prefix} Get Subaccounts Related Devices Count Success`,
    props<{ response: IDevicesCount }>(),
);
const getTotalSubaccountDevicesCountError = createAction(
    `${prefix} Get Subaccounts Related Devices Count Error`,
    props<{ error: HttpErrorResponse }>(),
);

const pingSubAccountsDevicesLocations = createAction(`${prefix} Ping SubAccounts Device Locations`);
const pingSubAccountsDevicesLocationsSuccess = createAction(
    `${prefix} Ping SubAccounts Device Locations Success`,
    props<{ response: IDeviceLocationEvent[] }>(),
);
const pingSubAccountsDevicesLocationsError = createAction(
    `${prefix} Ping SubAccounts Device Locations Error`,
    props<{ error: HttpErrorResponse }>(),
);

const changePingDeviceLocationInterval = createAction(
    `${prefix} Change Ping Device Location Interval`,
    props<{ pingInterval: number }>(),
);

export const DescendantsActions = {
    getDescendants,
    getDescendantsSuccess,
    getDescendantsError,

    getDescendantsDevices,
    getDescendantsDevicesSuccess,
    getDescendantsDevicesError,

    removeDescendant,
    removeDescendantSuccess,
    removeDescendantError,

    moveDescendantDevice,
    moveDescendantDeviceSuccess,
    moveDescendantDeviceError,

    clearDescendants,

    getTotalSubaccountDevicesCount,
    getTotalSubaccountDevicesCountSuccess,
    getTotalSubaccountDevicesCountError,

    pingSubAccountsDevicesLocations,
    pingSubAccountsDevicesLocationsSuccess,
    pingSubAccountsDevicesLocationsError,

    changePingDeviceLocationInterval,
};
