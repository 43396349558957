<div class="dialog-wrapper">
    <div mat-dialog-title>
        <h1 class="title">
            {{'LOST_PET_CENTER' | translate}}
            <i class="icon close-icon" [inlineSVG]="'#close'" (click)="closeDialog()"></i>
        </h1>

    </div>
    <div mat-dialog-content>
        <h5>Select Communties to notify</h5>

        <mat-checkbox class="communtie-checkbox" color="primary" [labelPosition]="'before'">
            <div class="communtie-info">
                <img src="https://placehold.co/40x40" alt="">

                <div>
                    <h5 class="community-title">Community Ring</h5>
                    <p>{{ 'MEMBERS' | translate }}: 2 768</p>
                </div>
            </div>
        </mat-checkbox>

        <mat-checkbox class="communtie-checkbox" color="primary" [labelPosition]="'before'">
            <div class="communtie-info">
                <img src="https://placehold.co/40x40" alt="">

                <div>
                    <h5 class="community-title">My Ring</h5>
                    <p>{{ 'MEMBERS' | translate }}: 12</p>
                </div>
            </div>
        </mat-checkbox>

        <mat-checkbox class="communtie-checkbox" color="primary" [labelPosition]="'before'">
            <div class="communtie-info">
                <img src="https://placehold.co/40x40" alt="">

                <div>
                    <h5 class="community-title">Community Ring</h5>
                    <p>{{ 'MEMBERS' | translate }}: 2 768</p>
                </div>
            </div>
        </mat-checkbox>

    </div>
    <div mat-dialog-actions>
        <div class="dialog-actions">
            <button class="btn basic" (click)="closeDialog()" mat-flat-button>
                {{ 'CANCEL' | translate }}
            </button>
            <button class="btn primary" (click)="next()" mat-flat-button>
                {{ 'NEXT' | translate }}
            </button>
        </div>
    </div>
</div>