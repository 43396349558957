<div class="dialog-wrapper" style="height: 100%">
    <div class="iframe-container">
        <iframe [src]="urlMap" width="100%" height="100%" frameborder="0" style="border: 0"></iframe>
    </div>

    <div class="close-container">
        <button class="btn primary close" (click)="close()" mat-flat-button>
            {{ 'CLOSE' | translate }}
        </button>
    </div>
</div>
