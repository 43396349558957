<div class="dialog-container">
    <div class="wrapp">
        <h1>{{ 'LOST_PET' | translate }}</h1>
        <p>{{ (isTimerFinished ? 'LOST_PET_COUNTER_INFO' : 'LOST_PET_COUNTER_INFO_DONE') | translate }}
        </p>

        <div class="counter" *ngIf="!isTimerFinished">
            <div class="pulsating-circle"></div>
            <div class="number">{{ countDown$ | async }}</div>
        </div>

        <img class="lost-pet-image" src="assets/images/lost-pet-circle.svg" *ngIf="isTimerFinished">

        <p class="cancel" *ngIf="!isTimerFinished" (click)="closeDialog()">{{ 'CANCEL' | translate }}</p>

        <div class="dialog-content-actions" *ngIf="isTimerFinished" mat-dialog-actions>
            <div class="nav-container">
                <p class="cancel" (click)="closeDialog()">{{ 'CANCEL' | translate }}</p>

                <button class="btn primary" (click)="goToInfo()" mat-flat-button>
                    {{ 'GO_TO_INFO' | translate }}
                </button>
            </div>
        </div>

    </div>
</div>