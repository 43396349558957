<div class="dialog-wrapper">
    <div mat-dialog-title>
        <h1 class="title">
            {{ 'DESCENDANT_MOVE_DEVICE_MODAL_TITLE' | translate }}
            <i class="icon close-icon" [inlineSVG]="'#close'" (click)="closeDialog()"></i>
        </h1>
    </div>

    <div class="content" mat-dialog-content>
        <app-device-preview [device]="data.selectedDevice" [isHideChevron]="true">
        </app-device-preview>

        <mat-form-field class="form-field" appearance="outline">
            <mat-label>{{ 'EMAIL' | translate }}</mat-label>
            <input [formControl]="emailControl" matInput placeholder="{{ 'EMAIL' | translate }}" />
        </mat-form-field>
    </div>
    <div mat-dialog-actions>
        <div class="dialog-actions">
            <button class="btn basic" (click)="closeDialog()" mat-flat-button>
                {{ 'CANCEL' | translate }}
            </button>
            <button class="btn primary" (click)="save()" [disabled]="emailControl.invalid" mat-flat-button>
                {{ 'SAVE' | translate }}
            </button>
        </div>
    </div>
</div>