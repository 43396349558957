import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { LocalStorageService } from '../local-storage.service';
import { SocialAuthService } from 'angularx-social-login';

@Injectable({
    providedIn: 'root',
})
export class AccountPagesJobsService implements CanActivate {
    constructor(private router: Router, public localStorage: LocalStorageService) {}

    canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
        if (next.queryParams?.info) {
            if (this.localStorage.getItem('token')) {
                this.localStorage.remove('token');
            }
        }

        if (this.localStorage.getItem('token')) {
            this.router.navigate(['/map']);
            return of(false);
        } else {
            return of(true);
        }
    }
}
