import { CommonModule } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { StatusLabelComponent } from './status-label/status-label.component';
import { LanguageSelectComponent } from './language-select/language-select.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatTabsModule } from '@angular/material/tabs';
import { CookiesService } from '../store/services/cookie/cookie.service';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { CookieBarComponent } from './cookie-bar/cookie-bar.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { MatDialogModule } from '@angular/material/dialog';
import { TermsAndConditionsDialog } from '../dialogs/terms-and-conditions-dialog/terms-and-conditions-dialog';
import { PrivacyAndPolicyDialog } from '../dialogs/privacy-and-policy-dialog/privacy-and-policy-dialog';
import { PrivacyPolicyComponent } from '../public/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from '../public/terms-and-conditions/terms-and-conditions.component';
import { PasswordHintsComponent } from './password-hints/password-hints.component';
import { PasswordValidatorService } from '../store/services/password-validator/password-validator.service';
import { BackBtnComponent } from './back-btn/back-btn.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { EditPhotoComponent } from './edit-photo/edit-photo.component';
import { EditPhotoDialogComponent } from '../dialogs/edit-photo-dialog/edit-photo-dialog.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { WINDOW_PROVIDERS } from '../store/providers/window-provider';
import { GoogleMapsModule } from '@angular/google-maps';
import { ConfirmDialogComponent } from '../dialogs/confirm-dialog/confirm-dialog.component';
import { UpdatePlanDialogComponent } from '../dialogs/update-plan-dialog/update-plan-dialog.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AddContactDialogComponent } from '../dialogs/add-contact-dialog/add-contact-dialog.component';
import { PhoneInputComponent } from './phone-input/phone-input.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { TooltipModule } from 'ng2-tooltip-directive';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FormatCountdownTimePipe } from './pipes/format-countdown.pipe';
import { SearchFilterPipe } from './pipes/search-filter.pipe';
import { TextAlertLimitDialogComponent } from '../dialogs/text-alert-limit-dialog/text-alert-limit-dialog.component';
import { PaymentCongratsMsgComponent } from './payment-congrats-msg/payment-congrats-msg.component';
import { DevicePreviewComponent } from '../dashboard/devices/device-preview/device-preview.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SnackbarComponent } from './snackbar/snackbar.component';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { AddSubAccountDialogComponent } from '../dialogs/add-sub-account-dialog/add-sub-account-dialog.component';
import { StatusDialogComponent } from '../dialogs/status-dialog/status-dialog.component';
import { NoGPSSignalDialogComponent } from '../dialogs/no-gps-dialog/no-gps-dialog.component';
import { NoGpsDialogInfoComponent } from '../dialogs/no-gps-dialog-info/no-gps-dialog-info.component';
import { ScheduleSleepTurnOffComponent } from '../dialogs/schedule-sleep-turn-off/schedule-sleep-turn-off.component';
import { OwlMomentDateTimeModule } from '@danielmoncada/angular-datetime-picker-moment-adapter';
import { OwlDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { OrderByPipe } from './pipes/order-by-pipe.pipe';
import { DatFormatPipe } from './pipes/date-format.pipe';
import { RtlSupportDirective } from './directives/rtl.directive';
import { AutocompletePositionDirective } from './directives/scrollMatSelect.directive';
import { FeatureFlagsDialogComponent } from '../dialogs/feature-flags-dialog/feature-flags-dialog.components';
import { GoogleplaceDirective } from './directives/googleplace.directive';
import { DeviceBatteryComponent } from './device-battery/device-battery.component';
import { OverwatchDialogComponent } from '../dialogs/overwatch-dialog/overwatch-dialog.component';
import { OverwatchEmergancyInfoDialogComponent } from '../dialogs/overwatch-emergancy-info-dialog/overwatch-emergancy-info-dialog.component';
import { QrModalComponent } from '../dialogs/qr-modal/qr-modal.component';
import { DescendantDeviceMoveDialogComponent } from '../dialogs/descendant-device-move-dialog/descendant-device-move-dialog.component';
import { ActivationCodeDialogComponent } from '../dialogs/activation-code-dialog/activation-code-dialog.component';
import { MapSosComponent } from './map-sos/map-sos.component';
import { JoinRingDialogComponent } from '../dialogs/join-ring-dialog/join-ring-dialog.component';
import { DeviceBatteryV2Component } from './device-battery-v2/device-battery-v2.component';
import { NoDevicesComponent } from '../dashboard/devices/no-devices/no-devices.component';
import { LimitLiveTrackingDialogComponent } from '../dialogs/limit-live-tracking-dialog/limit-live-tracking-dialog.component';
import { StopLiveTrackingDialogComponent } from '../dialogs/stop-live-tracking-dialog/stop-live-tracking-dialog.component';
import { LostPetsCentreModalComponent } from '../dialogs/lost-pets-centre-modal/lost-pets-centre-modal.component';
import { LostPetsCounterComponent } from '../dialogs/lost-pets-counter/lost-pets-counter.component';
import { BannersComponent } from './banners/banners.component';
import { CarouselComponent } from './carousel/carousel.component';
import { FirmwareUpdateModalComponent } from '../dialogs/firmware-update-modal/firmware-update-modal.component';
import { LostPetCenterWihtDevicesModalComponent } from '../dialogs/lost-pet-center-wiht-devices-modal/lost-pet-center-wiht-devices-modal.component';
import { LostPetCenterCommuntiesComponent } from '../dialogs/lost-pet-center-communties/lost-pet-center-communties.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { LostPetsHomeModalComponent } from '../dialogs/lost-pets-home-modal/lost-pets-home-modal.component';
import { CommuntieInfoComponent } from '../dialogs/lost-pet-center-communties copy/communtie-info.component';
import { Clubloc8MembershipBenefitsDialogComponent } from '../dialogs/clubloc8-membership-benefits-dialog/clubloc8-membership-benefits-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        InlineSVGModule.forRoot({ baseUrl: '/assets/images/sprite.svg' }),
        MatSelectModule,
        FormsModule,
        TranslateModule,
        MatExpansionModule,
        RouterModule,
        MatMenuModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatRadioModule,
        GoogleMapsModule,
        MatAutocompleteModule,
        MatToolbarModule,
        MatIconModule,
        MatProgressSpinnerModule,
        NgxMatSelectSearchModule,
        TooltipModule,
        MatProgressBarModule,
        ScrollingModule,
        TourMatMenuModule,
        MatSlideToggleModule,
        OwlDateTimeModule,
        OwlMomentDateTimeModule,
        NgxScannerQrcodeModule,
        MatDatepickerModule,
        MatSidenavModule,
    ],
    declarations: [
        StatusLabelComponent,
        LanguageSelectComponent,
        BreadcrumbsComponent,
        CookieBarComponent,
        FooterComponent,
        HeaderComponent,
        TermsAndConditionsDialog,
        PrivacyAndPolicyDialog,
        EditPhotoDialogComponent,
        ConfirmDialogComponent,
        UpdatePlanDialogComponent,
        AddContactDialogComponent,
        TermsAndConditionsComponent,
        PrivacyPolicyComponent,
        PasswordHintsComponent,
        EditPhotoComponent,
        BackBtnComponent,
        PhoneInputComponent,
        FormatCountdownTimePipe,
        SearchFilterPipe,
        TextAlertLimitDialogComponent,
        QrModalComponent,
        JoinRingDialogComponent,
        PaymentCongratsMsgComponent,
        DevicePreviewComponent,
        SnackbarComponent,
        AddSubAccountDialogComponent,
        LostPetsCentreModalComponent,
        LostPetsCounterComponent,
        LostPetsHomeModalComponent,
        LostPetCenterWihtDevicesModalComponent,
        LostPetCenterCommuntiesComponent,
        CommuntieInfoComponent,
        StatusDialogComponent,
        OverwatchDialogComponent,
        OverwatchEmergancyInfoDialogComponent,
        DescendantDeviceMoveDialogComponent,
        Clubloc8MembershipBenefitsDialogComponent,
        NoGPSSignalDialogComponent,
        NoGpsDialogInfoComponent,
        ScheduleSleepTurnOffComponent,
        OrderByPipe,
        DatFormatPipe,
        RtlSupportDirective,
        GoogleplaceDirective,
        AutocompletePositionDirective,
        FeatureFlagsDialogComponent,
        DeviceBatteryComponent,
        DeviceBatteryV2Component,
        ActivationCodeDialogComponent,
        MapSosComponent,
        NoDevicesComponent,
        LimitLiveTrackingDialogComponent,
        StopLiveTrackingDialogComponent,
        BannersComponent,
        CarouselComponent,
        FirmwareUpdateModalComponent,
    ],
    exports: [
        CommonModule,
        HttpClientModule,
        HttpClientJsonpModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        InlineSVGModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatCheckboxModule,
        MatRadioModule,
        MatSlideToggleModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatStepperModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatTabsModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        TranslateModule,
        MatExpansionModule,
        MatProgressBarModule,
        ClipboardModule,
        ScrollingModule,
        OwlDateTimeModule,
        OwlMomentDateTimeModule,
        NgxScannerQrcodeModule,
        LanguageSelectComponent,
        StatusLabelComponent,
        BreadcrumbsComponent,
        CookieBarComponent,
        MatMenuModule,
        FooterComponent,
        HeaderComponent,
        MatDialogModule,
        TermsAndConditionsDialog,
        PrivacyAndPolicyDialog,
        EditPhotoDialogComponent,
        ConfirmDialogComponent,
        AddContactDialogComponent,
        TermsAndConditionsComponent,
        UpdatePlanDialogComponent,
        JoinRingDialogComponent,
        PrivacyPolicyComponent,
        PasswordHintsComponent,
        EditPhotoComponent,
        BackBtnComponent,
        GoogleMapsModule,
        MatAutocompleteModule,
        PhoneInputComponent,
        TooltipModule,
        FormatCountdownTimePipe,
        SearchFilterPipe,
        TextAlertLimitDialogComponent,
        QrModalComponent,
        PaymentCongratsMsgComponent,
        DevicePreviewComponent,
        SnackbarComponent,
        TourMatMenuModule,
        AddSubAccountDialogComponent,
        LostPetsCentreModalComponent,
        LostPetsHomeModalComponent,
        LostPetsCounterComponent,
        LostPetCenterWihtDevicesModalComponent,
        LostPetCenterCommuntiesComponent,
        CommuntieInfoComponent,
        StatusDialogComponent,
        OverwatchDialogComponent,
        OverwatchEmergancyInfoDialogComponent,
        DescendantDeviceMoveDialogComponent,
        Clubloc8MembershipBenefitsDialogComponent,
        NoGPSSignalDialogComponent,
        NoGpsDialogInfoComponent,
        ScheduleSleepTurnOffComponent,
        OrderByPipe,
        DatFormatPipe,
        RtlSupportDirective,
        GoogleplaceDirective,
        AutocompletePositionDirective,
        FeatureFlagsDialogComponent,
        DeviceBatteryComponent,
        DeviceBatteryV2Component,
        ActivationCodeDialogComponent,
        MapSosComponent,
        NoDevicesComponent,
        LimitLiveTrackingDialogComponent,
        StopLiveTrackingDialogComponent,
        BannersComponent,
        CarouselComponent,
        FirmwareUpdateModalComponent,
        MatSidenavModule,
    ],
    providers: [WINDOW_PROVIDERS, MatDatepickerModule, CookiesService, PasswordValidatorService],
})
export class SharedModule {}
