import { Component, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-device-battery',
    templateUrl: './device-battery.component.html',
    styleUrls: ['./device-battery.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceBatteryComponent implements OnChanges {
    @Input() batteryLevel: number;
    levelColor: string;
    constructor() {}

    ngOnChanges(changes: SimpleChanges) {
        this.batteryLevel = this.batteryLevel || 0;
        this.setBatteryColor();
    }

    setBatteryColor() {
        switch (true) {
            case this.batteryLevel >= 80:
                this.levelColor = '#009C56';
                break;
            case this.batteryLevel >= 60:
                this.levelColor = '#94bf19';
                break;
            case this.batteryLevel >= 40:
                this.levelColor = '#c98f1f';
                break;
            case this.batteryLevel >= 20:
                this.levelColor = '#df612a';
                break;
            case this.batteryLevel >= 0:
                this.levelColor = '#FF3B30';
                break;
            default:
                this.levelColor = '#888888';
        }
    }
}
