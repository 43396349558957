import { environment } from '../../../environments/environment';

export const API_VERSION_1 = 'v1';
export const API_VERSION_2 = 'v2';
export const API_VERSION_3 = 'v3';
export const API_VERSION_4 = 'v4';
export const API_VERSION_5 = 'v5';
export const API_VERSION_6 = 'v6';
export const BASE_URL = environment.baseApiUrl;
export const BASE_REDIRECT_URL = environment.baseRedirectUrl;
export const WHITELABEL = environment.whiteLabel;
export const GOOGLE_MAP_API_KEY = environment.googleMapApiKey;
export const G_MAP_STATIC_KEY = 'AIzaSyA5ZFMA8dgaFIadvOEg7T-I6mK2x8ZEeiM';
export const G_AUTH_CLIENT = '960826123475-itcgs6fv01sa8cq64nm0ppf9ghl323hh.apps.googleusercontent.com';
export const FB_AUTH_CLIENT = environment.whiteLabel === 'TRACKIMO' ? '409884421079738' : '261761293401049';
