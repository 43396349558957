import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { interval, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom, filter, takeUntil } from 'rxjs/operators';
import { selectUser } from '../selectors/user.selector';
import { ApiService, GoogleMapService, LocalStorageService } from '../services';
import { IAppState } from '../state/app.state';
import { TranslateService } from '@ngx-translate/core';
import { DescendantsActions } from '../actions/descendants.actions';
import {
    selectSubaccountsDevices,
    selectSubaccountsDevicesTotalCount,
} from '../selectors/descendants.selectors';
import { SnackbarService } from '../services/snackbar.service';
import { EventsActions } from '../actions/events.actions';
import { SUBACCOUNTS_LOCATION_PING_INTERVAL, USER_TYPES } from '../constants';
import { Router } from '@angular/router';

@Injectable()
export class DescendantsEffects {
    getDescendants$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DescendantsActions.getDescendants),
            withLatestFrom(this.store.select(selectUser)),
            switchMap(([, user]) =>
                this.apiService.getDescendantsOnly(user.account_id).pipe(
                    map((descendant) => DescendantsActions.getDescendantsSuccess({ response: descendant })),
                    catchError((error) => of(DescendantsActions.getDescendantsError({ error }))),
                ),
            ),
        ),
    );

    getDescendantsDevices$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DescendantsActions.getDescendantsDevices),
            withLatestFrom(this.store.select(selectUser)),
            tap(() => {
                this.googleMapService.removeMarkers();
                this.googleMapService.removeZones();
                this.googleMapService.removeInfoWindows();
                this.googleMapService.removeHistory();
            }),
            switchMap(([, user]) =>
                this.apiService
                    .getDescendantsDevices(user.account_id, 1, USER_TYPES.MASTER_AND_SUB_ACCOUNTS)
                    .pipe(
                        map((devices) =>
                            DescendantsActions.getDescendantsDevicesSuccess({ response: devices }),
                        ),
                        catchError((error) => of(DescendantsActions.getDescendantsDevicesError({ error }))),
                    ),
            ),
        ),
    );

    getDescendantsDevicesSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DescendantsActions.getDescendantsDevicesSuccess),
            withLatestFrom(
                this.store.select(selectUser),
                this.store.select(selectSubaccountsDevices),
                this.store.select(selectSubaccountsDevicesTotalCount),
            ),
            tap(([, , devices]) => this.googleMapService.addMarkerCluster(devices)),
            tap(([, , devices]) => this.googleMapService.fitBounds(devices, [])),
            switchMap(([, user]) =>
                this.apiService.getDevicesLocations(user.account_id, USER_TYPES.MASTER_AND_SUB_ACCOUNTS).pipe(
                    filter((response) => !!response.length),
                    map((response) =>
                        DescendantsActions.pingSubAccountsDevicesLocationsSuccess({ response }),
                    ),
                    catchError((error) =>
                        of(DescendantsActions.pingSubAccountsDevicesLocationsError({ error })),
                    ),
                ),
            ),
        ),
    );

    pingSubAccountsDevicesLocationsSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DescendantsActions.pingSubAccountsDevicesLocationsSuccess),
                withLatestFrom(this.store.select(selectSubaccountsDevices)),
                tap(([, devices]) => {
                    const devicesWithUpdatedLocation = devices?.filter((device) => device.location_ping);
                    this.googleMapService.updateMarkersPositions(devicesWithUpdatedLocation);
                }),
            ),
        { dispatch: false },
    );

    removeDescendant$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DescendantsActions.removeDescendant),
            map((action) => action.email),
            withLatestFrom(this.store.select(selectUser)),
            mergeMap(([email, user]) =>
                this.apiService.removeDescendant(user.account_id, email).pipe(
                    map(() => DescendantsActions.removeDescendantSuccess()),
                    catchError((error) => of(DescendantsActions.removeDescendantError({ error }))),
                ),
            ),
        ),
    );

    removeDescendantSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DescendantsActions.removeDescendantSuccess),
                tap(() => this.snackBar.success(this.translate.instant('SUBACCOUNT_DELETED'))),
            ),
        { dispatch: false },
    );

    moveDescendantDevice$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DescendantsActions.moveDescendantDevice),
            map((action) => action.req),
            withLatestFrom(this.store.select(selectUser)),
            mergeMap(([req, user]) =>
                this.apiService
                    .moveDescendantDevice(
                        user.account_id,
                        req.device_id,
                        req.src_account_id,
                        req.dst_account_email,
                    )
                    .pipe(
                        map(() =>
                            DescendantsActions.moveDescendantDeviceSuccess({
                                msg: this.translate.instant('SAVED'),
                            }),
                        ),
                        catchError((error) => {
                            return of(DescendantsActions.moveDescendantDeviceError({ error }));
                        }),
                    ),
            ),
        ),
    );

    /// ***** Sub Accounts Devices Locations ***** ///
    pingSubAccountsDevicesLocationsInterval$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                DescendantsActions.getDescendantsDevicesSuccess,
                DescendantsActions.changePingDeviceLocationInterval,
            ),
            withLatestFrom(this.store.select(selectSubaccountsDevices)),
            filter(([, devices]) => !!devices.length && this.router.url === '/map/sub-accounts'),
            switchMap(() =>
                interval(
                    +this.localStorage.getItem('PingInterval-sub-accounts') ||
                        SUBACCOUNTS_LOCATION_PING_INTERVAL,
                ).pipe(
                    takeUntil(this.actions$.pipe(ofType(DescendantsActions.clearDescendants))),
                    withLatestFrom(this.store.select(selectUser)),
                    mergeMap(([, user]) =>
                        this.apiService
                            .getDevicesLocations(user.account_id, USER_TYPES.MASTER_AND_SUB_ACCOUNTS)
                            .pipe(
                                filter((response) => !!response.length),
                                map((response) =>
                                    DescendantsActions.pingSubAccountsDevicesLocationsSuccess({ response }),
                                ),
                                catchError((error) =>
                                    of(DescendantsActions.pingSubAccountsDevicesLocationsError({ error })),
                                ),
                            ),
                    ),
                ),
            ),
        ),
    );

    // Calls immediately
    getEventsOnDevicesSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DescendantsActions.getDescendantsDevicesSuccess),
            withLatestFrom(this.store.select(selectUser), this.store.select(selectSubaccountsDevices)),
            switchMap(([, user]) =>
                this.apiService.getNotReadEvents(user.account_id).pipe(
                    map((response) => EventsActions.getSOSDevicesEventsSuccess({ response })),
                    catchError((error) => of(EventsActions.getSOSDevicesEventsError({ error }))),
                ),
            ),
        ),
    );

    snackBarSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DescendantsActions.moveDescendantDeviceSuccess),
                tap((resonse) => this.snackBar.success(resonse.msg)),
            ),
        { dispatch: false },
    );

    snackBarError$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DescendantsActions.moveDescendantDeviceError),
                tap(({ error }) => {
                    this.snackBar.error(this.translate.instant(error.error.message_key.toUpperCase()));
                }),
            ),
        { dispatch: false },
    );

    constructor(
        private actions$: Actions,
        private apiService: ApiService,
        private store: Store<IAppState>,
        private snackBar: SnackbarService,
        private googleMapService: GoogleMapService,
        private translate: TranslateService,
        private router: Router,
        private localStorage: LocalStorageService,
    ) {}
}
