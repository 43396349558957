<div class="devider" *ngIf="!buttonToShow">
    <span></span>
    <p>{{ 'OR' | translate }}</p>
    <span></span>
</div>

<div class="social-container">
    <!-- <div class="social-btn" (click)="signInWithApple()" *ngIf="!buttonToShow || buttonToShow === 'apple'">
        <img src="assets/images/socials-logo/apple.svg" alt="apple">
        Continue with Apple
    </div> -->

    <div class="social-btn" (click)="signInWithGoogle()" *ngIf="!buttonToShow || buttonToShow === 'google'">
        <img src="assets/images/socials-logo/google.svg" alt="google">
        {{ 'CONTINUE_WITH_GOOGLE' | translate }}
    </div>

    <ng-container *ngIf="skin.whiteLabel === 'TRACKIPET'">
        <div class="social-btn" (click)="signInWithFB()" *ngIf="!buttonToShow || buttonToShow === 'facebook'">
            <img src="assets/images/socials-logo/facebook.svg" alt="facebook">
            {{ 'CONTINUE_WITH_FACEBOOK' | translate }}
        </div>
    </ng-container>
</div>