export const WATCHINU = {
    title: 'WATCHINU',
    brandName: 'WatchinU',
    primaryColor: '#1181ef',
    androidAppLink: '',
    iosAppLink: '',
    localizeLink: 'https://trackimo-web-plus-translations.s3.us-west-2.amazonaws.com/locale/',
    clientId: '6531a578-f901-41f7-a76a-5dcddd22bfa0',
    clientSecret: 'b5b61bf4b1a5a756c57a3a2784d0cf7a',
};
