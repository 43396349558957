<div class="dialog-wrapper add-contact-dialog" rtl>
    <div mat-dialog-title>
        <h1 class="title">
            {{ 'CLUBLOC8_BENEFITS' | translate }}
            <i class="icon close-icon" [inlineSVG]="'#close'" (click)="closeDialog()"></i>
        </h1>

    </div>
    <div mat-dialog-content>
        <ul>
            <li [innerHTML]="'UNLIMITED_LIVE_TRACKING' | translate"></li>
            <li [innerHTML]="'FIRST_WEEK_FREE' | translate"></li>
            <li [innerHTML]="'3_YEAR_WARRANTY' | translate"></li>
            <li [innerHTML]="'EARLY_ACCESS' | translate"></li>
            <li [innerHTML]="'MONTHLY_NEWSLETTER' | translate"></li>
            <li [innerHTML]="'50_DISCOUNT' | translate"></li>
        </ul>
    </div>
    <div class="dialog-actions" mat-dialog-actions>
        <button class="btn primary" (click)="applyCode()" mat-flat-button>
            {{ 'CLAIM_YOUR_DISCOUNT' | translate }}
        </button>
        <button class="btn basic" (click)="closeDialog()" mat-flat-button>
            {{ 'CANCEL' | translate }}
        </button>
    </div>
</div>