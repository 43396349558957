import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LostPetsCentreModalComponent } from '../lost-pets-centre-modal/lost-pets-centre-modal.component';

@Component({
    selector: 'app-lost-pet-center-communties',
    templateUrl: './lost-pet-center-communties.component.html',
    styleUrls: ['./lost-pet-center-communties.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LostPetCenterCommuntiesComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<LostPetCenterCommuntiesComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
        public dialog: MatDialog,
    ) {}

    ngOnInit(): void {
        console.log(this.data);
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    next(): void {
        this.closeDialog();

        this.dialog.open(LostPetsCentreModalComponent, {
            autoFocus: false,
            width: '500px',
            maxHeight: '80vh',
            backdropClass: 'dialogBackground',
        });
    }
}
