<div class="dialog-wrapper">
    <div mat-dialog-title>
        <h1 class="title">
            {{'LOCAL_COMMUNITY' | translate}}
            <i class="icon close-icon" [inlineSVG]="'#close'" (click)="closeDialog()"></i>
        </h1>

    </div>
    <div class="content" mat-dialog-content>
        <p>{{'RULES_TITLE' | translate: { brand:skin.brandName } }}</p>

        <p>{{'RULES' | translate}}</p>

        <ul>
            <li>{{'RULE_1' | translate}}</li>
            <li>{{'RULE_2' | translate}}</li>
            <li>{{'RULE_3' | translate}}</li>
            <li>{{'RULE_4' | translate}}</li>
            <li>{{'RULE_5' | translate}}</li>
        </ul>

    </div>
</div>