import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'app-no-devices',
    templateUrl: './no-devices.component.html',
    styleUrls: ['./no-devices.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoDevicesComponent {
    constructor() {}
}
