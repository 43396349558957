<footer class="footer" [ngClass]="skin.whiteLabel" rtl>
    <div class="content" *ngIf="skin.whiteLabel !== 'MYLOC8' && skin.whiteLabel !== 'PETLOC8'">
        <div class="footer-title">
            <h1>{{ 'TRACKIMO_IS_AVAILABLE_FOR_IOS_AND_ANDROID' | translate: { brand: skin.brandName } }}</h1>
            <app-language-select class="language-switcher"></app-language-select>
        </div>

        <div class="app-liks">
            <a href="{{ skin.iosAppLink }}" target="_blank" class="link"><img src="assets/images/app-store.svg"
                    alt="app store" /></a>
            <a href="{{ skin.androidAppLink }}" target="_blank" class="link"><img src="assets/images/google-play.svg"
                    alt="google play" /></a>
        </div>

        <div>
            <ul class="footer-nav">
                <li>
                    <a *ngIf="skin.whiteLabel !== 'TRACKIPET'" routerLink="/faq" class="link">{{ 'FAQS' | translate
                        }}</a>
                    <a *ngIf="skin.whiteLabel === 'TRACKIPET'" target="_blank"
                        href="https://trackipet.com/pages/frequently-asked-question" class="link">{{ 'FAQS' |
                        translate }}</a>

                </li>
                <li>
                    <a href="{{skin.contactUs}}" target="_blank" class="link">{{'CONTACT_US' | translate}}</a>
                </li>
                <li>
                    <a target="_blank" href="{{skin.privacyPolicy}}" class="link">{{
                        'PRIVACY_POLICY' | translate }}</a>
                </li>
                <li>
                    <a target="_blank" href="{{skin.termsOfService}}" class="link">
                        {{ 'TERMS_AND_CONDITIONS' | translate }}
                    </a>
                </li>

            </ul>

            <ng-container [ngSwitch]="skin.whiteLabel">
                <p class="copyright" *ngSwitchCase="'TAGANDTRACK'">{{ 'TNT_COPYRIGHT' | translate: { date: date } }}</p>
                <p class="copyright" *ngSwitchCase="'TRACKIPET'">{{ 'TRACKIPET_COPYRIGHT' | translate: { date: date } }}
                </p>
                <p class="copyright" *ngSwitchDefault>{{ 'COPY_RIIGHT' | translate }}</p>
            </ng-container>
        </div>
    </div>

    <div class="content" *ngIf="skin.whiteLabel === 'MYLOC8' || skin.whiteLabel === 'PETLOC8'">
        <div class="info">
            <img class="logo" src="white-labels/{{ skin.whiteLabel }}/logo-small.svg" [alt]="skin.whiteLabel" />
            <ul class="footer-nav">
                <li>
                    <a *ngIf="skin.whiteLabel !== 'TRACKIPET'" routerLink="/faq" class="link">{{ 'FAQS' | translate
                        }}</a>
                    <a *ngIf="skin.whiteLabel === 'TRACKIPET'" target="_blank"
                        href="https://trackipet.com/pages/frequently-asked-question" class="link">{{ 'FAQS' |
                        translate }}</a>
                </li>

                <li>
                    <a href="{{skin.contactUs}}" target="_blank" class="link">{{
                        'CONTACT_US' | translate
                        }}</a>
                </li>

                <li>
                    <a target="_blank" href="{{skin.privacyPolicy}}" class="link">{{
                        'PRIVACY_POLICY' | translate }}</a>
                </li>
                <li>
                    <a target="_blank" href="{{skin.termsOfService}}" class="link">
                        {{ 'TERMS_AND_CONDITIONS' | translate }}
                    </a>
                </li>
            </ul>

            <ng-container [ngSwitch]="skin.whiteLabel">
                <p class="copyright" *ngSwitchCase="'TAGANDTRACK'">{{ 'TNT_COPYRIGHT' | translate: { date: date } }}</p>
                <p class="copyright" *ngSwitchCase="'TRACKIPET'">{{ 'TRACKIPET_COPYRIGHT' | translate: { date: date } }}
                </p>
                <p class="copyright" *ngSwitchCase="'MYLOC8'">© myloc8 {{date}}</p>
                <p class="copyright" *ngSwitchCase="'PETLOC8'">© petloc8 {{date}}</p>
                <p class="copyright" *ngSwitchDefault>{{ 'COPY_RIIGHT' | translate }}</p>
            </ng-container>
        </div>

        <div class="app-buttons">
            <a href="{{ skin.androidAppLink }}" target="_blank" class="link">
                <img *ngIf="skin.whiteLabel === 'MYLOC8'" src="assets/images/google-play-store.svg" alt="google play" />
                <img *ngIf="skin.whiteLabel === 'PETLOC8'" src="assets/images/google-play-store-white.svg"
                    alt="google play" />
            </a>
            <a href="{{ skin.iosAppLink }}" target="_blank" class="link">
                <img *ngIf="skin.whiteLabel === 'MYLOC8'" src="assets/images/app-store-white.svg" alt="app store" />
                <img *ngIf="skin.whiteLabel === 'PETLOC8'" src="assets/images/app-store-white-2.svg" alt="app store" />
            </a>
        </div>
    </div>

    <app-cookie-bar *ngIf="!accepted" (acceptCookieEvent)="acceptCookie()"></app-cookie-bar>
</footer>