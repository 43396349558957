<div class="dialog-wrapper">
    <div class="overwatch-info-container">
        <div class="title">Your emergency phone number</div>
        <div class="desc">
            <p>Phone number <b>{{ userPhoneNumber }}</b> will be used to contact you in a case of emergency</p>
            <p>You can change it anytime in Account Settings.</p>
        </div>
    </div>
    <div mat-dialog-actions>
        <div class="nav-container">
            <button class="btn primary" (click)="close()" mat-flat-button>{{ 'OK' | translate }}</button>
        </div>
    </div>
</div>
