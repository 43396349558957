<div class="dialog-wrapper add-contact-dialog" rtl>
    <div mat-dialog-title>
        <h1 class="title">{{ 'JOIN_RING' | translate }}</h1>
    </div>
    <div mat-dialog-content>
        <p class="error" *ngIf="error">{{error}}</p>
        <mat-form-field class="form-field" appearance="outline">
            <mat-label>{{ 'ACTIVATION_CODE' | translate }}
            </mat-label>
            <input class="input-upper" [formControl]="activationCodeInput" matInput />
            <mat-hint>{{ 'JOIN_HINT' | translate }}</mat-hint>
        </mat-form-field>

    </div>
    <div mat-dialog-actions>
        <div class="dialog-actions">
            <button class="btn basic" (click)="closeDialog()" mat-flat-button>
                {{ 'CANCEL' | translate }}
            </button>
            <button [disabled]="activationCodeInput.invalid" class="btn primary"
                (click)="applyCode(activationCodeInput.value)" mat-flat-button>
                {{ 'SUBMIT' | translate }}
            </button>
        </div>
    </div>
</div>