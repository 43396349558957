import { createSelector } from '@ngrx/store';
import { MAX_DEVICE_ZONES_ASSIGNMENTS, ZoneType } from '../constants/common.constants';
import { IDeviceShort, IZone } from '../interfaces';
import { IAppState } from '../state/app.state';
import { IZoneState } from '../state/zones.stete';
import { selectDeviceFences, selectDevicesList } from './devices.selectors';

export const selectFeature = (appState: IAppState) => appState.zones;

export const selectZonesList = createSelector(selectFeature, (appState: IZoneState) => appState.zones);
export const selectSectedZone = createSelector(
    selectFeature,
    (appState: IZoneState) => appState.selectedZone,
);

export const selectSectedZoneWithDevices = createSelector(
    selectSectedZone,
    selectDevicesList,
    (zone: IZone, devices: IDeviceShort[]) => ({
        ...zone,
        devices: devices?.map((device) => ({
            ...device,
            isChecked: zone?.deviceIds?.includes(device.device_id),
        })),
    }),
);

export const selectGroupedZonesList = createSelector(selectZonesList, (zones) => {
    if (zones.length) {
        const zonesTypes = {
            wifi: [],
            polygon: [],
            polyline: [],
            geofence: [],
            isEmpty: !!zones.length,
        };
        zones.forEach((zone) => {
            if (zone.type === ZoneType.WIFI) {
                zonesTypes.wifi.push(zone);
            } else if (zone.type === ZoneType.POLYGON) {
                zonesTypes.polygon.push(zone);
            } else if (zone.type === ZoneType.POLYLINE) {
                zonesTypes.polyline.push(zone);
            } else {
                zonesTypes.geofence.push(zone);
            }
        });
        return zonesTypes;
    }

    return undefined;
});

export const selectZonesListWithDeviceFences = createSelector(
    selectZonesList,
    selectDeviceFences,
    (zones, deviceFences) => {
        if (zones.length && deviceFences) {
            const zonesTypes = {
                wifi: [],
                polygon: [],
                polyline: [],
                geofence: [],
                isEmpty: !!zones.length,
            };
            const deviceFencesRectangles = deviceFences.filter((zones) =>
                (zones.id as any as string).includes(ZoneType.DEVICE_GEOZONE),
            );

            zones
                .map((zone) => ({
                    ...zone,
                    checked: isZoneCheked(deviceFences, zone),
                    disabled:
                        zone.type !== ZoneType.POLYGON &&
                        zone.type !== ZoneType.POLYLINE &&
                        !isZoneCheked(deviceFences, zone) &&
                        (zone.id as any as string).includes(ZoneType.DEVICE_GEOZONE) &&
                        deviceFencesRectangles.length === MAX_DEVICE_ZONES_ASSIGNMENTS,
                }))
                .sort((a, b) => +b.checked - +a.checked)
                .forEach((zone) => {
                    if (zone.type === ZoneType.WIFI) {
                        zonesTypes.wifi.push(zone);
                    } else if (zone.type === ZoneType.POLYGON) {
                        zonesTypes.polygon.push(zone);
                    } else if (zone.type === ZoneType.POLYLINE) {
                        zonesTypes.polyline.push(zone);
                    } else {
                        zonesTypes.geofence.push(zone);
                    }
                });
            return zonesTypes;
        }

        return undefined;
    },
);

const isZoneCheked = (geozones: IZone[], zone: IZone) => geozones.some((gzone) => gzone.id === zone.id);
