<div class="dialog-wrapper">
    <div mat-dialog-title>
        <h1 class="title">
            {{ (!data?.isHiddenDevicesBar ? 'EDIT_TRACKER_PHOTO' : 'ACCOUNT_PHOTO') | translate }}
        </h1>
    </div>
    <div mat-dialog-content>
        <app-edit-photo (fileSelectEvent)="onFileSelect($event)" [isEtollDevice]="data?.isEtollDevice"
            (fileResetEvent)="onRemovePhoto()" [iconID]="data.iconID"
            [isHiddenDevicesBar]="data.isHiddenDevicesBar"></app-edit-photo>
    </div>
    <div mat-dialog-actions>
        <div class="nav-container">
            <button class="btn basic" (click)="closeDialog()" mat-flat-button>
                {{ 'CANCEL' | translate }}
            </button>
            <button class="btn primary" (click)="onSave()" mat-flat-button>
                {{ (!data?.isHiddenDevicesBar ? 'SAVE' : 'ADD') | translate }}
            </button>
        </div>
    </div>
</div>