import { Component, ChangeDetectionStrategy, Input, OnChanges } from '@angular/core';

@Component({
    selector: 'app-device-battery-v2',
    templateUrl: './device-battery-v2.component.html',
    styleUrls: ['./device-battery-v2.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceBatteryV2Component implements OnChanges {
    @Input() batteryLevel: number;
    @Input() small: boolean;
    levelColor: string;
    constructor() {}

    ngOnChanges() {
        this.batteryLevel = this.batteryLevel || 0;
        this.setBatteryColor();
    }

    setBatteryColor() {
        switch (true) {
            case this.batteryLevel >= 80:
                this.levelColor = '#009C56';
                break;
            case this.batteryLevel >= 60:
                this.levelColor = '#94bf19';
                break;
            case this.batteryLevel >= 40:
                this.levelColor = '#c98f1f';
                break;
            case this.batteryLevel >= 20:
                this.levelColor = '#df612a';
                break;
            case this.batteryLevel >= 0:
                this.levelColor = '#FF3B30';
                break;
            default:
                this.levelColor = '#888888';
        }
    }
}
