import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable, take } from 'rxjs';
import { IDeviceShort } from 'src/app/store/interfaces';
import { selectDevicesList } from 'src/app/store/selectors/devices.selectors';
import { IAppState } from 'src/app/store/state/app.state';
import { LostPetsCentreModalComponent } from '../lost-pets-centre-modal/lost-pets-centre-modal.component';
import { LostPetCenterCommuntiesComponent } from '../lost-pet-center-communties/lost-pet-center-communties.component';

@Component({
    selector: 'app-lost-pet-center-wiht-devices-modal',
    templateUrl: './lost-pet-center-wiht-devices-modal.component.html',
    styleUrls: ['./lost-pet-center-wiht-devices-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LostPetCenterWihtDevicesModalComponent implements OnInit {
    selectedDevice: IDeviceShort;
    loadedDevices$: Observable<IDeviceShort[]>;

    constructor(
        public dialogRef: MatDialogRef<LostPetCenterWihtDevicesModalComponent>,
        private store: Store<IAppState>,
        public dialog: MatDialog,
    ) {}

    ngOnInit(): void {
        this.loadedDevices$ = this.store.select(selectDevicesList).pipe(take(1));
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    next(): void {
        this.closeDialog();

        this.dialog.open(LostPetCenterCommuntiesComponent, {
            autoFocus: false,
            width: '500px',
            maxHeight: '80vh',
            backdropClass: 'dialogBackground',
            data: { selectedDevice: this.selectedDevice },
        });
    }

    addAnotherLostPet(): void {
        this.closeDialog();

        this.dialog.open(LostPetsCentreModalComponent, {
            autoFocus: false,
            width: '500px',
            maxHeight: '80vh',
            backdropClass: 'dialogBackground',
        });
    }
}
