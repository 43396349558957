import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { NUMBERS_ONLY } from 'src/app/store/constants';
import { ApiService } from 'src/app/store/services';
import { skin } from 'src/white-labels';

@Component({
    selector: 'app-join-ring-dialog',
    templateUrl: './join-ring-dialog.component.html',
    styleUrls: ['./join-ring-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JoinRingDialogComponent implements OnInit, OnDestroy {
    private destroyed$ = new Subject<void>();
    activationCodeInput = new UntypedFormControl(null, [
        Validators.required,
        Validators.pattern(NUMBERS_ONLY),
    ]);
    skin = skin;
    error: string;

    constructor(
        public dialogRef: MatDialogRef<JoinRingDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
        private apiService: ApiService,
        private cdRef: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {}

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    applyCode(activationCode?: string) {
        this.error = null;
        this.apiService
            .acceptInvitation(activationCode)
            .pipe(takeUntil(this.destroyed$))
            .subscribe(
                () => this.dialogRef.close(true),
                ({ error }) => {
                    this.error = error.message;
                    this.cdRef.markForCheck();
                },
            );
    }
}
