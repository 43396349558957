<app-header></app-header>

<div class="auth-container" [ngClass]="skin.whiteLabel">
    <div class="wrapper">
        <div class="auth-container-form" rtl>
            <div class="container">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>

</div>

<app-footer></app-footer>