<section class="{{skin.whiteLabel}}">
    <app-header></app-header>
    <google-map height="100%" width="100%" *ngIf="mapApiLoaded" #GoogleMap></google-map>

    <div class="device-info" [ngClass]="{'pet-info': petInfo}" *ngIf="device">
        <div class="device-info-head">
            <h5 *ngIf="!petInfo">
                <i class="icon no-fill icon-map-point" [inlineSVG]="'#map-point'"></i>
                {{ device.deviceName ? device.deviceName : device.deviceId }}
            </h5>
            <div class="date" *ngIf="device?.lastUpdated">
                <span class="date-suffix">{{ 'DATE' | translate }}:</span>
                <b> {{ device?.lastUpdated * 1000 | dateFormat: 'HH:mm' }}</b>
                <h3></h3>
            </div>
        </div>

        <div class="location-info">
            <p class="location">{{ address$ | async }}</p>
            <span class="geo">{{ 'LAT' | translate }}: {{ device.geoLocation.latitude }}</span>
            <span class="geo">{{ 'LNG' | translate }}: {{ device.geoLocation.longitude }}</span>
        </div>

        <div class="device-info-footer" *ngIf="!petInfo">
            <div class="item" *ngIf="device.batteryLevel">
                <i class="icon no-fill" [inlineSVG]="'#battery'"></i>{{ device.batteryLevel }}%
            </div>
            <div class="item" *ngIf="device.geoLocation.type">
                <i class="icon" [inlineSVG]="'#gps'"></i>{{ device.geoLocation.type }}
            </div>
        </div>

        <a *ngIf="petInfo"
            href="https://www.google.com/maps/dir/?api=1&destination={{petInfo.location.latitude }},{{ petInfo.location.longitude }}"
            target="_blank" class="btn primary" mat-flat-button title="{{ 'DIRECTIONS_TO' | translate }}">
            {{ 'DIRECTIONS' | translate }}</a>
    </div>
</section>