import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TEXT_INPUT } from 'src/app/store/constants';
import { EditPhotoDialogComponent } from '../edit-photo-dialog/edit-photo-dialog.component';
import { filter } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { LostPetsCounterComponent } from '../lost-pets-counter/lost-pets-counter.component';

@Component({
    selector: 'app-lost-pets-centre-modal',
    templateUrl: './lost-pets-centre-modal.component.html',
    styleUrls: ['./lost-pets-centre-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LostPetsCentreModalComponent implements OnInit {
    lostPetForm: FormGroup = this.formBuilder.group({
        userPhoto: [null],
        petName: ['', [Validators.required, Validators.pattern(TEXT_INPUT)]],
        petDomesticAddress: ['', [Validators.required]],
        areaLastSeen: ['', [Validators.required]],
        dateLastSeen: ['', [Validators.required]],
    });

    profilePhotoSrc: SafeUrl;

    constructor(
        public dialogRef: MatDialogRef<LostPetsCentreModalComponent>,
        private formBuilder: FormBuilder,
        public dialog: MatDialog,
        private sanitizer: DomSanitizer,
        private cdRef: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {}

    closeDialog(): void {
        this.dialogRef.close();
    }

    notify(isValid: boolean): void {
        const formData = this.lostPetForm.value;
        console.log(formData);

        this.closeDialog();

        const dialog = this.dialog.open(LostPetsCounterComponent, {
            data: { isHiddenDevicesBar: true },
            autoFocus: false,
            width: '400px',
            backdropClass: 'dialogBackground',
        });

        dialog
            .afterClosed()
            .pipe(filter((inValue) => Boolean(inValue)))
            .subscribe((inValue) => {
                console.log(inValue);

                // this.lostPetForm.patchValue({ userPhoto: inValue });
                // const mediaType = 'application/image';
                // const blob = new Blob([inValue], { type: mediaType });
                // const unsafeImg = URL.createObjectURL(blob);
                // this.profilePhotoSrc = this.sanitizer.bypassSecurityTrustUrl(unsafeImg);
                // this.cdRef.markForCheck();
            });
    }

    openProfilePhotoDialog(event: Event): void {
        const dialog = this.dialog.open(EditPhotoDialogComponent, {
            data: { isHiddenDevicesBar: true },
            autoFocus: false,
            width: '600px',
            backdropClass: 'dialogBackground',
        });

        dialog
            .afterClosed()
            .pipe(filter((inValue) => Boolean(inValue)))
            .subscribe((inValue) => {
                this.lostPetForm.patchValue({ userPhoto: inValue });
                const mediaType = 'application/image';
                const blob = new Blob([inValue], { type: mediaType });
                const unsafeImg = URL.createObjectURL(blob);
                this.profilePhotoSrc = this.sanitizer.bypassSecurityTrustUrl(unsafeImg);
                this.cdRef.markForCheck();
            });
    }
}
