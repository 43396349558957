import { animate, style, transition, trigger } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ApiService, WindowService } from 'src/app/store/services';

@Component({
    selector: 'app-banners',
    templateUrl: './banners.component.html',
    styleUrls: ['./banners.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('carouselAnimation', [
            transition('void => *', [style({ opacity: 0 }), animate('200ms', style({ opacity: 1 }))]),
            transition('* => void', [animate('200ms', style({ opacity: 0 }))]),
        ]),
    ],
})
export class BannersComponent implements OnDestroy {
    private destroyed$ = new Subject<void>();

    banners = [
        {
            title: 'Activate your device!',
            subTitle: 'To activate your device, click to the button.',
            imgUrl: 'assets/images/banners/banner-image-1.svg',
            buttonTitle: 'Activate Tracker',
            buttonAction: () => {
                this.router.navigate(['/add-device']);
            },
        },
        {
            title: 'New: Local Community.',
            subTitle: 'Join other 1450 local members to help keep track of your pet should they go missing.',
            imgUrl: 'assets/images/banners/banner-image-2.svg',
            buttonTitle: 'Join Local Community',
            buttonAction: () => {
                this.apiService
                    .joinLocalCommunity({
                        country: '',
                    })
                    .pipe(takeUntil(this.destroyed$))
                    .subscribe(() => {
                        this.router.navigate(['/map/rings/community-info']);
                    });
            },
        },

        {
            title: "Don't have a TrackiPet device yet?",
            subTitle: 'Welcome discount -20% off',
            imgUrl: 'assets/images/banners/banner-image-3.svg',
            buttonTitle: 'Buy Now',
            buttonAction: () => {
                this.window.open('https://trackipet.com');
            },
        },
    ];
    currentSlide = 0;

    constructor(private router: Router, private apiService: ApiService, private window: WindowService) {}

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    goToSlide(index: number) {
        this.currentSlide = index;
    }
}
