export const TRACKIPET = {
    title: 'TRACKIPET',
    brandName: 'TrackiPet',
    primaryColor: '#3DB456',
    androidAppLink: 'https://play.google.com/store/apps/details?id=com.trackimo.app2.trackiplus',
    iosAppLink: 'https://apps.apple.com/ua/app/trackipet/id1621740474',
    localizeLink: 'https://trackimo-web-plus-translations.s3.us-west-2.amazonaws.com/locale/',
    clientId: '2d38a0a5-0365-41c7-85b9-a9e10397bc61',
    clientSecret: 'd83be8ab9b61d05ed17c6db00b404c1f',
};
