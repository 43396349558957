import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, map, takeWhile, tap, timer } from 'rxjs';

@Component({
    selector: 'app-lost-pets-counter',
    templateUrl: './lost-pets-counter.component.html',
    styleUrls: ['./lost-pets-counter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LostPetsCounterComponent implements OnInit {
    countDown$: Observable<number>;
    isTimerFinished: boolean;

    constructor(private router: Router, public dialogRef: MatDialogRef<LostPetsCounterComponent>) {}

    ngOnInit(): void {
        this.countDown$ = timer(0, 1000).pipe(
            map((n) => 5 - n),
            takeWhile((n) => n >= 0),
            tap((n) => {
                if (!n || n < 1) {
                    this.isTimerFinished = true;
                }
            }),
        );
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    goToInfo() {
        this.dialogRef.close();
        this.router.navigate(['/map/rings/lost-pet-info', 123]);
    }
}
