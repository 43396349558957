import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { delay, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { DevicesActions } from 'src/app/store/actions/devices.actions';
import { IWifi, IWifiNetwork } from 'src/app/store/interfaces';
import { ApiService } from 'src/app/store/services';
import { SnackbarService } from 'src/app/store/services/snackbar.service';
import { IAppState } from 'src/app/store/state/app.state';

@Component({
    selector: 'app-firmware-update-modal',
    templateUrl: './firmware-update-modal.component.html',
    styleUrls: ['./firmware-update-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FirmwareUpdateModalComponent implements OnInit, OnDestroy {
    modalTitle = 'New firmware update';
    isWifiListShown = false;
    confirmShown = false;
    petWifiLoaderShown = false;
    isWifiConnectionError = false;

    showPassword = false;
    wifiSelected: IWifiNetwork;

    wifiPassword = new UntypedFormControl(null);
    wifiList: IWifiNetwork[];

    private destroyed$ = new Subject<void>();

    constructor(
        public dialogRef: MatDialogRef<FirmwareUpdateModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
        private apiService: ApiService,
        private cdRef: ChangeDetectorRef,
        private translate: TranslateService,
        private snackBar: SnackbarService,
        private store: Store<IAppState>,
    ) {}

    ngOnInit(): void {}

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    onUpdateNow() {
        this.modalTitle = this.translate.instant('FW_WIFI_MODAL_TITLE');
        this.isWifiListShown = true;

        this.apiService
            .putWifiNetworksByDeviceId(this.data.accountID, this.data.deviceID)
            .pipe(takeUntil(this.destroyed$))
            .subscribe({
                next: (res) => {
                    this.wifiList = res.wifiNetworks;
                    this.cdRef.markForCheck();
                },
                error: () => {
                    this.closeDialog();
                    this.snackBar.error(this.translate.instant('WIFI_LIST_LOADING_ERROR'));

                    this.cdRef.markForCheck();
                },
            });
    }

    onWifiClick(wifi: IWifiNetwork) {
        this.wifiSelected = wifi;
        this.modalTitle = this.translate.instant('FW_WIFI_MODAL_NAME', { wifi_name: wifi.wifiName });
    }

    changeVisiblePassword(): void {
        this.showPassword = !this.showPassword;
    }

    backToWifiList() {
        if (this.petWifiLoaderShown) {
            this.confirmShown = true;
            this.modalTitle = this.translate.instant('ARE_YOU_SHURE');
        } else {
            this.wifiSelected = null;
            this.modalTitle = this.translate.instant('FW_WIFI_MODAL_TITLE');
            this.isWifiListShown = true;

            this.wifiPassword.reset();
        }
    }

    onWifiPasswordEnter() {
        this.apiService
            .enableFotaForDevice(this.data.accountID, this.data.deviceID, {
                wifi_name: this.wifiSelected.wifiName,
                password: this.wifiPassword.value,
                wifi_encrypt: true,
            })
            .pipe(
                tap((res) => {
                    this.modalTitle = this.translate.instant('WIFI_PASSWORD_CHECK', {
                        wifi_name: this.wifiSelected.wifiName,
                    });
                    this.petWifiLoaderShown = true;

                    this.store.dispatch(DevicesActions.getFOTALogs());
                }),
                takeUntil(this.destroyed$),
            )
            .subscribe((res) => {
                this.cdRef.markForCheck();

                setTimeout(() => {
                    this.closeDialog();
                }, 5000);
            });
    }

    backToLoading() {
        this.confirmShown = false;
        this.modalTitle = this.modalTitle = this.translate.instant('WIFI_PASSWORD_CHECK', {
            wifi_name: this.wifiSelected.wifiName,
        });
        this.petWifiLoaderShown = true;
    }
}
