export const MYTRACKI = {
    title: 'MYTRACKI',
    brandName: 'MyTracki',
    primaryColor: '#1181ef',
    androidAppLink: 'https://play.google.com/store/apps/details?id=com.trackimo.app2',
    iosAppLink: 'https://apps.apple.com/ua/app/trackimo/id1515561581',
    localizeLink: 'https://trackimo-web-plus-translations.s3.us-west-2.amazonaws.com/locale/',
    clientId: '7f8976b0-3f0e-4cf2-9d14-6b898787292c',
    clientSecret: '99404ead886bcb4fec3b87fa9c7ba3f9',
};
