<div class="dialog-wrapper">
    <div mat-dialog-title>
        <h1 class="title">
            {{ 'SCAN_QR' | translate }}
            <i class="icon close-icon" [inlineSVG]="'#close'" (click)="closeDialog()"></i>
        </h1>

    </div>
    <div mat-dialog-content>
        <!-- <ngx-scanner-qrcode #action="scanner" [isAuto]="true" (event)="onEvent(action)" (error)="onError($event)">
        </ngx-scanner-qrcode> -->
    </div>
</div>