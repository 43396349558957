export const FIDELIDADE = {
    title: 'FIDELIDADE',
    brandName: 'Fidelidade',
    primaryColor: '#EE2429',
    androidAppLink: '',
    iosAppLink: '',
    localizeLink: 'https://trackimo-web-plus-translations.s3.us-west-2.amazonaws.com/locale/',
    clientId: '8ca4e558-a5fa-4a36-953c-906f400035dd',
    clientSecret: 'f48b78365767f1c7c1427dbdf5f49764',
};
