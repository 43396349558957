import { createReducer, on } from '@ngrx/store';
import { DevicesActions } from '../actions/devices.actions';
import { MapActions } from '../actions/map.actions';
import { StatusLabelActions } from '../actions/status-label.actions';
import { ZonesActions } from '../actions/zones.actions';
import { LocationState } from '../constants/common.constants';
import { initialStatusState } from '../state/status.stete';
import { DescendantsActions } from '../actions/descendants.actions';
import { AuthActions } from '../actions/auth.actions';

export const statusReducer = createReducer(
    initialStatusState,
    on(DevicesActions.activatePlanByCard, (state) => ({
        ...state,
        paymentProcessing: true,
    })),
    on(DevicesActions.activatePlanByCardSuccess, (state) => ({
        ...state,
        paymentProcessing: false,
    })),
    on(DevicesActions.activatePlanByCardError, (state) => ({
        ...state,
        paymentProcessing: false,
    })),
    on(DevicesActions.renewPlan, (state) => ({
        ...state,
        paymentProcessing: true,
    })),
    on(DevicesActions.renewPlanSuccess, (state) => ({
        ...state,
        paymentProcessing: false,
    })),
    on(DevicesActions.renewPlanError, (state) => ({
        ...state,
        paymentProcessing: false,
    })),
    on(DevicesActions.renewPrepaidPlan, (state) => ({
        ...state,
        paymentProcessing: true,
    })),
    on(DevicesActions.renewPrepaidPlanSuccess, (state) => ({
        ...state,
        paymentProcessing: false,
    })),
    on(DevicesActions.renewPrepaidPlanError, (state) => ({
        ...state,
        paymentProcessing: false,
    })),
    on(DevicesActions.activatePrepaidPlan, (state) => ({
        ...state,
        paymentProcessing: true,
    })),
    on(DevicesActions.activatePrepaidPlanSuccess, (state) => ({
        ...state,
        paymentProcessing: false,
    })),
    on(DevicesActions.activatePrepaidPlanError, (state) => ({
        ...state,
        paymentProcessing: false,
    })),
    on(DevicesActions.renewPlanByCoupon, (state) => ({
        ...state,
        paymentProcessing: true,
    })),
    on(DevicesActions.activatePlanByCoupon, (state) => ({
        ...state,
        paymentProcessing: true,
    })),
    on(DevicesActions.activatePlanByCouponSuccess, (state) => ({
        ...state,
        paymentProcessing: false,
    })),
    on(DevicesActions.activatePlanByCouponError, (state) => ({
        ...state,
        paymentProcessing: false,
    })),
    on(DevicesActions.renewPlanByCouponSuccess, (state) => ({
        ...state,
        paymentProcessing: false,
    })),
    on(DevicesActions.renewPlanByCouponError, (state) => ({
        ...state,
        paymentProcessing: false,
    })),
    on(DevicesActions.increaseSMSLimit, (state) => ({
        ...state,
        paymentProcessing: true,
    })),
    on(DevicesActions.increaseSMSLimitSuccess, (state) => ({
        ...state,
        paymentProcessing: false,
    })),
    on(DevicesActions.increaseSMSLimitError, (state) => ({
        ...state,
        paymentProcessing: false,
    })),
    on(DevicesActions.resetPaymentProcessingStatus, (state) => ({
        ...state,
        paymentProcessing: false,
    })),
    on(AuthActions.startSocialLogin, (state) => ({
        ...state,
        loginProcessing: true,
        loginSuccess: false,
    })),
    on(AuthActions.endSocialLogin, (state) => ({
        ...state,
        loginProcessing: false,
        loginSuccess: false,
        statusLabel: undefined,
    })),
    on(AuthActions.login, (state) => ({
        ...state,
        loginProcessing: true,
        loginSuccess: false,
    })),
    on(AuthActions.loginError, (state) => ({
        ...state,
        loginProcessing: false,
        loginSuccess: false,
    })),
    on(AuthActions.loginSuccess, (state) => ({
        ...state,
        loginProcessing: false,
        loginSuccess: true,
    })),
    on(DevicesActions.getDevicesSuccess, (state) => ({
        ...state,
        devicesLoaded: true,
    })),
    on(DevicesActions.clearDevices, (state) => ({
        ...state,
        devicesLoaded: false,
    })),
    on(DevicesActions.getDeviceByIDSuccess, (state) => ({
        ...state,
        deviceLoaded: true,
    })),
    on(DevicesActions.removeSelectedDevice, (state) => ({
        ...state,
        deviceLoaded: false,
    })),
    on(ZonesActions.getZonesSuccess, (state) => ({
        ...state,
        zonesLoaded: true,
    })),
    on(DescendantsActions.getDescendantsSuccess, (state) => ({
        ...state,
        subAccountsLoaded: true,
    })),
    on(DescendantsActions.clearDescendants, (state) => ({
        ...state,
        subAccountsLoaded: false,
    })),
    on(MapActions.mapLoadedSuccess, (state) => ({
        ...state,
        mapLoaded: true,
    })),
    on(StatusLabelActions.showStatusLabel, (state, { statusLabel }) => ({
        ...state,
        statusLabel,
        loginProcessing: false,
    })),
    on(StatusLabelActions.hideStatusLabel, (state) => ({
        ...state,
        statusLabel: undefined,
    })),
    on(DevicesActions.triggerDevicesLocationsSuccess, (state) => ({
        ...state,
        devicesLocationsStatus: LocationState.WAITING,
    })),
    on(DevicesActions.triggerDevicesLocationsError, (state) => ({
        ...state,
        devicesLocationsStatus: undefined,
    })),
    on(DevicesActions.pingDevicesLocationsSuccess, (state, { response }) => ({
        ...state,
        devicesLocationsStatus: response.some(
            (locationItem) => locationItem.after_last_location_request === 'true',
        )
            ? null
            : LocationState.WAITING,
    })),
    on(DevicesActions.pingDevicesLocationsError, (state) => ({
        ...state,
        devicesLocationsStatus: undefined,
    })),

    on(DevicesActions.pingDeviceLocationSuccess, (state, { response }) => ({
        ...state,
        devicesLocationsStatus: response?.after_last_location_request,
    })),

    // Ping device location
    on(DevicesActions.startPingDeviceLocation, (state) => ({
        ...state,
        pingDeviceLocation: true,
    })),
    on(DevicesActions.stopPingDeviceLocation, (state) => ({
        ...state,
        pingDeviceLocation: false,
    })),

    on(MapActions.showNoLocationModal, (state) => ({
        ...state,
        noLocationModal: true,
    })),
    on(MapActions.hideNoLocationModal, (state) => ({
        ...state,
        noLocationModal: false,
    })),
    on(DevicesActions.getDevicesSubscription, (state) => ({
        ...state,
        subscriptionLoading: true,
    })),
    on(DevicesActions.getDevicesSubscriptionSuccess, (state) => ({
        ...state,
        subscriptionLoading: false,
    })),
    on(DevicesActions.getDevicesSubscriptionError, (state) => ({
        ...state,
        subscriptionLoading: false,
    })),
    on(DevicesActions.getDeviceFPIDetailsSuccess, (state) => ({
        ...state,
        fpiSettingsLoaded: true,
    })),
    on(DevicesActions.getDeviceFPIDetailsError, (state) => ({
        ...state,
        fpiSettingsLoaded: true,
    })),
    on(DevicesActions.removeSelectedDevice, (state) => ({
        ...state,
        fpiSettingsLoaded: false,
    })),
    on(DevicesActions.renewPlanWithStripeSuccess, (state) => ({
        ...state,
        renewWithStripeStatus: true,
    })),
    on(DevicesActions.clearRenewWithStripeStatus, (state) => ({
        ...state,
        renewWithStripeStatus: false,
    })),
    on(DevicesActions.renewPlanWithStripe, (state) => ({
        ...state,
        paymentProcessing: true,
    })),
    on(DevicesActions.renewPlanWithStripeSuccess, (state) => ({
        ...state,
        paymentProcessing: false,
    })),
    on(DevicesActions.renewPlanWithStripeError, (state) => ({
        ...state,
        paymentProcessing: false,
    })),
    on(DevicesActions.associateDeviceWithAccount, (state) => ({
        ...state,
        paymentProcessing: true,
    })),
    on(DevicesActions.associateDeviceWithAccountSuccess, (state) => ({
        ...state,
        paymentProcessing: false,
    })),
    on(DevicesActions.associateDeviceWithAccountError, (state) => ({
        ...state,
        paymentProcessing: false,
    })),
);
