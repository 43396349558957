import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { from, Observable, ReplaySubject, Subject } from 'rxjs';
import { IAppState } from 'src/app/store/state/app.state';
import { filter, takeUntil } from 'rxjs/operators';
import { ApiService } from '../../store/services';
import { ICongratsMessage, ISMSOptions, IStatusLabel } from '../../store/interfaces';
import { DevicesActions } from '../../store/actions/devices.actions';
import { StatusLabelActions } from '../../store/actions/status-label.actions';
import { selectIsPaymentProcessing, selectIsStatusLabel } from '../../store/selectors/status.selectors';
import { selectBrainTreeToken } from '../../store/selectors/shared.selector';
import dropin from 'braintree-web-drop-in';
import { TranslateService } from '@ngx-translate/core';
import { COUNTRIES } from 'src/app/store/constants';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';

@Component({
    selector: 'app-text-alert-limit-dialog',
    templateUrl: './text-alert-limit-dialog.component.html',
    styleUrls: ['./text-alert-limit-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextAlertLimitDialogComponent implements OnInit, OnDestroy {
    @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
    paymentStatus$: Observable<IStatusLabel>;
    paymentProcessing$: Observable<boolean>;
    selectedCountryCode: any;
    countryCodeCtrl: UntypedFormControl = new UntypedFormControl();
    countryFilterCtrl: UntypedFormControl = new UntypedFormControl();
    countryCodes: (string | number | string[])[][] = COUNTRIES;
    filteredCountryCodes: ReplaySubject<(string | number | string[])[][]> = new ReplaySubject<
        (string | number | string[])[][]
    >(1);
    smsOptions: ISMSOptions[];
    selectedSMSOption: ISMSOptions;
    isFinalStep = false;
    isBrainTreeToken: boolean;
    dropinInstance: any;
    congratsMessage: ICongratsMessage;
    private destroyed$ = new Subject<void>();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data,
        public dialogRef: MatDialogRef<TextAlertLimitDialogComponent>,
        private store: Store<IAppState>,
        private cdRef: ChangeDetectorRef,
        private formBuilder: UntypedFormBuilder,
        private apiService: ApiService,
        private translate: TranslateService,
    ) {}

    ngOnInit(): void {
        this.paymentStatus$ = this.store.select(selectIsStatusLabel);
        this.paymentProcessing$ = this.store.select(selectIsPaymentProcessing);
        this.filteredCountryCodes.next(this.countryCodes.slice());
        this.countryFilterCtrl.valueChanges
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => this.filterCountryCodes());

        this.store
            .select(selectBrainTreeToken)
            .pipe(
                filter((token) => Boolean(token)),
                takeUntil(this.destroyed$),
            )
            .subscribe((token) => {
                this.isBrainTreeToken = true;
                this.cdRef.markForCheck();
                this.createBraintreeUI(token);
            });
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();

        this.store.dispatch(DevicesActions.resetPaymentProcessingStatus());
        this.store.dispatch(DevicesActions.removeBrainTreeDropInToken());
        this.store.dispatch(StatusLabelActions.hideStatusLabel());
    }

    onSelectionChange(event: MatSelectChange) {
        this.selectedCountryCode = event.value[2];
        if (this.selectedCountryCode) {
            this.apiService
                .getSmsOptions(this.selectedCountryCode)
                .pipe(takeUntil(this.destroyed$))
                .subscribe((res: ISMSOptions[]) => {
                    this.smsOptions = res.map((sms: ISMSOptions, index: number) => {
                        return {
                            ...sms,
                            isChecked: index === 0 ? true : false,
                        };
                    });

                    this.selectedSMSOption = this.smsOptions[0];
                    this.cdRef.markForCheck();
                });
        }
    }

    close(): void {
        this.dialogRef.close();
    }

    closeWithConfirm(): void {
        this.dialogRef.close(true);
    }

    protected filterCountryCodes() {
        if (!this.countryCodes) {
            return;
        }

        let search = this.countryFilterCtrl.value;
        if (!search) {
            this.filteredCountryCodes.next(this.countryCodes.slice());
            return;
        } else {
            search = search.toLowerCase();
        }

        this.filteredCountryCodes.next(
            this.countryCodes.filter((country) => {
                const countryCode = `+${country[2]}`;
                return (
                    country[0].toString().toLowerCase().includes(search) ||
                    countryCode.toString().toLowerCase().includes(search)
                );
            }),
        );
    }

    radioChange(event: MatRadioChange, data) {
        this.smsOptions = this.smsOptions.map((sms: ISMSOptions) => {
            return {
                ...sms,
                isChecked: data.smsLimit === sms.smsLimit ? true : false,
            };
        });
        this.selectedSMSOption = this.smsOptions.filter(
            (sms: ISMSOptions) => sms.smsLimit === data.smsLimit,
        )[0];
    }

    createBraintreeUI(token: string): void {
        from(
            dropin.create({
                authorization: token,
                container: document.getElementById('#dropin-container'),
                paypal: {
                    flow: 'vault',
                    amount: this.selectedSMSOption.price,
                    currency: this.selectedSMSOption.currency,
                },
            }),
        )
            .pipe(takeUntil(this.destroyed$))
            .subscribe((instance) => (this.dropinInstance = instance));
    }

    goToPayment() {
        this.isFinalStep = true;
        const payload = {
            planID: this.selectedSMSOption.planId,
            deviceID: this.data.currentDeviceID,
        };
        this.store.dispatch(DevicesActions.getBrainTreeDropInToken({ payload }));
    }

    payForSms() {
        from(this.dropinInstance.requestPaymentMethod())
            .pipe(takeUntil(this.destroyed$))
            .subscribe((payload) => {
                this.congratsMessage = {
                    planID: this.selectedSMSOption.planId,
                    planDesc: `${this.selectedSMSOption.smsLimit} ${this.translate.instant('SMS_MONTHLY')}`,
                    planPrice: `${this.selectedSMSOption.price} ${this.selectedSMSOption.currency}`,
                };
                return this.store.dispatch(
                    DevicesActions.increaseSMSLimit({
                        payload: {
                            nonce: payload['nonce'],
                            plan_id: this.selectedSMSOption.planId,
                            email: '',
                            first_name: '',
                            last_name: '',
                        },
                        smsLimit: this.selectedSMSOption.smsLimit,
                        accountID: this.data.currentAccountID ? this.data.currentAccountID : null,
                        deviceID: this.data.currentDeviceID ? this.data.currentDeviceID : null,
                    }),
                );
            });
    }
}
