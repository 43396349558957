import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { NoGpsSignalService } from '../../store/services';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-no-gps-dialog',
    templateUrl: './no-gps-dialog.component.html',
    styleUrls: ['./no-gps-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoGPSSignalDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<NoGPSSignalDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
        private noGPSService: NoGpsSignalService,
    ) {}

    close(): void {
        this.noGPSService.close();
    }

    moreInfo() {
        this.noGPSService.openMoreInfoDialog();
    }
}
