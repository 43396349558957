export const PETLOC8 = {
    title: 'PETLOC8',
    brandName: 'PetLoc8',
    primaryColor: '#21293D',
    androidAppLink: 'https://play.google.com/store/apps/details?id=com.trackimo.app2.petloc8',
    iosAppLink: 'https://apps.apple.com/ua/app/petloc8/id6736832204',
    localizeLink: 'https://trackimo-web-plus-translations.s3.us-west-2.amazonaws.com/locale/',
    clientId: '9092cd94-a728-47b7-86da-e15c9a3d4cdb',
    clientSecret: '9f540cd42ec8d3bc452ce39cdd3d6de4',
    storeLink: 'https://petloc8.com/',
    contactUs: 'https://petloc8.com/#shopify-section-template--18779387953316__contact_form_zPgHJW',
    termsOfService: 'https://petloc8.com/policies/terms-of-service',
    termsOfUse: 'https://petloc8.com/pages/subscription-terms-of-use',
    privacyPolicy: 'https://petloc8.com/policies/privacy-policy',
};
