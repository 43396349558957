import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { ApiService, WindowService } from 'src/app/store/services';
import { SnackbarService } from 'src/app/store/services/snackbar.service';
import { skin } from 'src/white-labels';

@Component({
    selector: 'app-banners',
    templateUrl: './banners.component.html',
    styleUrls: ['./banners.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('carouselAnimation', [
            transition('void => *', [style({ opacity: 0 }), animate('200ms', style({ opacity: 1 }))]),
            transition('* => void', [animate('200ms', style({ opacity: 0 }))]),
        ]),
    ],
})
export class BannersComponent implements OnDestroy {
    private destroyed$ = new Subject<void>();

    banners;
    currentSlide = 0;

    constructor(
        private router: Router,
        private apiService: ApiService,
        private window: WindowService,
        private translate: TranslateService,
        private snackBar: SnackbarService,
    ) {
        if (skin.whiteLabel === 'TRACKIPET') {
            this.banners = [
                {
                    title: 'Activate your device!',
                    subTitle: 'To activate your device, click to the button.',
                    imgUrl: 'assets/images/banners/banner-image-1.svg',
                    buttonTitle: 'Activate Tracker',
                    buttonAction: () => {
                        this.router.navigate(['/add-device']);
                    },
                },
                {
                    title: 'New: Local Community.',
                    subTitle:
                        'Join other 1450 local members to help keep track of your pet should they go missing.',
                    imgUrl: 'assets/images/banners/banner-image-2.svg',
                    buttonTitle: 'Join Local Community',
                    buttonAction: () => {
                        this.apiService
                            .joinLocalCommunity({
                                country: '',
                            })
                            .pipe(takeUntil(this.destroyed$))
                            .subscribe(
                                () => {
                                    this.router.navigate(['/map/rings/community-info']);
                                },
                                ({ error }) => {
                                    this.snackBar.warning(error.message);
                                },
                            );
                    },
                },

                {
                    title: "Don't have a TrackiPet device yet?",
                    subTitle: 'Welcome discount -20% off',
                    imgUrl: 'assets/images/banners/banner-image-3.svg',
                    buttonTitle: 'Buy Now',
                    buttonAction: () => {
                        this.window.open('https://trackipet.com');
                    },
                },
            ];
        } else if (skin.whiteLabel === 'PETLOC8') {
            this.banners = [
                {
                    title: this.translate.instant('ACTIVATE_YOUR_TRACKER'),
                    subTitle: this.translate.instant('ACTIVATE_YOUR_TRACKER_MSG'),
                    imgUrl: 'assets/images/banners/banner-image-1-new.svg',
                    buttonTitle: this.translate.instant('ACTIVATE_TRACKER'),
                    buttonAction: () => {
                        this.router.navigate(['/add-device']);
                    },
                },
                {
                    title: this.translate.instant('CREATE_A_PET_PROFILE'),
                    subTitle: this.translate.instant('CREATE_A_PET_PROFILE_MSG'),
                    imgUrl: 'assets/images/banners/banner-image-2-new.svg',
                    buttonTitle: this.translate.instant('CREATE_A_PET_PROFILE'),
                    buttonAction: () => {
                        this.router.navigate(['/pet-profile/new']);
                    },
                },
                {
                    title: this.translate.instant('MY_COMMUNITY'),
                    subTitle: this.translate.instant('MY_COMMUNITY_MSG'),
                    imgUrl: 'assets/images/banners/banner-image-3-new.svg',
                    buttonTitle: this.translate.instant('INVITE_FRIENDS_AND_FAMILY'),
                    buttonAction: () => {
                        this.router.navigate(['/map/rings/order-tracker']);
                    },
                },
                {
                    title: this.translate.instant('NEW_LOCAL_COMMUNITY'),
                    subTitle: this.translate.instant('JOIN_LOCAL_COMMUNITY_MSG'),
                    imgUrl: 'assets/images/banners/banner-image-4-new.svg',
                    buttonTitle: this.translate.instant('JOIN_LOCAL_COMMUNITY'),
                    buttonAction: () => {
                        this.apiService
                            .joinLocalCommunity({
                                country: '',
                            })
                            .pipe(takeUntil(this.destroyed$))
                            .subscribe(
                                () => {
                                    this.router.navigate(['/map/rings/community-info']);
                                },
                                ({ error }) => {
                                    this.snackBar.warning(error.message);
                                },
                            );
                    },
                },

                {
                    title: this.translate.instant('DONT_HAVE_DEVICE', { brand: skin.brandName }),
                    subTitle: this.translate.instant('20OFF'),
                    imgUrl: 'assets/images/banners/banner-image-5-new.svg',
                    buttonTitle: this.translate.instant('BUY_NOW'),
                    buttonAction: () => {
                        this.window.open('https://petloc8.com');
                    },
                },
            ];
        }
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    goToSlide(index: number) {
        this.currentSlide = index;
    }
}
