import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { IAccountFeatures, IFeatureToggleItem } from '../interfaces';
import { USER_FEATURES_OPTIONS_TYPE } from '../constants';

@Injectable({ providedIn: 'root' })
export class FeatureToggleService {
    userFeatures: IAccountFeatures;
    constructor(private localStorageService: LocalStorageService) {}

    initFeaturesData(userFeatures: IAccountFeatures) {
        this.userFeatures = userFeatures;
        Object.keys(this.userFeatures).map((featureItem) => {
            this.localStorageService.remove(featureItem.toUpperCase());
            if (userFeatures[featureItem] === USER_FEATURES_OPTIONS_TYPE.ENABLED) {
                this.localStorageService.setItem(featureItem.toUpperCase(), 'true');
            }

            if (featureItem === 'gql_location') {
                this.localStorageService.setItem(featureItem.toUpperCase(), 'true');
            }
        });
    }

    getFeaturesData() {
        return this.userFeatures
            ? Object.keys(this.userFeatures)
                  .filter(
                      (featureItem) => this.userFeatures[featureItem] === USER_FEATURES_OPTIONS_TYPE.OPTIONAL,
                  )
                  .map((featureItem) => ({
                      featureName: featureItem.toUpperCase(),
                      featureValue: this.localStorageService.getItem(featureItem.toUpperCase()),
                  }))
            : [];
    }

    updateFeaturesData(data: IFeatureToggleItem[]) {
        data.map((item: IFeatureToggleItem) => {
            this.localStorageService.setItem(item.featureName, String(item.featureValue));
        });
    }
}
