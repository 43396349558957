<!-- <div class="spinner-wrapper" *ngIf="loginProcessing$ | async">
    <mat-spinner class="spinner" diameter="60"></mat-spinner>
</div> -->

<div class="flex-container" [ngClass]="{ loading: (loginProcessing$ | async) === true }">
    <app-status-label *ngIf="loginError$ | async" [statusLabel]="loginError$ | async"></app-status-label>

    <div class="welcome-header" [ngClass]="skin.whiteLabel">
        <h1>{{ 'WELCOME' | translate }}</h1>
        <h4 *ngIf="skin.whiteLabel === 'TRACKIPET'">to TrackiPet application</h4>
    </div>

    <form [formGroup]="form" (ngSubmit)="onSubmit(form.valid)">
        <mat-form-field class="form-field" appearance="outline">
            <mat-label>{{ 'EMAIL' | translate }}</mat-label>
            <input formControlName="email" matInput placeholder="{{ 'EMAIL' | translate }}" />
        </mat-form-field>

        <ng-container *ngIf="showPasswordField">
            <mat-form-field class="form-field" appearance="outline">
                <mat-label>{{ 'PASSWORD' | translate }}</mat-label>
                <input formControlName="password" matInput [type]="showPassword ? 'text' : 'password'"
                    placeholder="{{ 'PASSWORD' | translate }}" />

                <i class="icon" (click)="changeVisiblePassword()" matSuffix
                    [inlineSVG]="showPassword ? '#eye' : '#eye-close'"></i>
            </mat-form-field>

            <div class="link-container">
                <mat-checkbox class="form-field checkbox" color="primary" formControlName="isKeepLogged">
                    {{ 'KEEP_ME_LOGGED' | translate }}
                </mat-checkbox>

                <span class="link" routerLink="/forgot-password"> {{ 'FORGOT_PASSWORD' | translate }} </span>
            </div>
        </ng-container>

        <button class="btn primary form-btn" mat-flat-button>
            {{ 'CONTINUE' | translate }}
        </button>
    </form>

    <app-social-btns
        *ngIf="skin.whiteLabel === 'TRACKIPET' || skin.whiteLabel === 'TRACKIMO' || skin.whiteLabel === 'PETLOC8' || skin.whiteLabel === 'MYLOC8'"></app-social-btns>

    <div class="login-footer"
        *ngIf="skin.whiteLabel !== 'TRACKIPET' && skin.whiteLabel !== 'TRACKIMO' && skin.whiteLabel !== 'PETLOC8' && skin.whiteLabel !== 'MYLOC8'">
        <p class="label">{{ 'NEW_TO' | translate }} {{ skin.brandName }}?</p>
        <button class="btn primary-light" routerLink="/create-account" mat-flat-button>
            {{ (skin.whiteLabel !== 'TAGANDTRACK' ? 'ADD_ACCOUNT' : 'CREATE_ACCOUNT') | translate }}
        </button>
    </div>

</div>