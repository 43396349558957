<div class="carousel">
    <ng-container *ngFor="let banner of banners; let i = index">
        <div class="banner" *ngIf="i === currentSlide" @carouselAnimation>
            <div class="title">
                <h5>{{banner.title}}</h5>
                <p>{{banner.subTitle}}</p>
            </div>

            <div class="image-container">
                <img src="{{banner.imgUrl}}" alt="">
            </div>

            <button class="btn primary" (click)="banner.buttonAction()" mat-flat-button>
                {{banner.buttonTitle}}
            </button>
        </div>
    </ng-container>

    <div class="points">
        <div class="point" [ngClass]="{active: i === currentSlide}" (click)="goToSlide(i)"
            *ngFor="let banner of banners; let i = index"></div>
    </div>
</div>