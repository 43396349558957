import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, map, of } from 'rxjs';
import { skin } from 'src/white-labels';
import { selectUser } from '../../selectors/user.selector';
import { IAppState } from '../../state/app.state';

@Injectable()
export class SubAccountGuard  {
    skin = skin;
    constructor(private router: Router, private store: Store<IAppState>) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        if (skin.whiteLabel === 'TAGANDTRACK') {
            this.router.navigate(['/']);
            return of(false);
        }
        return this.store.select(selectUser).pipe(
            map((user) => {
                if (user.subAccountEnabled) {
                    return true;
                } else {
                    this.router.navigate(['/']);
                    return false;
                }
            }),
        );
    }
}
