<section *ngIf="socialUserInfo">
    <app-status-label *ngIf="loginError$ | async" [statusLabel]="loginError$ | async"></app-status-label>

    <h1>{{ 'CREATE_AN_ACCOUNT' | translate }}</h1>

    <div class="user-info">
        <img src="{{ socialUserInfo.photoUrl ? socialUserInfo.photoUrl : '/assets/images/user-icon.svg' }}"
            alt="{{socialUserInfo?.firstName}}">
        <div>
            <h3 *ngIf="socialUserInfo.firstName">{{socialUserInfo.firstName}} {{socialUserInfo.lastName}}</h3>
            <p>{{socialUserInfo.email}}</p>
        </div>
    </div>

    <p class="desc">{{ 'PLS_FILL_INFO' | translate }}</p>

    <form [formGroup]="form" (ngSubmit)="onSubmit(form.valid)">
        <mat-form-field class="form-field" appearance="outline">
            <mat-label>{{ 'COUNTRY' | translate }}</mat-label>
            <mat-select matNativeControl formControlName="addressCountry" required>
                <mat-option *ngFor="let inCountry of allCountries" [value]="inCountry.code">{{ inCountry.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="form-field" appearance="outline" *ngIf="form.get('addressCountry').value == 'CA'">
            <mat-label>{{ 'PROVINCE' | translate }}</mat-label>
            <mat-select matNativeControl formControlName="state" required>
                <mat-option *ngFor="let inState of canadaStates" [value]="inState.abbreviation">{{ inState.name
                    }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="form-field" appearance="outline" *ngIf="form.get('addressCountry').value == 'US'">
            <mat-label>{{ 'STATE' | translate }}</mat-label>
            <mat-select matNativeControl formControlName="state" required>
                <mat-option *ngFor="let inState of usaStates" [value]="inState.abbreviation">{{ inState.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <app-phone-input [isRequired]="true"></app-phone-input>

        <div class="info-hint">
            * {{ 'REQUIRED_INFORMATION' | translate }}
        </div>

        <mat-checkbox [class.invalid-checkbox]="isTermsChecked === false"
            class="form-field checkbox primary terms-and-conditions" color="primary" formControlName="terms">
            {{ 'I_ACCEPT_THE' | translate }}
            <a target="_blank" href="{{skin.termsOfService}}" class="link-primary">
                {{ 'TERMS_AND_CONDITIONS' | translate }}
            </a>
        </mat-checkbox>
        <mat-checkbox [class.invalid-checkbox]="isPrivacyChecked === false"
            class="form-field checkbox primary privacy-policy" color="primary" formControlName="privacy">
            {{ 'I_ACCEPT_THE' | translate }}
            <a target="_blank" href="{{skin.privacyPolicy}}" class="link-primary">
                {{ 'PRIVACY_POLICY' | translate }}
            </a>
        </mat-checkbox>

        <button class="btn primary form-btn" mat-flat-button>
            {{ 'CREATE' | translate }}
        </button>
    </form>
</section>