export const MYLOC8 = {
    title: 'MYLOC8',
    brandName: 'myloc8',
    primaryColor: '#303D64',
    androidAppLink: 'https://play.google.com/store/apps/details?id=com.myloc8.app',
    iosAppLink: 'https://apps.apple.com/ua/app/myloc8/id6736831889',
    localizeLink: 'https://trackimo-web-plus-translations.s3.us-west-2.amazonaws.com/locale/',
    clientId: '9092cd94-a728-47b7-86da-e15c9a3d4cdb',
    clientSecret: '9f540cd42ec8d3bc452ce39cdd3d6de4',
    storeLink: 'https://myloc8.com',
    contactUs: 'https://myloc8.com/policies/contact-information',
    termsOfService: 'https://myloc8.com/policies/terms-of-service',
    termsOfUse: 'https://myloc8.com/pages/subscription-terms-of-use',
    privacyPolicy: 'https://myloc8.com/policies/privacy-policy',
};
