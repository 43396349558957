<div class="carousel">
    <ng-container *ngFor="let slide of slides; let i = index">
        <div class="slide" *ngIf="i === currentSlide" @carouselAnimation>
            <img [src]="slide.src" />
            <p>{{slide.text}}</p>
        </div>
    </ng-container>

    <!-- controls -->
    <!-- <button class="control prev" (click)="onPreviousClick()">
        <span class="arrow left"></span>
    </button>
    <button class="control next" (click)="onNextClick()">
        <span class="arrow right"></span>
    </button> -->
</div>

<div class="points">
    <div class="point" [ngClass]="{active: i === currentSlide}" (click)="goToSlide(i)"
        *ngFor="let slide of slides; let i = index"></div>
</div>

<div class="nav-container-wrap">
    <div class="nav-container">
        <button class="btn basic" (click)="skipIntro()">{{ (currentSlide !== slides.length -1 ? 'skip' : 'UPDATE_LATER')
            | translate }}</button>
        <button class="btn primary" (click)="nextSlide()" mat-flat-button>
            {{ (currentSlide !== slides.length -1 ? 'NEXT' : 'OK, let’s start') | translate }}
        </button>
    </div>
</div>