import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ZonesActions } from '../actions/zones.actions';
import { StatusLabelType, ZoneType } from '../constants/common.constants';
import { selectUser } from '../selectors/user.selector';
import { selectSectedZone } from '../selectors/zones.selectors';
import { ApiService } from '../services';
import { IAppState } from '../state/app.state';
import { StatusLabelActions } from '../actions/status-label.actions';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '../services/snackbar.service';
import { selectSectedDevice } from '../selectors/devices.selectors';

@Injectable()
export class ZonesEffects {
    getZones$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ZonesActions.getZones),
            withLatestFrom(this.store.select(selectUser), this.store.select(selectSectedDevice)),
            switchMap(([, user, device]) =>
                this.apiService
                    .getZones(device?.info?.owner_id ? device?.info?.owner_id : user.account_id)
                    .pipe(
                        map((response) => ZonesActions.getZonesSuccess({ response })),
                        catchError((error) => of(ZonesActions.getZonesError({ error }))),
                    ),
            ),
        ),
    );

    getZoneByID$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ZonesActions.getZoneByID),
            map((action) => action.zoneID),
            withLatestFrom(this.store.select(selectUser)),
            switchMap(([zoneID, user]) =>
                this.apiService.getZoneByID(user.account_id, zoneID).pipe(
                    map((response) => ZonesActions.getZoneByIDSuccess({ response })),
                    catchError((error) => of(ZonesActions.getZoneByIDError({ error }))),
                ),
            ),
        ),
    );

    createNewZone$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ZonesActions.createNewZone),
            map((action) => action.zone),
            withLatestFrom(this.store.select(selectUser)),
            switchMap(([zone, user]) => {
                const zonesInPayload = zone.deviceIds;
                return this.apiService.createNewZone(user.account_id, zone).pipe(
                    map((zone) => {
                        if (zonesInPayload.length !== zone.deviceIds.length) {
                            this.store.dispatch(
                                StatusLabelActions.showStatusLabel({
                                    statusLabel: {
                                        status: this.translate.instant('ZONES_LIMIT_REACH'),
                                        labelType: StatusLabelType.ERROR,
                                    },
                                }),
                            );
                        }

                        let zoneType = zone.type;
                        switch (zoneType) {
                            case ZoneType.WIFI:
                                zoneType = 'Wi-Fi';
                                break;
                            case ZoneType.POLYLINE:
                                zoneType = 'Stretch of road';
                                break;
                            case ZoneType.POLYGON:
                                zoneType = 'Polygon';
                                break;
                            default:
                                zoneType = 'Geofence';
                                break;
                        }

                        return ZonesActions.createNewZoneSuccess({
                            zone,
                            msg: this.translate.instant('ZONE_ADDED', {
                                zoneName: zone.name,
                                zoneType,
                            }),
                        });
                    }),
                    catchError((error) => of(ZonesActions.createNewZoneError({ error }))),
                );
            }),
        ),
    );

    updateZone$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ZonesActions.updateZone),
            map((action) => action.zone),
            withLatestFrom(this.store.select(selectUser)),
            switchMap(([zone, user]) => {
                const zonesInPayload = zone.deviceIds;
                return this.apiService.updateZone(user.account_id, zone).pipe(
                    map((zone) => {
                        if (zonesInPayload.length !== zone.deviceIds.length) {
                            this.store.dispatch(
                                StatusLabelActions.showStatusLabel({
                                    statusLabel: {
                                        status: this.translate.instant('ZONES_LIMIT_REACH'),
                                        labelType: StatusLabelType.ERROR,
                                    },
                                }),
                            );
                        }
                        return ZonesActions.updateZoneSuccess({
                            zone,
                            msg: this.translate.instant('ZONE_UPDATED'),
                        });
                    }),
                    catchError((error) => of(ZonesActions.updateZoneError({ error }))),
                );
            }),
        ),
    );

    removeZone$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ZonesActions.removeZone),
            withLatestFrom(this.store.select(selectUser), this.store.select(selectSectedZone)),
            switchMap(([, user, zone]) =>
                this.apiService.removeZone(user.account_id, zone.id).pipe(
                    map((zone) =>
                        ZonesActions.removeZoneSuccess({ msg: this.translate.instant('ZONE_DELETED') }),
                    ),
                    catchError((error) => of(ZonesActions.removeZoneError({ error }))),
                ),
            ),
        ),
    );

    moveToGeo$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    ZonesActions.updateZoneSuccess,
                    ZonesActions.removeZoneSuccess,
                    ZonesActions.createNewZoneSuccess,
                ),
                tap(() => this.router.navigate(['/map/places/geo'])),
            ),
        { dispatch: false },
    );

    showSnackBarMsg$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    ZonesActions.createNewZoneSuccess,
                    ZonesActions.updateZoneSuccess,
                    ZonesActions.removeZoneSuccess,
                ),
                tap((resonse) => this.snackBar.success(resonse.msg)),
            ),
        { dispatch: false },
    );

    errorZone$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ZonesActions.createNewZoneError),
                tap(({ error }) => {
                    if (error.error?.message_key === 'exception_geozone_alreadyExist') {
                        this.snackBar.warning(this.translate.instant('GEO_NAME_EXIST'));
                    } else {
                        this.snackBar.warning(error.error?.message);
                    }
                }),
            ),
        { dispatch: false },
    );

    constructor(
        private actions$: Actions,
        private apiService: ApiService,
        private store: Store<IAppState>,
        private snackBar: SnackbarService,
        private router: Router,
        private location: Location,
        private translate: TranslateService,
    ) {}
}
