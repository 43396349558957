import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { WindowService } from 'src/app/store/services';
import { skin } from 'src/white-labels';

@Component({
    selector: 'app-pet-found',
    templateUrl: './pet-found.component.html',
    styleUrls: ['./pet-found.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PetFoundComponent implements OnInit {
    constructor(private window: WindowService) {}

    ngOnInit(): void {}

    joinTrackipet(): void {
        if (/android/i.test(navigator.userAgent)) {
            this.window.getWindow.location.href = skin.androidAppLink;
        } else if (/iPad|iPhone|iPod|iPad/.test(navigator.userAgent)) {
            this.window.getWindow.location.href = skin.iosAppLink;
        } else {
            this.window.getWindow.location.href = 'https://trackipet.com';
        }
    }
}
