import { createReducer, on } from '@ngrx/store';
import { AuthActions } from '../actions/auth.actions';
import { DevicesActions } from '../actions/devices.actions';
import { EventsActions } from '../actions/events.actions';
import { UserActions } from '../actions/user.actionsts';
import { initialUserState } from '../state/user.stete';

export const userReducer = createReducer(
    initialUserState,
    on(AuthActions.loginSuccess, (state, { response }) => ({
        ...state,
        user: response,
    })),
    on(AuthActions.loginByVerificationCodeSuccess, (state, { response }) => ({
        ...state,
        user: response,
    })),
    on(UserActions.getUserSuccess, (state, { response }) => ({
        ...state,
        user: response,
    })),
    on(DevicesActions.liveTrackingSuccess, (state, {}) => ({
        ...state,
        user: {
            ...state.user,
            settings: {
                ...state.user.settings,
                fast_tracking_status: {
                    ...state.user.settings.fast_tracking_status,
                    pending_fast_tracking_count:
                        state.user.settings.fast_tracking_status.pending_fast_tracking_count - 1,
                },
            },
        },
    })),
);
