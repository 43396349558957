import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { DevicesActions } from 'src/app/store/actions/devices.actions';
import { MapActions } from 'src/app/store/actions/map.actions';
import { IDeviceByToken, ILostPetInfo } from 'src/app/store/interfaces';
import { selectIsMapLoaded } from 'src/app/store/selectors/status.selectors';
import { ApiService, GoogleMapService } from 'src/app/store/services';
import { IAppState } from 'src/app/store/state/app.state';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { Apollo, gql } from 'apollo-angular';
import { skin } from 'src/white-labels';

@Component({
    selector: 'app-public-map',
    templateUrl: './public-map.component.html',
    styleUrls: ['./public-map.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublicMapComponent implements OnInit, OnDestroy {
    @ViewChild('GoogleMap') set map(map: GoogleMap) {
        if (map) {
            this.googleMapService.map = map;
        }
    }
    address$: Observable<string>;
    device: IDeviceByToken;
    mapApiLoaded: boolean;
    petInfo: ILostPetInfo;
    skin = skin;

    private destroyed$ = new Subject<void>();

    constructor(
        private googleMapService: GoogleMapService,
        private store: Store<IAppState>,
        private route: ActivatedRoute,
        private cdRef: ChangeDetectorRef,
        private readonly actions$: Actions,
        private apollo: Apollo,
        private apiService: ApiService,
    ) {}

    ngOnInit(): void {
        if (!this.googleMapService.map) {
            this.store.dispatch(MapActions.loadMap());
        }

        this.actions$
            .pipe(
                ofType(MapActions.mapLoadedSuccess),
                takeUntil(this.destroyed$),
                switchMap(() =>
                    this.route.queryParams.pipe(
                        takeUntil(this.destroyed$),
                        switchMap((params) =>
                            this.store.select(selectIsMapLoaded).pipe(
                                filter((isMapLoaded) => isMapLoaded),
                                map(() => ({ params })), // Include params in the observable chain
                            ),
                        ),
                    ),
                ),
            )
            .subscribe(({ params }) => {
                this.mapApiLoaded = true;

                if (params.code) {
                    this.apiService
                        .getLostpetInfo(params.code)
                        .pipe(takeUntil(this.destroyed$))
                        .subscribe((response) => {
                            this.googleMapService.removeMarkers();
                            this.petInfo = response;
                            const device = {
                                deviceName: response.petName,
                                deviceId: response.petId,
                                geoLocation: {
                                    latitude: response.location.latitude,
                                    longitude: response.location.longitude,
                                    type: response.location.type,
                                    empty: false,
                                    age: response.location.updatedAgo,
                                },
                                icon_url: response.petIcon,
                                allSOSEvents: [undefined],
                                isLostPet: true,
                            };

                            this.store.dispatch(
                                DevicesActions.getDeviceDetailsByToken({ devices: [device] }),
                            );
                        });
                } else {
                    const subscriptionQuery = gql`
                    subscription {
                        devicesByToken(tokens: ${JSON.stringify(params.token.split(','))}) {
                            id
                            details {
                                name
                                iconId
                            }
                            location {
                                lat
                                lng
                                locationType
                                updated
                            }
                            state {
                                batteryLevel
                            }
                        }
                    }`;

                    this.apollo
                        .subscribe({ query: subscriptionQuery, fetchPolicy: 'no-cache' })
                        .pipe(takeUntil(this.destroyed$))
                        .subscribe((result) => {
                            this.googleMapService.removeMarkers();
                            const devices = result.data['devicesByToken'].map((inDevice) => ({
                                deviceName: inDevice.details.name,
                                deviceId: inDevice.id,
                                geoLocation: {
                                    latitude: inDevice.location.lat,
                                    longitude: inDevice.location.lng,
                                    type: inDevice.location.locationType,
                                },
                                lastUpdated: inDevice.location.updated,
                                batteryLevel: inDevice.state?.batteryLevel,
                                icon_id: inDevice.details.iconId,
                            }));

                            this.store.dispatch(DevicesActions.getDeviceDetailsByToken({ devices }));
                        });
                }

                this.cdRef.markForCheck();
            });

        this.actions$
            .pipe(ofType(DevicesActions.showDeviceInfo), takeUntil(this.destroyed$))
            .subscribe(({ device }) => {
                this.device = device as IDeviceByToken;
                this.address$ = this.googleMapService.getAddress(
                    this.device.geoLocation.latitude,
                    this.device.geoLocation.longitude,
                );
                this.cdRef.markForCheck();
            });
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }
}
