import { IZone } from '../interfaces';

export interface IZoneState {
    zones: IZone[];
    selectedZone: IZone;
}

export const initialZoneState: IZoneState = {
    zones: [],
    selectedZone: undefined,
};
