import { IDeviceShort } from '../interfaces';
import { IDescendant } from '../interfaces/descendants.interface';

export interface IDescendantsState {
    descendants: IDescendant[];
    descendantsDevices: IDeviceShort[];
    subaccountsDevicesTotalCount: number;
}

export const initialDescendantsState: IDescendantsState = {
    descendants: undefined,
    descendantsDevices: undefined,
    subaccountsDevicesTotalCount: undefined,
};
