import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { DevicesActions } from 'src/app/store/actions/devices.actions';
import {
    DeviceStatus,
    ETOLL_DEVICE_TYPE,
    MAX_DEVICES_HISTORY_SELECT,
    StatusLabelType,
} from 'src/app/store/constants/common.constants';
import { IAppState } from 'src/app/store/state/app.state';
import { skin } from 'src/white-labels';
import { UpdatePlanDialogComponent } from '../../../dialogs/update-plan-dialog/update-plan-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SnackbarService } from '../../../store/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'src/app/store/services';
import { IDeviceShort, IUser } from 'src/app/store/interfaces';
import { DescendantDeviceMoveDialogComponent } from 'src/app/dialogs/descendant-device-move-dialog/descendant-device-move-dialog.component';
import { DescendantsActions } from 'src/app/store/actions/descendants.actions';
import { Subject, filter } from 'rxjs';

@Component({
    selector: 'app-device-preview',
    templateUrl: './device-preview.component.html',
    styleUrls: ['./device-preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DevicePreviewComponent implements OnDestroy {
    @Input() device: IDeviceShort;
    @Input() user: IUser;
    @Input() isShowAddressImmediately = true;
    @Input() isAddZone = false;
    @Input() isHideChevron = false;
    @Input() isShowHistorySelect = false;
    @Input() selectedDevicesList: number[];
    @Input() isWifiZone: boolean;
    @Input() selectedDevice: IDeviceShort;
    @Input() isLastWifiStep: boolean;
    @Output() deviceClickEvent = new EventEmitter();
    @Output() toggleClickEvent = new EventEmitter();
    @Output() refreshWifiListEvent = new EventEmitter();
    @Output() clickHistorySelect = new EventEmitter();

    deviceStatus = DeviceStatus;
    eTollDeviceType = ETOLL_DEVICE_TYPE;
    address: string;
    skin = skin;
    public MAX_DEVICES_HISTORY_SELECT = MAX_DEVICES_HISTORY_SELECT;
    private destroyed$ = new Subject<void>();

    // TODO
    sosStatusLabel = {
        status: this.translate.instant('SOS_BUTTON_IS_PRESSED'),
        labelType: StatusLabelType.ERROR,
    };

    constructor(
        public dialog: MatDialog,
        private store: Store<IAppState>,
        private snackBar: SnackbarService,
        private translate: TranslateService,
        public localStorage: LocalStorageService,
    ) {}

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    showDeviceAddress(event): void {
        event.stopPropagation();
        event.preventDefault();

        if (this.device.location_ping.lat && this.device.location_ping.lng) {
            this.store.dispatch(
                DevicesActions.getAddressForDevice({
                    deviceID: this.device.device_id,
                    lat: this.device.location_ping.lat,
                    lng: this.device.location_ping.lng,
                }),
            );
        }
    }

    onToggleChange(device: IDeviceShort, event): void {
        if (device.device_type === this.eTollDeviceType) {
            event.source.checked = event.source.source;
            this.snackBar.error('ETOLL_ZONE_UNAVAILABLE');
        } else {
            this.toggleClickEvent.emit({ device, event });
        }
    }

    refreshWifiList(device: IDeviceShort): void {
        this.refreshWifiListEvent.emit({ deviceId: device.device_id });
    }

    onDeviceClick(device: IDeviceShort) {
        if (this.selectedDevice) {
            return;
        }
        device.isChecked = !device.isChecked;
        if (!this.selectedDevice && !this.isLastWifiStep) {
            this.deviceClickEvent.emit({ device, event });
        }
    }

    renewSubscription(deviceID: number, event: Event) {
        event.stopPropagation();
        this.dialog.open(UpdatePlanDialogComponent, {
            width: '800px',
            maxHeight: '80vh',
            backdropClass: 'dialogBackground',
            data: {
                currentDeviceID: deviceID,
            },
        });
    }

    checkboxChange(device) {
        this.clickHistorySelect.emit(device.device_id);
    }

    moveDeviceToDescendant(device: IDeviceShort): void {
        const dialog = this.dialog.open(DescendantDeviceMoveDialogComponent, {
            width: '800px',
            backdropClass: 'dialogBackground',
            autoFocus: false,
            data: {
                selectedDevice: device,
            },
        });

        dialog
            .afterClosed()
            .pipe(filter((response) => Boolean(response)))
            .subscribe(({ selectedDescendantEmail }) => {
                this.store.dispatch(
                    DescendantsActions.moveDescendantDevice({
                        req: {
                            src_account_id: this.user.account_id,
                            dst_account_email: selectedDescendantEmail,
                            device_id: device.device_id,
                        },
                    }),
                );
            });
    }
}
