<header class="header">

    <div class="content">
        <img class="logo" (click)="goHome()" src="white-labels/{{ skin.whiteLabel }}/logo-small.svg"
            [alt]="skin.whiteLabel" />

        <div class="d-flex-center-sb language-select" *ngIf="!user">
            <i class="icon" [inlineSVG]="'#globe'"></i>
            <app-language-select class="language-switcher"></app-language-select>
        </div>

    </div>

    <nav class="header-nav" *ngIf="user">
        <ul>
            <li *ngIf="skin.whiteLabel !== 'TAGANDTRACK' && user.subAccountEnabled">
                <a class="link" [routerLink]="['/map/sub-accounts']" [routerLinkActive]="['active']">
                    <i class="icon no-fill" [inlineSVG]="'#chalkboardTeacher'"></i>
                    <div class="underline"></div>
                </a>
            </li>

            <li tourAnchor="devices">
                <a class="link" [routerLink]="['/map/devices']" [routerLinkActive]="['active']">
                    <i class="icon no-fill" [inlineSVG]="'#devices_menu'"></i>
                    <div class="underline"></div>
                </a>
            </li>

            <li *ngIf="skin.whiteLabel === 'TRACKIPET'">
                <a class="link" [routerLink]="['/map/rings']" [routerLinkActive]="['active']">
                    <i class="icon ring" [inlineSVG]="'#rings'"></i>
                    <div class="underline"></div>
                </a>
            </li>

            <li tourAnchor="zones">
                <a class="link" [routerLink]="['/map/places']" [routerLinkActive]="['active']">
                    <i class="icon zone" [inlineSVG]="'#zone_menu'"></i>
                    <div class="underline"></div>
                </a>
            </li>
            <li tourAnchor="notifications">
                <a class="link notifications" [routerLink]="['/notifications']" [routerLinkActive]="['active']">
                    <i class="icon" [inlineSVG]="'#notifications_menu'"></i>
                    <div class="underline"></div>
                    <div class="counter" *ngIf="(unreadEvents$ | async)">{{(unreadEvents$ | async)}}</div>
                </a>
            </li>
            <li>
                <div [matMenuTriggerFor]="menu" class="mail">
                    <span class="email-text">{{ user.email }}</span>

                    <i [inlineSVG]="'#admin'" *ngIf="!user.logo_url; else userPhoto"></i>
                    <ng-template #userPhoto>
                        <img class="img" [src]="user.logo_url" alt="User photo" />
                    </ng-template>
                </div>

                <mat-menu class="dropdown-menu" #menu="matMenu">
                    <div class="dropdown-menu-wrapper" rtl>
                        <a [routerLink]="['/account-settings']" class="menu-item" mat-menu-item><i
                                [inlineSVG]="'#user'"></i> {{ 'ACCOUNT_SETTINGS' | translate }}</a>
                        <a [routerLink]="['/contacts']" class="menu-item" mat-menu-item>
                            <i [inlineSVG]="'#contact'"></i>
                            {{ 'CONTACTS' | translate }}
                        </a>
                        <a [routerLink]="['/contact-support']" class="menu-item" *ngIf="skin.whiteLabel === 'MAMORI'"
                            mat-menu-item>
                            <i [inlineSVG]="'#phone'"></i>{{ 'CONTACT_SUPPORT' | translate }}
                        </a>
                        <a href="https://trackimo.com/starting-guide/submitrequest" target="_blank" class="menu-item"
                            *ngIf="skin.whiteLabel === 'TRACKIMO'" mat-menu-item>
                            <i [inlineSVG]="'#phone'"></i>{{ 'CONTACT_SUPPORT' | translate }}
                        </a>
                        <a [routerLink]="['/about/contact-support']" class="menu-item"
                            *ngIf="skin.whiteLabel === 'TAGANDTRACK'" mat-menu-item>
                            <i [inlineSVG]="'#phone'"></i>{{ 'CONTACT_SUPPORT' | translate }}
                        </a>
                        <a *ngIf="skin.whiteLabel !== 'TAGANDTRACK'" [routerLink]="['/subscriptions']" class="menu-item"
                            mat-menu-item>
                            <i [inlineSVG]="'#star_icon'"></i>
                            {{ 'SUBSCRIPTIONS' | translate }}
                        </a>
                        <a class="menu-item" mat-menu-item (click)="moveToTutorials()"><i [inlineSVG]="'#question'"></i>
                            {{ 'TUTORIALS' | translate }}
                        </a>
                        <a [routerLink]="['/developer']" class="menu-item" mat-menu-item><i [inlineSVG]="'#admin'"></i>
                            {{ 'DEVELOPER' | translate }}
                        </a>
                        <button class="menu-item logout" (click)="logout()" mat-menu-item>
                            <i [inlineSVG]="'#signout'"></i> <span>{{ 'LOGOUT' | translate }}</span>
                        </button>
                    </div>
                </mat-menu>
            </li>
        </ul>
    </nav>

    <div class="mobile-support-menu">
        <mat-toolbar>
            <button mat-icon-button [matMenuTriggerFor]="menu" class="trigger-menu-btn" #menuOption="matMenuTrigger"
                (menuOpened)="menuOption.menuOpen" (menuClosed)="menuOption.menuOpen">
                <mat-icon [ngClass]="{isOpen: menuOption.menuOpen}">menu</mat-icon>
            </button>
            <mat-menu #menu="matMenu" [overlapTrigger]="false">
                <ul class="links-wrapper">
                    <ng-container *ngIf="user">
                        <li *ngIf="skin.whiteLabel !== 'TAGANDTRACK' && user.subAccountEnabled">
                            <a class="link" mat-menu-item [routerLink]="['/map/sub-accounts']"
                                [routerLinkActive]="['active']">
                                <i class="icon no-fill" [inlineSVG]="'#chalkboardTeacher'"></i>{{ 'SUB_ACCOUNTS' |
                                translate }}
                            </a>
                        </li>
                        <li>
                            <a class="link" mat-menu-item [routerLink]="['/map/devices']"
                                [routerLinkActive]="['active']">
                                <i class="icon no-fill" [inlineSVG]="'#devices_menu'"></i>{{ 'DEVICES' | translate }}
                            </a>
                        </li>
                        <li>
                            <a class="link" mat-menu-item [routerLink]="['/map/places']"
                                [routerLinkActive]="['active']">
                                <i class="icon zone" [inlineSVG]="'#zone_menu'"></i>{{ 'ZONES' | translate }}
                            </a>
                        </li>
                        <li>
                            <a class="link" mat-menu-item [routerLink]="['/notifications']"
                                [routerLinkActive]="['active']">
                                <i class="icon" [inlineSVG]="'#notifications_menu'"></i>{{ 'NOTIFICATIONS' | translate
                                }}
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/account-settings']" class="link" mat-menu-item><i
                                    [inlineSVG]="'#user'"></i> {{ 'ACCOUNT_SETTINGS' | translate }}</a>
                        </li>
                        <li>
                            <a [routerLink]="['/contacts']" class="link" mat-menu-item>
                                <i [inlineSVG]="'#contact'"></i>
                                {{ 'CONTACTS' | translate }}
                            </a>
                        </li>

                        <li *ngIf="skin.whiteLabel === 'TRACKIMO'">
                            <a href="https://trackimo.com/starting-guide/submitrequest" target="_blank" class="link"
                                mat-menu-item>
                                <i [inlineSVG]="'#phone'"></i>{{ 'CONTACT_SUPPORT' | translate }}
                            </a>
                        </li>

                        <li *ngIf="skin.whiteLabel === 'TAGANDTRACK'">
                            <a [routerLink]="['/about/contact-support']" class="link" mat-menu-item>
                                <i [inlineSVG]="'#phone'"></i>{{ 'CONTACT_SUPPORT' | translate }}
                            </a>
                        </li>

                        <li *ngIf="skin.whiteLabel === 'MAMORI'">
                            <a [routerLink]="['/contact-support']" class="link" mat-menu-item>
                                <i [inlineSVG]="'#phone'"></i>{{ 'CONTACT_SUPPORT' | translate }}
                            </a>
                        </li>

                        <li *ngIf="skin.whiteLabel !== 'TAGANDTRACK'">
                            <a [routerLink]="['/subscriptions']" class="link" mat-menu-item>
                                <i [inlineSVG]="'#star_icon'"></i>
                                {{ 'SUBSCRIPTIONS' | translate }}
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/developer']" class="link" mat-menu-item><i [inlineSVG]="'#admin'"></i>
                                {{ 'DEVELOPER' | translate }}
                            </a>
                        </li>
                    </ng-container>

                    <li>
                        <a [routerLink]="skin.whiteLabel !== 'TAGANDTRACK' ? ['/faq'] : ['/about/faqs']" class="link"
                            mat-menu-item><i *ngIf="user" [inlineSVG]="'#question'"></i> {{'FAQS' | translate}}</a>
                    </li>
                    <li *ngIf="!user">
                        <a href="https://trackimo.com/starting-guide/submitrequest" target="_blank" class="link"
                            *ngIf="skin.whiteLabel !== 'TAGANDTRACK'" mat-menu-item>
                            {{ 'CONTACT_SUPPORT' | translate }}
                        </a>

                        <a [routerLink]="['/about/contact-support']" class="link"
                            *ngIf="skin.whiteLabel === 'TAGANDTRACK'" mat-menu-item>
                            {{ 'CONTACT_SUPPORT' | translate }}
                        </a>
                    </li>
                    <li *ngIf="skin.whiteLabel === 'TAGANDTRACK'">
                        <a [routerLink]="['/about/user-guide']" class="link" mat-menu-item>
                            <i *ngIf="user" [inlineSVG]="'#question'"></i>{{ 'USER_GUIDE' | translate }}</a>
                    </li>
                    <li>
                        <ng-container *ngIf="skin.whiteLabel !== 'TAGANDTRACK'; else tntTermsCond">
                            <a target="_blank" href="https://trackimoplus.com/terms-of-service" class="link"
                                mat-menu-item>
                                <i *ngIf="user" [inlineSVG]="'#question'"></i>
                                {{'TERMS_AND_CONDITIONS' | translate}}
                            </a>
                        </ng-container>
                        <ng-template #tntTermsCond>
                            <a class="link" mat-menu-item [routerLink]="['/about/terms-and-conditions']"><i *ngIf="user"
                                    [inlineSVG]="'#question'"></i>{{'TERMS_AND_CONDITIONS' | translate}}</a>
                        </ng-template>
                    </li>
                    <li>
                        <a target="_blank" href="{{
                                skin.whiteLabel !== 'TAGANDTRACK'
                                    ? 'https://trackimoplus.com/privacy-policy'
                                    : 'https://www.vodafone.com/business/privacy-policy'
                            }}" class="link" mat-menu-item><i *ngIf="user" [inlineSVG]="'#question'"></i>
                            {{ 'PRIVACY_POLICY' | translate }}</a>
                    </li>

                    <li>
                        <button mat-menu-item [matMenuTriggerFor]="languagesSelect">
                            <i class="icon" [inlineSVG]="'#globe'"></i>{{ 'LANGUAGE' | translate}}</button>
                    </li>

                    <mat-menu #languagesSelect="matMenu">
                        <button [ngClass]="{selected: language.value === currentLanguage}"
                            *ngFor="let language of languagesList" (click)="onLanguageChange(language.value)"
                            mat-menu-item>{{
                            language.viewValue }}</button>
                    </mat-menu>

                    <ng-container *ngIf="user">
                        <li>
                            <button class="link logout" (click)="logout()" mat-menu-item>
                                <i [inlineSVG]="'#signout'"></i> <span>{{ 'LOGOUT' | translate }}</span>
                            </button>
                        </li>
                    </ng-container>
                </ul>
            </mat-menu>
        </mat-toolbar>
    </div>
</header>