<div class="dialog-wrapper">
    <div mat-dialog-title>
        <h1 class="title">
            {{'LOST_PET_CENTER' | translate}}
            <i class="icon close-icon" [inlineSVG]="'#close'" (click)="closeDialog()"></i>
        </h1>

    </div>
    <div mat-dialog-content>

        <p>Sorry to hear that your pet has gone 😢<br />
            Please create pet profile to send notification</p>

        <form [formGroup]="lostPetForm">
            <div class="user-img">
                <img class="img" src="assets/images/pet-placeholder.svg" alt="Pet photo"
                    *ngIf="!profilePhotoSrc; else petPhoto" />

                <ng-template #petPhoto>
                    <img class="img" [src]="profilePhotoSrc" alt="User photo" />
                </ng-template>

                <div class="edit-btn">
                    <i class="icon" [inlineSVG]="'#edit_2'" (click)="openProfilePhotoDialog($event)"></i>
                </div>
            </div>

            <mat-form-field class="form-field" appearance="outline">
                <mat-label>Pet’s name</mat-label>
                <input matInput placeholder="Pet’s name" formControlName="petName" />
            </mat-form-field>

            <mat-form-field class="form-field" appearance="outline">
                <mat-label>Pet’s domestic address</mat-label>
                <input matInput placeholder="Pet’s domestic address" formControlName="petDomesticAddress" />
                <mat-hint>We aren’t sharing domestic address with other users</mat-hint>
            </mat-form-field>

            <p></p>

            <mat-form-field class="form-field" appearance="outline">
                <mat-label>Area last seen</mat-label>
                <input matInput placeholder="Area last seen" formControlName="areaLastSeen" />
            </mat-form-field>

            <mat-form-field class="form-field datepicker" appearance="outline">
                <mat-label>Date last seen</mat-label>
                <input matInput [matDatepicker]="picker" placeholder="Date last seen" formControlName="dateLastSeen">
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

        </form>
    </div>
    <div mat-dialog-actions>
        <div class="dialog-actions">
            <button class="btn basic" (click)="closeDialog()" mat-flat-button>
                {{ 'CANCEL' | translate }}
            </button>
            <button class="btn primary" (click)="notify(lostPetForm.valid)" mat-flat-button>
                Notify
            </button>
        </div>
    </div>
</div>