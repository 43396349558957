import { IDeviceByToken, IDeviceHistory } from '../interfaces';

export interface ISharedState {
    historyItem: IDeviceHistory;
    brainTreeToken: string;
    devicesByToken: IDeviceByToken[];
}

export const initialSharedState: ISharedState = {
    historyItem: undefined,
    brainTreeToken: undefined,
    devicesByToken: undefined,
};
